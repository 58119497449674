@import "./_mixin";
/* layout SP
***********************************/
.wrap{}
header.header{
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  z-index: 10;
  display: none;
  .header-inner{
  }
}
.header-hover-menu{display: none;}
.sp-navigation{
  position: fixed;
  bottom: 0; left: 0;
  width: 100%;
  z-index: 11;
  .btn-nav{
    width: 140/750*100%;
    height: 70px;
    .iphonex & {
      height: 100px;
    }
    &.brand-logo{
      width: 190/750*100%;
    }
  }
  .icon-brand{width: 40px;}
  .icon-search{width: 20px;}
  .icon-heart{width: 19px; margin-top: 0.5px;margin-bottom: 0.5px;}
  .icon-cart{width: 20px;}
  .menu-toggle-icon{
    margin-top: 3px;
    margin-bottom: 3px;
    width: 16px;
    height: 14px;
    .menu-toggle-icon-line{
      width: 100%;
      height: 2px;
      left: 0;
      transition: 0.4s;
      &.n1{top: 0px;}
      &.n2{top: 6px;}
      &.n3{top: 12px;}
      .navOpen & {
        &.n1{
          width: 100%;
          top: 6px;
          transform: rotate(45deg);
        }
        &.n2{
          opacity: 0;
        }
        &.n3{
          width: 100%;
          top: 6px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.sp-drawer-menu{
  visibility: hidden;
  position: fixed;
  top: 0; left: 0;
  z-index: 10;
  width: 100%;
  .brand-logo-holder{display: none;}
  &.openedMenu{
    position: relative;
  }
  .sp-site-menu{
    padding-bottom: 70px;
  }
  .iphonex & {
    .sp-site-menu{
      padding-bottom: 100px;
    }
  }
}
.sp-site-menu{
  .user-holder{
    .icon-holder{
      width: 50px;
      height: 50px;
    }
  }
  .brand-logo{
    width: 52px;
    margin: auto;
  }
  ul .menu-list-item{
    margin-top: 0;
    .uk-accordion-title{
      &:before{content: none;}
      .icon-plus{
        width: 13px;
        svg{transition: transform 0.4s;}
      }
    }
    &.uk-open {
      .icon-plus svg{transform: rotate(45deg);}
    }
    .label-thumbs{
      width: 50px;
    }
  }
  .icon-facebook{
    width: 20px;
  }
  .icon-twitter{
    width: 20px;
  }
  .icon-instagram{
    width: 20px;
  }
}
footer.footer{
  background-color: #fff;
  padding-top: 40px;
  position: relative;
  .user-holder{display: none;}
  .footer-inner{
  }
}
/** ↓↓ 共通パンくず ↓↓ **/
.breadcrumb-block{
  padding: 24px 0 18px;
}
/** ↓↓ マガジン・アンバサダー ↓↓ **/
.breadcrumb-holder{
	.btn-breadcrumb{
		font-size: 24/2+px;
	}
}
.article-holder{
	.post-thumbnail{
		width: 750/2+px;
    width: 100%;
		height: 750/2+px;
		img{
			object-fit: cover;
			width: 100%;
			height: 100%
		}
	}
	.cat-holder{
		.cat-thumb{
      width: 100/2+px;
      min-height: 100/2+px;
    }
	}
}
//既存コンテンツ揃える
.article-content{
	h1,h2,h3{
		font-size: 40/2+px;
		padding-left: 100/750*100%;
		padding-right: 100/750*100%;
		line-height: 50/30*1;
		margin: 110/2+px 0 0;
		&:first-child{
			margin-top: 0;
		}
	}
	>ul li{
		list-style-type: disc;
	}
	p,dd,address,>ul{
		margin: 110/2+px 0;
		position: relative;
		padding-left: 100/2+px;
		padding-right: 100/2+px;
		font-size: 30/2+px;
		line-height: 2;
		a[href*=".jpg"]{
			display: block;
			margin: 100/2+px -50/750*100%;
		}
		&:last-child{
			margin-bottom: 0;
		}
		&:first-child{
			margin-top: 0;
		}
		&:empty{display: none;}
	}
  a{
    text-decoration: underline;
  }
	.wp-block-image{
		margin-top: 110/2+px;
	}
	>.img_c {
		padding-left: 40/2+px;
		padding-right: 40/2+px;
	}
	.img_c{
		margin: 110/2+px 0;
	}
	.img_r{
		margin: 110/2+px 0;
	}
	.row{
		padding-left: 40/2+px;
		padding-right: 40/2+px;
	}
	p + p{margin-top: 110/2+px;}
	p ~ p{margin-bottom: 110/2+px;}
	p + h3{margin-top: 102/2+px;}
	h3 + p,h2 + p{margin-top: 20/2+px;}
	h3 + dd,h2 + dd{margin-top: 20/2+px;}
	p + dd{margin-top: 20/2+px;}
	hr{margin: 140/2+px 0;}
	.img_l{
		margin-top: 140/2+px;
		margin-bottom: 140/2+px;
		padding-left: 100/2+px;
		padding-right: 100/2+px;
		overflow: hidden;
		.img_r{margin: 0;}
		.gia_ttl{
			margin-top: 0;
		}
		dt{
			width: 350/2+px;
			margin: auto;
		}
		h3,h2{
			padding: 0;
			clear: initial;
			font-size: 40/2+px;
			margin: 20/2+px 0;
		}
		dd{
			margin: 20/2+px 0;
			font-size: 24/2+px;
			p{font-size: 24/2+px; margin: 0;}
			h6{font-size: 24/2+px;}
		}
		>p{margin: 0; font-size: 24/2+px;}
		p,dd{padding: 0;}
		p + h3{margin-top: 0;}
		h3+ p{margin-top: 0;}
		dt + h3{margin-top: 42/2+px;}
		h3 + dd{margin-top: 30/2+px;}
		h1{margin: 0;}
	}
	p:empty + .row{
		margin: 110/2+px 0;
	}
	h3 + .row,{
		margin: 20/2+px 0;
	}
	.row + .row{
		margin: 110/2+px 0;
	}
	.row{
		.grid4{
			margin-top: 110/2+px;
			p{margin-top: 10px;}
		}
		.grid6 + .grid6{
			margin-top: 110/2+px;
		}
		.grid6{
			margin-top: 8px;
			.mt10{
				margin-top: 10px;
				padding: 0;
			}
			.mb10{
				margin-bottom: 10px;
				padding: 0;
			}
		}
	}
  * + .wp-block-lazyblock-article-2col-image{margin-top: 80px;}
  .article-2col-image-text{
    margin-top: 140/2+px;
    margin-bottom: 140/2+px;
    padding-left: 100/2+px;
    padding-right: 100/2+px;
    .article-2col-image-holder{
      width: 350/2+px;
      margin: auto;
    }
    .article-2col-text-holder{
      margin-top: 25px;
      *{
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
      h2,h3,h4{
        font-size: 40/2+px;
        line-height: 1.5;
      }
      p{
        font-size: 24/2+px;
        line-height: 2;
        & ~ p{margin-bottom: 0;}
      }
      a{
        text-decoration: underline;
      }
    }
  }
}
.post-slide-holder{
	.item{
		width: 400/2+px;
		margin-right: 15/2+px;
		.item-title{
			line-height: 32/24*1;
		}
	}
}
//関連アイテム
.relation-items-slide-holder{
	.item{
		width: 600/2+px;
		margin-right: 15/2+px;
	}
}
.ambassador-info-holder{
	.thumb-holder{
    width: 250/2+px;
    height: 250/2+px;
		margin: auto
	}
	.info-holder{
		padding-left: 100/2+px;
		padding-right: 100/2+px;
		.ambassador-desc{line-height: 36/20*1;}
	}
}
.article-bottom-holder{
	padding-left: 50/750*100%;
	padding-right: 50/750*100%;
	.post-url-holder{
		.btn-url-copy{
			width: 100%;
		}
    .balloon-top{
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
    }
	}
	.tag-item{
		margin-right: 20/2+px;
		margin-bottom: 20/2+px;
		.post-tag{
			line-height: 1;
			padding: 18/2+px 25/2+px;
		}
	}
}
/** ↑↑ マガジン・アンバサダー ↑↑ **/

/** ↓↓ WP パンくず ↓↓ **/
.section-wp-breadcrumb,
.section-breadcrumb{
	&.cat-parent,
  &.breadcrumb-abs{
		top: 0;
	}
}
/** ↑↑ WP パンくず ↑↑ **/


/** ↓↓ マガジンTOP ↓↓ **/
.magazin-slide-holder{
	.swiper-wrapper .list-item{
		.img-holder{
		}
	}
}
/** ↑↑ マガジンTOP ↑↑ **/

/** ↓↓ マガジン子カテゴリ ↓↓ **/

/** ↑↑ マガジン子カテゴリ ↑↑ **/

/** ↑↑ アンバサダーTOP ↑↑ **/
.section-ambassador-top{
  .mainVisual-info{
    height: auto;
    top: auto;
    bottom: 0;
  }
}

.ambassador-article-list{
	.ambassador-name{
		width: 230/2+px;
	}
}
/** ↑↑ アンバサダーTOP ↑↑ **/


/** ↓↓ アンバサダー子カテゴリ ↓↓ **/
/** ↑↑ アンバサダー子カテゴリ ↑↑ **/

/** ↓↓ 商品一覧 ↓↓ **/
.section-product-list{
  .image-holder{
    img{width: 100%;}
  }
  .rearrange-layout{
    justify-content: center;
  }
  .style-layout{
    margin-top: 0;
    justify-content: space-between;
  }
}
/** ↑↑ 商品一覧 ↑↑ **/

/** ↓↓ TOP NEWS ↓↓ **/
.top-news-list{
	.list-item{
		&:last-child{
			.btn{border-bottom: none;}
		}
	}
}
/** ↑↑ TOP NEWS ↑↑ **/

/** ↓↓ NEWS一覧 ↓↓ **/
.news-list-layout{
    .list-item{
    &:last-child{
      .btn{border-bottom: none;}
    }
  }
}
/** ↑↑ NEWS一覧 ↑↑ **/

/** ↓↓ NEWS詳細 ↓↓ **/
.news-detail-layout{
  .pic{
    width: 275px;
    margin: auto;
  }
  .main-holder{
    word-break: break-all;
    padding: 0 20px;
  }
}
/** ↑↑ NEWS詳細 ↑↑ **/


/** ↓↓ マイページ ↓↓ **/
.member-info{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .name{
    font-size: 15px;
  }
  .point{
    font-size: 12px;
  }
}
.history-head{
  margin: 0;
}
.mypage-list-head{
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 38px;
  .item {
    width: 50%;
    position: relative;
    &:nth-child(2):before,
    &:nth-child(4):before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background-color: #C8C8C8;
      width: 1px;
      height: 15px;
      margin-top: -7.5px;
    }
  }
  a{
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 46px;
  }
}
.mypage-list{
  .item{
    + .item{
      border-top: solid 1px #DCDCDC;
    }
    a{
      width: 100%;
      padding: 25px 0;
      display: flex;
      align-items: center;
    }
    .icon{
      height: 38px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      svg{width: 100%;}
    }
    .label{
      margin-left: 24px;
      font-size: 15px;
    }
  }
}
.order-history-item{
  +.order-history-item{
    margin-top: 25px;
  }
  padding: 32px 25px 20px;
  .order-history-info{
    font-size: 12px;
  }
}

.card_address-list{
  .uk-fieldset{
    border-radius: 4px;
    border: #DCDCDC solid 1px;
    padding: 20px 25px 25px;
    margin: 0;
    font-size: 12px;
    .uk-form-controls{
    font-size: 12px;

    }
    + .uk-fieldset{
      margin-top: 25px;
    }
  }
  .uk-list{
    li{ display: flex; align-items: center;}
  }
  .uk-legend{
    width: 100px;
    margin-bottom: 0;
  }
  .btn-default{
    width: 100%;
    font-size: 12px;
  }
  .button-group{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    >*{
      width: calc(50% - 7.5px);
      +*{
        margin-left: 15px;
      }
    }
  }
}
/** ↑↑ マイページ ↑↑ **/

/** ↓↓ ログイン ↓↓ **/
.member-merit{
  .item{
    +.item{
      margin-top: 25px;
    }
    display: flex;
    align-items: center;
    line-height: 1.7;
    .icon{
      padding: 25px;
      width: 125px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{width: 100%;}
    }
    .info{
      margin-left: 25px;
      flex:1;
    }
    .ttl{
      margin: 0 0 10px;
    }
  }
}
/** ↑↑ ログイン ↑↑ **/

/** ↓↓ カート ↓↓ **/
h3.pc-text-size-20.sp-text-size-40{
  margin-top: 0;
  margin-bottom: 20px;
}
.item-total-price{
  .text{
    width: 100px;
  }
}
.item-list-row{
  margin: -20px 0;
  ol{
    padding: 20px 0;
  }
  .item-image{
    img{
      width: 75px;
      display: block;
    }
  }
  .item-info{
    width: calc(100% - 75px - 15px);
    [data-pageid=cart_index] & {
      width: calc(100% - 75px - 15px - 20px - 15px);
    }
  }
  .item-amount{
    margin-left: 75px + 15px
  }
}
/** ↑↑ カート ↑↑ **/

.cart-step, .member-step{
  font-size: 10px;
  margin: 0 -2.5px;
  .btn-default{
    margin: 0 2.5px;
    color:$gray;
    padding: 0 10px;
  }
  .bg-color-black{
    .text{color:$white;}
  }
}




/** ↓↓ こだわり検索 ↓↓ **/
.search-container{
  background-color: #fff;
  .ITEM_003-head{
    border-bottom: 1px solid #F0F0F0;
    li{
      width: 25%;
    }
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 10px;
      color: #969696;
      &.active{
        color: #000;
        border-bottom: #000 solid 1px;
      }
    }
  }
  .icon-absolute-right{
    right: 25px;
    &.uk-radio{
      right: 12px;
    }
  }
  .icon-absolute-left{
    left: 25px;
  }
  .uk-button-default{
    width: 100%;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.25;
    padding: 5px;
    font-size: 10px;
    height: 50px;
  }
  .color-panel{
  }
  .list-holder{
    .item{
      flex-direction: column;
      align-items: flex-start;
      padding: 25px;
      &.item-child,
      &.item-kids{
        padding: 0;
        .label{
          padding: 25px;
          width: 100%;
        }
      }
      &.item-kids{
        .label{
          padding: 25px 20px 25px 75px;
        }
      }
    }
    .item-title{
      width: auto;
      font-size: 15px;
    }
    .item-body{
      margin-top: 13px;
      width: 100%;
    }
    // カラーなどのボタンの横並び
    .uk-grid-collapse{
      >li{padding-left: 4px;}
      .uk-first-column{
        padding-left: 0;
      }
    }
    .sCategory{
      padding-right: 30px;
    }
    .unisex{
      display: block;
      margin-top: 15px;
    }
    .head{
      .item{
        align-items: center;
      }
    }
  }
  .search-foot{
    position: fixed;
    width: 100%;
    bottom: 0;left: 0;
    .uk-button{
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      height: 50px;
    }
    .reset{
      color: #969696;
      background-color: #F0F0F0;
    }
    .submit{
      color: #FFFFFF;
      background-color: #323232;
    }
  }
  .search-head{
    border-bottom: none;
    padding-top: 8px;
    .btn-item-top{
      margin-left: 20px;
      justify-content: flex-start;
      font-size: 12px;
    }
    .item-search-form{
      padding: 0 25px;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 15px;
    }
    .item-search-submit{
      margin-left: 25px;
    }
  }
  .search-inner.spOnly{
    padding-bottom: 50px;
  }

  .search-inner{
    //height: 100vh;
    //overflow-y: auto;
    //-webkit-overflow-scrolling: touch;
  }

}

/** ↑↑ こだわり検索 ↑↑ **/

// アマゾンペイ
#memberEditArea {
  max-height: 90%;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.item-details-holder{
  .size-guide-table{
    margin-left: -25px;
    margin-right: -25px;
  }
}


//購入ガイド
.guide-list-rank-item{
  margin: 0 -25px;
  li{
  }
  .uk-open{
    .uk-accordion-title >div{border-bottom-color: transparent;}
  }
  .uk-accordion-title{
    padding-left: 50px;
    padding-right: 25px;
  }
  .guide-rank-table{
    margin-left: -75px;
    margin-right: -25px;
  }
  .uk-accordion-content-inner{
    padding-left: 50px;
    padding-right: 25px;
  }
  .uk-accordion-inner-holder{
    padding-left: 25px;
  }
}

//aboutページ
.navOpenHtml{
  overflow-y: scroll !important;
  body[data-pageid="about"]{
    &.modalOpen{
      overflow-y: scroll !important;
      .sp-drawer-menu{
        overflow-y: scroll;
      }
    }
  }
}
.about-navigation-arrow-holder{
  bottom: 70px;
  .iphonex & {
    bottom: 100px;
  }
}
.about-video-holder{
  .showSecVideo + .about-page-cover{
    visibility: visible;
  }
  .about-page-cover ~ .showSecVideo{
    //visibility: visible;
  }
  .about-page-cover{
    visibility: hidden;
    &.showSecVideo{visibility: visible;}
  }
}

body[data-pageid="about"]{
  .sp-navigation,.about-navigation-arrow-holder{
    transition: 0.4s;
  }
  &.header-hidden{
    .sp-navigation{
      transform: translateY(100%);
    }
    .about-navigation-arrow-holder{
      bottom: 0;
    }
  }
}

.section-about-mainvisual{
  .about-page-title{
    .ka-about-title{
      width: 460/750*100vw;
    }
    .title-sub{
      margin-top: 14px;
      font-size: 15px;
    }
  }
}
.section-about-read{
  .about-section-inner{
    padding: 120px 0 300px;
    min-height: calc(100vh - 70px);
    .iphonex & { min-height: calc(100vh - 100px); }
  }
  .about-read{
    width: 500/750*100vw;
    font-size: 18px;
    & + .about-read{
      padding-top: 60px;
    }
  }
}
.section-about-label-intro{
  .title{
    width: 441/750*100vw;
  }
  .title-sub{
    margin-top: 14px;
  }
}
.about-label-contents-holder{
  .about-label-contents{
    width: 451/750*100vw;
    padding-top: 100/750*100vw;
    padding-left: 50/750*100vw;
    >*{width: 480/750*100vw;}
    [uk-icon="ka-title-ultimate"]{
      width: 285/750*100vw;
    }
    [uk-icon="ka-title-explorer"]{
      width: 292/750*100vw;
    }
    [uk-icon="ka-title-life"]{
      width: 103/750*100vw;
    }
    [uk-icon="ka-title-aspire"]{
      width: 218/750*100vw;
    }
    .uk-accordion-title{
      display: inline-block;
      padding: 10px;
      margin-top: 20px;
      margin-left: -10px;
      .icon-bottom{
        width: 10px;
        transform: translateY(-1px);
      }
    }

    .arrow{
      top: calc(100vh - 70px);
      transform: translate(-50%, -100%);
    }
  }
}

.section-about-history{
  background-image: url("../img/history/history-fit-bg-sp.jpg");
}

//history
.section-history-mainvisual{
  height: calc(100vh - 70px);
  .iphonex & { height: calc(100vh - 100px); }
}
.section-history-read{
  padding: 180/750*100vw 0;
  .history-read{
    width: 500/750*100vw;
    font-size: 36/750*100vw;
  }
}

.history-number{
  font-size: 80/750*100vw;
  padding: 80/1280*100vw 50/1280*100vw 0;
}


.hiastory-count-holder{
  overflow: hidden;
  .sp-hisotry-navigation{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    height: calc(100vh - 70px);
    .iphonex & { height: calc(100vh - 100px); }
    &.showSpNav{
      visibility: visible;
      opacity: 1;
    }
    &.bottom-abs{
      position: absolute;
      bottom: 0; top: auto;
    }
    &.top-abs{
      position: absolute;
      top: auto;
    }
    .btn-hisotry-navigation{
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transition: color 0.3s;
      background: none;
      outline: none;
      border: none;
      &.disabled{
        opacity: 0.6;
        pointer-events:none;
      }
      .icon-top,.icon-bottom{
        width: 17px;
      }
    }
    &.black{
      .btn-hisotry-navigation{color:#000; }
    }
  }
  .section-bg-height-md{
    height: 1192/750*100vw;
  }
  .section-bg-height-lg{
    height: 1192/750*100vw;
  }
  .pc-right-text-holder{
    font-size: 30/750*100vw;
    width: 100%;
    .animation-text{
      width: 100%;
      word-wrap: break-word;
    }
    .animation-text{
      font-size: 30/750*100vw;
    }
  }
  .text-right-holder{
    font-size: 30/750*100vw;
  }
  .section-1{
    padding: 900/750*100vw 0 80/750*100vw;
  }
  .section{
    position: relative;
    &[data-color="black"]{
      .section-number-holder{color: $black2}
    }
    &[data-color="white"]{
      .section-number-holder{color: $white2}
    }
  }
  .section-number-holder{
    position: absolute;
    left: 0; top: 0;
    z-index: 1;
    padding-left: 50/750*100vw;
    padding-top: 70/750*100vw;
  }
  .section.bg-color-white{
    padding-top: 260/750*100vw;
    padding-bottom: 80/750*100vw;
    .section-number-holder{
      padding-top: 100/750*100vw;
    }
  }
  .section.bg-color-white + .section.bg-color-white{
    border-top: solid 1px $lightGray;
  }
  .section.bg-color-gray{
    .pc-right-text-holder{
      padding-bottom: 80/750*100vw;
    }
  }
}


.history-fit-bg{
  background-image: url("../img/history/history-fit-bg-sp.jpg");
}

