@import "./_mixin";
/* layout PC SP 共通
***********************************/
.btn-b2b{
  height: 75px;
  width: 100%;
  background-color: #0090D4;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  .icon-arrow{
    display: block;
    transform: translateY(-2px);
    margin-left: 18px;
  }
}

.wrap{width: 100%;}

.sys-messages{
  .messages--error{
    color: $red;
  }
  .messages--warn{
    color: $red;
  }
  .messages--default{
    color: #C8C8C8;
  }
}

.contents{position: relative;}
/** ↓↓ 共通パンくず ↓↓ **/
.breadcrumb-block{
  .text-breadcrumb{
    display: flex;
    align-items: center;
    .icon{margin-right: 8px;}
  }
}

/** ↓↓ 詳細ページ パンくず ↓↓ **/
.wrap[data-pageid="item_detail"],
.wrap[data-pageid="styles_detail"]{
  .breadcrumb-holder{
    .first,.last{display: none !important;}
  }
}
/** ↑↑ 詳細ページ パンくず ↑↑ **/

/** ↓↓ マガジン・アンバサダー 記事 ↓↓ **/
.article-holder{
	*{margin: 0;} // マージンリセット
	img{width: 100%;}
	.img_l{}
	.wp-block-image .aligncenter{display: block;}
}
.relation-items-slide-holder{
	.status-item + .status-item{
		&:before{
			content: "/";
			padding: 0 0.5em;
		}
	}
}

.ambassador-info-holder{
}
/** ↑↑ マガジン・アンバサダー 記事 ↑↑ **/
/** ↓↓ WP パンくず ↓↓ **/
.section-wp-breadcrumb,
.section-breadcrumb{
	position: relative;
	width: 100%;
	&.cat-parent,
  &.breadcrumb-abs{
		position: absolute;
		top: 0; left: 0;
		width: auto;
		z-index: 2;
		.container-pc-size-m{max-width: none;}
	}
  .icon-arrow{
    @include mqPcPxSpVw("width",8,15);
  }
  .icon-ambassador{
    @include mqPcPxSpVw("width",52,84);
  }
}
/** ↑↑ WP パンくず ↑↑ **/
/** ↓↓ マガジンTOP ↓↓ **/
.section-magazine-top{
	display: inline-block;
	width: 100%;
	.main-slide-holder{
		.main-slide{padding:0;}
		img{width: 100%;}
		.item{
			width: 100%;
			position: relative;
			list-style: none;
		}
		.item-info{
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}


/** ↑↑ マガジンTOP ↑↑ **/
/** ↓↓ マガジン子カテゴリ ↓↓ **/
.section-wp-category-top{
	.category-icon-holder{
		.category-icon{
			@include mqPcPxSpVw("width",160,200);
			@include mqPcPxSpVw("height",160,200);
		}
	}
}
.sort-btn-holder{
	display: block;
	margin-right: 0;
	margin-left: auto;
	position: relative;
	align-self: center;
  display: flex;
  @include mqPcPxSpVw("width",180,300);
  @include mqPcPxSpVw("min-height",50,90);
  form{width: 100%;}
  .icon{
    position: absolute;
    @include mqPcPxSpVw("width",12,18);
    @include mqPcPxSpVw("right",18,30);
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
	select.btn-post-sort{
		height: 100%;
		background-image:none;
		padding: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
		&.uk-select{
			background-image:none;
		}
	}
  @media all and (min-width: 960px) {
    select.btn-post-sort{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  @media screen and (max-width: 959px) {
    select.btn-post-sort{
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}
/** ↑↑ マガジン子カテゴリ ↑↑ **/

/** ↓↓ アンバサダーTOP ↓↓ **/
.ambassador-intro-list-holder{
	@include mqPcPxSpVw("margin-top",-100,-100);
	.ambassador-intro-item{
		@include mqPcPxSpVw("margin-top",100,100);
	}
	.thumb-holder{
		@include mqPcPxSpVw("width",160,200);
		margin: 0 auto;
	}
}
.ambassador-article-list{
	.ambassador-icon{
		@include mqPcPxSpVw("width",80,130);
		@include mqPcPxSpVw("margin-right",26,24);
	}
}
/** ↑↑ アンバサダーTOP ↑↑ **/

/** ↓↓ SHOP一覧 ↓↓ **/
.section-shop-list{
	.uk-open .uk-accordion-title{
		.icon svg{
			transform: rotate(45deg);
		}
	}
	.uk-accordion-title{
		&:before{display: none;}
	}
	.btn-current-location-holder{
		@include mqPcPxSpVw("width",280,650);
		.isSubmit{pointer-events:none;}
	}
}
.shoplist-index-holder{
	position: relative;
	.fade-leave-active{
		position: absolute;
		left: 0;top: 0;
		width: 100%;
	}
}
.shop-sort-taglist{
	.active{
		.btn{
			background-color: #323232;
			.tag-text{color: #fff;}
		}
	}
}
.shop-list-holder{
	.shop-list{
		>li{
			margin: 0;
			.uk-accordion-title{cursor: pointer;}
			&:last-child{border-bottom: none;}
		}
	}
	.shop-list-child{
		>li{
			margin: 0;
		}
		>li:last-child{
			.area-child-holder{border-bottom: none;}
		}
	}
}
/** ↑↑ SHOP一覧 ↑↑ **/
/** ↓↓ SHOP AREA ↓↓ **/
.area-shop-list{
	li.shop-item{
		.icon-arrow{
			@include mqPcPxSpVw("width",20,30);
			@include mqPcPxSpVw("height",20,30);
		}
	}
}
.pager-holder{
	.btn-pager-prev,.btn-pager-next{
		@include mqPcPxSpVw("width",40,60);
		@include mqPcPxSpVw("height",40,60);
		.icon{
			@include mqPcPxSpVw("width",12,17);
			@include mqPcPxSpVw("height",12,17);
		}
	}
}
/** ↑↑ SHOP AREA ↑↑ **/

/** ↓↓ SHOP detail ↓↓ **/
.section-shop-detail{
	.shop-read-holder > p{margin: 0;}
	.map-holder{
		width: 100%;
		@include mqPcPxSpVw("height",440,375);
		position: relative;
		margin: auto;
		iframe{
			position: absolute;
			left: 0; top: 0;
			width: 100%; height: 100%;
		}
	}
	.shop-detail-info{
		.info-item:last-child{
			border: none;
		}
	}
	.info-item{
		.info-th{
			@include mqPcPxSpVw("width",120,150);
		}
	}
}
/** ↑↑ SHOP detail ↑↑ **/

/** ↓↓ よくある質問 ↓↓ **/
.section-faq{
  .wp-block-lazyblock-faq-block-content + .wp-block-lazyblock-faq-middle-title{
    @include mqPcPxSpVw("margin-top",100,80);
  }
  .faq-content{
    ul{
      margin: 0;
      li{list-style-type: disc;}
    }
    a{color: $gray;}
  }
}
.anker-list-holder{
  .icon-arrow{
    @include mqPcPxSpVw("width",10,20);
  }
}
/** ↑↑ よくある質問 ↑↑ **/

/** ↓↓ 商品一覧 ↓↓ **/
.section-product-list{
  .list-item,.image-holder{
    img{width: 100%;}
  }
}
/** ↑↑ 商品一覧 ↑↑ **/

/** ↓↓ フリーおすすめ商品 ↓↓ **/
.section-recommend{
  .item-rank-num{
    display: none;
  }
}
/** ↑↑ フリーおすすめ商品 ↑↑ **/

/** ↓↓ カテゴリスライダー ↓↓ **/
.section-category{
  .item-title{
    white-space: pre-line;
  }
}
/** ↑↑ カテゴリスライダー ↑↑ **/


/** ↓↓ カテゴリ一覧 アクティビティから探す ↓↓ **/
.section-activity{

}
/** ↑↑ カテゴリ一覧 アクティビティから探す ↑↑ **/

/** ↓↓ TOP NEWS ↓↓ **/
.top-news-list{
  .list-item{
    @include mqPcPxSpVw("padding-right",40,0);
  }
  .img-holder{
    @include mqPcPxSpVw("width",120,150);
    @include mqPcPxSpVw("height",120,150);
    @include mqPcPxSpVw("margin-left",20,70);
  }
}
/** ↑↑ TOP NEWS ↑↑ **/

/** ↓↓ NEWS 一覧 ↓↓ **/
.news-list-layout{
  .list-item{
    .img-holder{
      @include mqPcPxSpVw("width",120,150);
      @include mqPcPxSpVw("margin-left",20,70);
      &.img-holder-square{
        @include mqPcPxSpVw("padding-top",120,150);
        .img-element{
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
/** ↑↑ NEWS 一覧  ↑↑ **/

/** ↓↓ NEWS詳細 ↓↓ **/
.news-detail-layout{
  .section-title-holder{
    @include mqPcPxSpVw("margin-bottom",80,80);
  }
  p,div{
    margin: 0;
    line-height: 2;
    & + .btn-holder{
      @include mqPcPxSpVw("margin-top",60,120);
    }
  }
  .pic{
    margin: auto;
    text-align: center;
  }
  div img{margin: auto;}
  .main-holder{
    & + .news-text-block{
      @include mqPcPxSpVw("margin-top",60,80);
    }
    & + .btn-holder.newslist{
      @include mqPcPxSpVw("margin-top",70,140);
    }
    .pic + .news-text-block{
      @include mqPcPxSpVw("margin-top",60,80);
    }
  }
  .balloon-top{
    right: 0;
    margin: auto;
    @include mqPcPxSpVw("width",220,400);
  }
  .btn-pdf{
    @include mqPcPxSpVw("margin-top",60,80);
  }
  .btn-newslist{
    text-decoration: underline;
    &:hover{text-decoration: none;}
  }
}
/** ↑↑ NEWS詳細 ↑↑ **/


/** ↓↓ カート ↓↓ **/
h3.pc-text-size-20.sp-text-size-40{
  margin-top: 0;
  margin-bottom: 30px;
}
.item-total-price{
  .ttl{
    color: #969696;
    margin: 0;
  }
  .text{
    width: 150px;
    margin: 0;
    text-align: right;
  }
}
.item-list-row{
  margin: -40px 0;
  >li{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  ol{
    padding: 40px 0;
  }
  .item-image{
    img{
      width: 120px;
      display: block;
    }
  }
  .item-info{
    flex-grow: 1;
    .item-status:empty{display: none;}
  }

  .item-amount{
    flex-shrink: 0;
  }
  .item-pirce{
    flex-shrink: 0;
  }
}
.wrap[data-pageid="cart_seisan"],
.wrap[data-pageid="cart_seisan$amazon_payments"]{
  label{cursor: pointer;}
  .point-info{
    .sub{
      padding-left: 10px;
    }
  }
  .cart-option-item{
    display: none;
  }
  .optionItems{
    .item{
      width: 170px;
      .thumb{
        border-radius: 4px;
        overflow: hidden;
        border:1px solid transparent;
        transition: border 0.2s;
        &.active{
          border:1px solid #969696;
        }
      }
      .info{
        display: flex;
        margin-top: 10px;
        input{
          margin-top: 7px;
          flex-shrink: 0;
          margin-right: 8px;
        }
        span{
          min-height: 3em;
        }
      }
    }
  }
}


/** ↑↑ カート ↑↑ **/

/** ↓↓ ログイン ↓↓ **/
.member-merit{

}
/** ↑↑ ログイン ↑↑ **/

/** ↓↓ マイページ ↓↓ **/
.section-mypage-top{
  width: 100%;
  //@include mqPcPxSpVw("height",600,955);
  padding-bottom: 30px;
  .icon-arrow{@include mqPcPxSpVw("width",8,15);}
  .mypage-status-holder{
    background-color: $rankBlue;
    @include mqPcPxSpVw("padding-top",35,50);
    @include mqPcPxSpVw("padding-bottom",35,50);
    @include mqPcPxSpVw("padding-left",40,50);
    @include mqPcPxSpVw("padding-right",40,50);
    &.rank-blue{background-color: $rankBlue;}
    &.rank-gold{background-color: $rankGold;}
    &.rank-platinum{background-color: $rankPLATINUM;}
    &.rank-black{background-color: $rankBlack;}
  }
  .member-info-holder{
    .name{text-align: center;}
    .area-barcord{
      margin: auto;
      @include mqPcPxSpVw("width",240,550);
    }
  }
  @media all and (min-width: 960px) {
    // padding-bottom: 120px;
    padding-bottom: 0;
    .member-info-item + .member-info-item{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        top: 0; left: 0;
        background-color: rgba($lightGray2,0.2);
      }
    }
  }
  @media screen and (max-width: 959px) {
    padding-bottom: 0;
    .member-info-item + .member-info-item{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        top: 0; left: 0;
        background-color: rgba($lightGray2,0.2);
      }
    }
  }
}
.mypage-list,
.mypage-list-head{
	.member_input a{
		[data-pageid=member_input] & {
			color: #C8C8C8;
		}
	}
	.member_credit a{
		[data-pageid=member_credit_entry_input] &,
		[data-pageid=member_credit_entry_edit] &{
			color: #C8C8C8;
		}
	}
	.member_address a{
		[data-pageid=address_list] &,
		[data-pageid=address_input] &,
		[data-pageid=address_add] &,
		[data-pageid=address_confirm] &,
		[data-pageid=address_result] &{
			color: #C8C8C8;
		}
	}
	.member_wishlist a{
		[data-pageid=wishlist_edit] &,
		[data-pageid=wishlist] & {
			color: #C8C8C8;
		}
	}
	.member_history a{
		[data-pageid=member_history] & {
			color: #C8C8C8;
		}
	}
}
// 購入履歴
[data-pageid=member_history]{
  .history-head-holder{
    @media screen and (min-width: 960px) {}
    @media screen and (max-width: 959px) {
      flex-wrap: wrap;
    }
  }
  .history-head{
    @include mqPcPxSpVw("margin-top",0,100);
    > .uk-flex{
      margin: 0;
      margin-bottom: 0;
    }
    .item.active{
      a{
        color: #C8C8C8;
        cursor: default;
        pointer-events: none;
      }
    }
    .item:nth-child(2){
      a{
        width: 100%;
        display: block;
        border-left: 1px solid #C8C8C8;
      }
    }
    @media screen and (min-width: 960px) {
      .item:nth-child(1){
        a{padding-right: 40px;}
      }
      .item:nth-child(2){
        a{padding-left: 40px;}
      }
    }
    @media screen and (max-width: 959px) {
      width: 100%;
      justify-content: center;
      .item{
        width: 50%;
        text-align: center;
      }
    }
  }
}
// ウィッシュリスト
.wishlist-head{
	display: flex;
	.item {
		flex-grow: 1;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			background-color: #C8C8C8;
			width: 1px;
			height: 20px;
			margin-top: -10px;
		}
		&:first-child:before{
			content: none;
		}
		&:last-child{
			margin-right: -34px;
		}
	}
	a{
		&.active{
			color: #C8C8C8;
		}
		padding: 0 34px;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
	}
}
.order-history-item{
  .order-place{
    @include mqPcPxSpVw("margin-top",30,0);
    @include mqPcPxSpVw("margin-bottom",70,70);
  }
  .uk-accordion-title{
    &:before{
      content: none;
    }
  }
  .text-close{display: none;}
  .uk-open{
    .text-show{display: none;}
    .text-close{display: flex;}
  }
}
.order-history-info{
	.label{width: 160px;}
}


.member-input-address-btn-holder{
	.btn-member-input-address-taikai + .btn-member-input-address-back{
		display: none;
	}
}
/** ↑↑ マイページ ↑↑ **/



.cart-step, .member-step{
  margin: 0 -5px;
  .btn-default{
    margin: 0 5px;
    color:$gray;
  }
  .bg-color-black{
    .text{color:$white;}
  }
}
.uk-button.bg-color-gray{
  color: #FFF;
}




/** ↓↓ こだわり検索 ↓↓ **/
.search-container{
  label{
    cursor: pointer;
  }
  .color-panel-holder{
    padding: 15px;
    .other{
      width: 100%;
      .color-panel{
        padding-top: 30%;
        background-color: #fff;
        border: solid 1px #DCDCDC;
        .uk-icon-holder{
          border: solid 1px #DCDCDC;
        }
        h6{
          display: block;
          position: absolute;
          top: 50%;left: 50%;
          transform: translate(-50%,-50%);
        }
      }

    }
  }
  .color-panel{
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    width: 100%;
    height: auto;
    padding: 100% 0 0 0;
    margin: 0;
    h6{display: none;}
    .uk-icon-holder{
      top: 30px;
      right: 30px;
      left: auto;
    }
  }
  .icon-absolute-right{
    position: absolute;
    top: 50%;
    right: 20px;
    &.uk-radio{
      right: 12px;
      margin-right: 0;
    }
    transform: translateY(-50%);
  }
  .icon-absolute-left{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
  .uk-button-default{
    color: #969696;
    min-width: 80px;
    padding: 0 5px;
    font-size: 12px;
    width: 100%;
    &.active{
      background-color: #969696;
      color: #FFF;
      border: solid 1px #969696;
    }
  }
  .list-holder{
    .uk-list-divider{
      > li{
        padding: 0;
        margin: 0;
        background-color: #fff;
      }
    }
    .item{
      position: relative;
      display: flex;
      align-items: center;
      padding: 25px 20px;
      &.item-child,
      &.item-kids{
        padding: 0;
        .label{
          padding: 25px 20px;
          width: 100%;
          transition: background-color 0.3s;
        }
        [type="checkbox"]:checked + .label{
          background-color: #969696;
          color: #FFF;
        }
      }
      &.item-kids{
        .label{
          padding: 25px 20px 25px 75px;
        }
      }
    }
    .item-title{
      width: 130px;
      margin: 0;
      font-size: 14px;
    }
    .item-body{
      flex-grow: 1;
      li{margin: 0;}
    }
    // カラーなどのボタンの横並び
    .uk-grid-collapse{
      >li{padding-left: 4px;}
      .uk-first-column{
        padding-left: 0;
      }
    }
    .sCategory{
      font-size: 10px;
      color: #969696;
    }
    .unisex{
      display: block;
      margin-top: 20px;
    }

    .head{
      background-color: #969696;
      color: #FFF;
      h2{
        color: #FFF;
        font-size: 12px;
      }
    }
  }
  .search-head{
    border-bottom: solid 1px #DCDCDC;
    .item-search-form{
      position: relative;
      height: 40px;
      .uk-input{
        padding-left: 32px;
        margin-bottom: 0;
      }
      .item-search-submit{
        background-color: #fff;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 10px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-search{
      	@include mqPcPxSpVw("width",16,24);
      	@include mqPcPxSpVw("height",16,24);
      }
    }
    .btn-item-top{
      position: relative;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      padding-right: 50px;
    }
    .uk-button{
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      height: 55px;
    }
    .reset{
      color: #969696;
      background-color: transparent;
      width: 143px;
    }
    .submit{
      width: 160px;
      color: #FFFFFF;
      background-color: #323232;
      border-radius: 4px;
    }
    .close{
      margin: 0 10px;
      .icon{
        width: 24px;
        height: 24px;
      	@include mqPcPxSpVw("width",24,40);
      	@include mqPcPxSpVw("height",24,40);
      }
      background-color: #fff;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
/** ↑↑ こだわり検索 ↑↑ **/
.page-load-status{
  display: none;
  height: 60px;
  width: 100%;
  .infinite-scroll-request{
    position: relative;
    .uk-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p{
    margin: 0;
    width: 100%;
    height: 100%;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}

//historyページ
.history-fit-bg{
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url("../img/history/history-fit-bg.jpg");
  .cover-el{
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.6);
    transition: opacity 0.5s;
    opacity: 0;
  }
  &.history-fit-bg-black{
    .cover-el{opacity: 1;}
  }
}
.section-history-mainvisual{
  width: 100%;
  //@include mqPcPxSpVw("height",720,1056);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .history-page-title{
    color: #FFF;
    text-align: center;
    width: 100%;
    .title-sub{
      display: block;
      @include mqPcPxSpVw("margin-top",30,28);
      @include mqPcPxSpVw("font-size",20,30);
    }
    .ka-history-title{
      @include mqPcPxSpVw("width",651,651);
      margin-left: auto;
      margin-right: auto;
      svg{width: 100%;}
    }
  }
  .btn-arrow-bottom-holder{
    position: absolute;
    bottom: 0;
    left: 50vw;
    margin-left: -45px;
    z-index: 5;
    width: 90px;
    .btn-arrow-bottom{
      display: block;
      padding: 30px 30px;
      color: #fff;
      background: none;
      border: none;
      outline: none;
    }
  }
}
.section-history-read{
  .history-read{
    margin-left: auto;
    margin-right: auto;
  }
}
.hiastory-count-holder{
  .history-bg-pic{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.bottom-section{
  position: relative;
  z-index: 1;
}

//aboutページ
body[data-pageid="about"]{
  background-color: #000;
  .wrap{overflow: hidden;}
  .iScrollVerticalScrollbar{display: none;}
  #aboutVideo-1 .endPoster{
    background: url("../img/about/poster-01.jpg") center center / cover no-repeat;
  }
  #aboutVideo-2 .endPoster{
    background: url("../img/about/poster-02.jpg") center center / cover no-repeat;
  }
  #aboutVideo-3 .endPoster{
    background: url("../img/about/poster-03.jpg") center center / cover no-repeat;
  }
  #aboutVideo-4 .endPoster{
    background: url("../img/about/poster-04.jpg") center center / cover no-repeat;
  }
  #aboutVideo-5 .endPoster{
    background: url("../img/about/poster-05.jpg") center center / cover no-repeat;
  }
  #aboutVideo-6 .endPoster{
    background: url("../img/about/poster-06.jpg") center center / cover no-repeat;
  }
  #aboutVideo-7 .endPoster{
    background: url("../img/about/poster-07.jpg") center center / cover no-repeat;
  }
  #aboutVideo-8 .endPoster{
    background: url("../img/about/poster-08.jpg") center center / cover no-repeat;
  }
}
.about-navigation-arrow-holder{
  position: fixed;
  bottom: 0;
  left: 50vw;
  margin-left: -45px;
  z-index: 5;
  width: 90px;
  transition: 0.4s;
  .arrow{
    display: block;
    padding: 30px 30px;
    color: #fff;
  }
  &.arrow-hide{
    opacity: 0;
    visibility: hidden;
  }
}
.about-video-holder{
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100vh;
  visibility: hidden;
  z-index: 0;
  transition: all 700ms ease 0s;
  .top-mv-img{
    position: absolute;
    width: 100%; height: 100%;
    background: url("../img/about/poster-01.jpg") center bottom / cover no-repeat;
  }
  .about-page-cover{
    transition: 0.6s;
    .safari.desktop & {transition: 0.8s;}
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    .endPoster{
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      z-index: 0;
    }
    &.showSecVideo{opacity: 1; visibility: visible;}
    &.showSecVideoPause{
      transition: 0.6s;
      iframe{z-index: 0;}
      .endPoster{z-index: 1;}
    }
    &.about-video{
      &:after{
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 2;
      }
    }
  }
  &.pause:not(.upChange){
    .about-video:not(#aboutVideo-1){transition: none;}
  }
  .showSecVideo + .about-page-cover{
    visibility: visible;
  }
  &.showVideo{animation: fadeIn 1.2s forwards;}
  .vimeomPlayer{
    z-index: 0;
  }
  iframe{
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    z-index: 1;
  }
  &.hideUp{
    transform: translateY(-100%);
  }
  .about-page-cover-black{
    position: absolute;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.6);
    transition: 0.5s;
    opacity: 0;
    z-index: 2;
    &.show-cover{opacity: 1;}
  }
}
.about-navigation-holder{
  position: fixed;
  right: 0; top: 0;
  @include mqPcPxSpVw("width",88,88);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: transform 0.4s $easeOutQuart;
  transform: translateX(100%);
  &.show{
    transform: translateX(0);
  }
  .about-navigation-item{
    .btn-about-navigation{
      @include mqPcPxSpVw("width",40,60);
      @include mqPcPxSpVw("height",40,60);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border:none;
      outline: none;
      font-size: 0;
      padding: 0;
    }
    &:nth-of-type(even){
      .dot{
        @include mqPcPxSpVw("width",4,8);
        @include mqPcPxSpVw("height",4,8);
      }
    }
    .dot{
      display: block;
      width: 8px;
      height: 8px;
      @include mqPcPxSpVw("width",8,16);
      @include mqPcPxSpVw("height",8,16);
      border-radius: 50%;
      background: #979797;
      transition: 0.3s;
    }
  }
  .about-navigation-item{
    .current .dot{
      @include mqPcPxSpVw("width",16,24);
      @include mqPcPxSpVw("height",16,24);
      background-color: #fff;
    }
    &:nth-of-type(even){
      .current .dot{
        width: 8px; height: 8px;
        @include mqPcPxSpVw("width",8,12);
        @include mqPcPxSpVw("height",8,12);
      }
    }
  }
}
.scr-fit-holder{
  .about-section-inner{
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh; /* 変数をサポートしていないブラウザのフォールバック */
    min-height: calc(var(--vh, 1vh) * 100);
    // min-height: 100%;
    height: 100%;
    .title{margin: 0 auto;}
  }
}
.section-about-mainvisual{
  width: 100%;
  height: 100vh;
  .about-page-title{
    color: #FFF;
    text-align: center;
    width: 100%;
    .ka-about-title{
      display: block;
      margin: auto;
    }
    .title-sub{
      display: block;
    }
  }
}
.section-about-read{
  .about-read{
    margin-left: auto;
    margin-right: auto;
  }
}

// .section-about-label,
// .section-about-label-intro{
//   .section-about-video-trigger{
//     width: 100%;
//     height: 100vh;
//     &.section-about-video-trigger-harf{
//       height: 50vh;
//     }
//   }
// }

.about-label-contents-holder{
  width: 100%;
  position: fixed;
  z-index: 1;
  visibility: hidden;
  .about-label-contents{
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    touch-action: none;
    pointer-events:none;
    a{
      touch-action: auto;
      pointer-events:auto;
    }
    .uk-accordion-content,.uk-accordion-title{
      pointer-events:auto;
    }
    .label-title,.label-title-sub,.section-about-label-info{
      opacity: 0;
    }
    &.contentsShow{
      animation: jsFedeShow 1s $easeOutQuart forwards;
      .label-title,.label-title-sub{
        animation: jsFedeShowUp 1s $easeOutQuart forwards;
      }
      .section-about-label-info{
        animation: jsFedeShowUp 1s 0.3s $easeOutQuart forwards;
      }
    }
    &.contentsHide{
      animation: jsFedeHide 1s $easeOutQuart forwards;
      .label-title,.label-title-sub,.section-about-label-info{
        opacity: 1;
      }
    }
    .arrow{
    }
    .uk-accordion-title{
      &:before{
        display: none;
      }
    }
    .close-text{display: none;}
    .open-text{
      display: inline-block;
      animation: fadeIn 0.5s;
    }
    .uk-open{
      .close-text{
        display: inline-block;
        animation: fadeIn 0.5s;
      }
      .open-text{display: none;}
    }
    .uk-accordion-title-text{
      .icon-bottom{
        width: 12px;
        transform: translateY(-2px);
        margin-left: 5px;
      }
    }
  }
}
.section-about-label-intro,.section-about-read,.section-about-mainvisual{
  overflow: hidden;
}
.section-about-label{
  //@include mqPcPxSpVw("min-height",720,1192);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  .section-about-label-info{
    width: 480px;
  }
  .uk-accordion-title{
    .uk-accordion-title-text{
      text-decoration: underline;
    }
    &:hover{
      .uk-accordion-title-text{
        text-decoration: none;
      }
    }
    &:before{
      content: none;
    }
    .close-text{display: none;}
  }
  .uk-open{
    .open-text{display: none;}
    .close-text{display: inline;}
  }
  .tag-list-holder{
    @media all and (min-width: 960px) {
      width: 520px;
    }
  }
}
.section-about-history{
  @include mqPcPxSpVw("height",720,1192);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  .section-about-history-inner{
    height: 100%;
  }
  .about-history-title{
    @include mqPcPxSpVw("width",488,570);
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (max-width: 959px) {
    .about-history-read{
      width: 550/2+px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .btn-holder{
    @include mqPcPxSpVw("width",240,340);
    margin-left: auto;
    margin-right: auto;
  }
}


//privacy
.layout-list-bullet{
  >li{
    list-style-type: disc;
  }
}

//size guide
.section-size-guide{
  .size-guide-list-holder{
    ul >li{margin-top: 0;}
  }
}
.size-guide-table-holder{
  .size-guide-pic{
    @include mqPcPxSpVw("width",560,650);
    margin: 0 auto;
  }
}



//アイテム入荷お知らせ
.item_nyuka_holder{
  .image-holder{
    @include mqPcPxSpVw("width",120,150);
  }
}

[data-pageID="cart_index"]{
  .messages--info{
    display: none;
    + .spacer-lg{display: none;}
  }
}

//ご利用ガイド
.guide-rank-table{
  .guide-rank-table-td {
    @include mqPcPxSpVw("padding-top",10,20);
    @include mqPcPxSpVw("padding-bottom",10,20);
  }
}


.AmazonWidgetsArea{
  .widget-container{
    >h1{display: none;}
  }
}


// アクティビティから探す
.section-category.section-activity{
  .list-layout-select-items{
    .list-item:nth-of-type(1){
      display: none;
    }
  }
}
