@import "./_mixin";

/* GROUND animation
***********************************/
@keyframes jsUpShow {
  0% { opacity: 0; transform: translateY(40px); visibility: hidden;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}
@keyframes jsDownShow {
  0% { opacity: 0; transform: translateY(-30px); visibility: hidden;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}
@keyframes jsDownHide {
  0% { opacity: 0; transform: translateY(-30px); visibility: visible;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0); visibility: hidden;}
}

@keyframes jsFedeShow {
  0% { opacity: 0;}
  100% { opacity:1;visibility: visible;}
}
@keyframes jsFedeHide {
  0% { opacity: 1; visibility: visible;}
  100% { opacity:0;visibility: hidden;}
}

@keyframes jsFedeShowDown {
  0% { opacity: 0; transform: translateY(-30px);}
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}
@keyframes jsFedeShowUp {
  0% { opacity: 0; transform: translateY(30px);}
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}

@keyframes jsFedeHideUp {
  0% { opacity: 1; transform: translateY(0); visibility: visible;}
  100% { opacity:0; transform: translateY(-60px); visibility: hidden;}
}

@keyframes jsFedeSlideShow{
  0% { opacity: 0; transform: translateY(40px); visibility: hidden;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}
@keyframes jsFedeSlideHide{
  0% { opacity: 1; transform: translateY(0); visibility: visible;}
  60% { opacity: 0.8; }
  100% { opacity:0; transform: translateY(40px); visibility: hidden;}
}

@keyframes modalInnerShow {
  0% { opacity: 0; transform: translateY(80px); visibility: visible;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0);; visibility: visible;}
}
@keyframes modalInnerHide {
  0% { opacity: 1; margin-top: 0; transform: translateY(0); visibility: visible;}
  100% { opacity:0; transform: translateY(80px); visibility: hidden;}
}

@keyframes fadeIn {
  0% {opacity:0; visibility: hidden;}
  100%{opacity:1; visibility: visible;}
}
@keyframes fadeOut {
  0% {opacity:1; visibility: visible;}
  100%{opacity:0; visibility: hidden;}
}
@keyframes percentWidthUp {
  0% {width: 0;}
  100% {width: 100%;}
}
@keyframes percentWidthDown {
  0% {width: 100%;}
  100% {width: 0%;}
}
.fadeIn{
  animation: fadeIn 0.8s;
}
.fadeInUp{
  animation: jsUpShow 0.8s $easeOutQuart;
}
body{
  transition: opacity 0.8s $easeOutSine;
  &.standBy{
    opacity: 0;
  }
}
.wrap{
  transition: opacity 0.5s $easeOutSine;
  >.contents{
    transition: transform 0.22s
  }
  &.standBy{
    opacity: 0;
    >.contents{
      transform: translateY(-30px);
    }
  }
}
.header{
  &.standBy{
    opacity: 0;
  }
}

.fadeSlide-enter-active{
  opacity: 0;
  animation: jsFedeSlideShow 0.6s $easeOutQuart;
  animation-delay: 0.6s;
}
.fadeSlide-leave-active {
  animation: jsFedeSlideHide 0.6s $easeOutQuart;
}


.jsStanby,.jsStanbyFade{
  visibility: hidden;
}
.jsStanby.jsFadeInUp{
  &.jsShow{
    //visibility: visible;
    animation-name: jsUpShow;
    animation-duration: 1s;
    animation-timing-function: $easeOutQuart;
    animation-fill-mode: forwards;
  }
}
.jsStanby.jsFadeInDown{
  &.jsShow{
    //visibility: visible;
    animation-name: jsDownShow;
    animation-duration: 1s;
    animation-timing-function: $easeOutQuart;
    animation-fill-mode: forwards;
  }
}

.jsStanbyFade{
  animation-name: jsFedeShowDown;
  animation-duration: 0.7s;
  animation-timing-function: $easeOutQuart;
  animation-fill-mode: forwards;
}


.js-onHoverMenu{
  .header-hover-menu{
    animation: fadeIn 0.6s forwards;
  }
  .header-hover-menu .js-hoverMenuOpen{
    visibility: visible;
    opacity: 1;
  }
  .header-hover-menu .js-hoverMenuClose{
    visibility: hidden;
    opacity: 0;
  }
}
.js-offHoverMenu{
  .header-hover-menu{
    animation: fadeOut 0.6s forwards;
  }
  .header-hover-menu .js-hoverMenuClose{
    //animation: fadeIn 0.6s forwards;
  }
}

@keyframes slideInFromLeft {
  0% { opacity: 0; transform: translateX(-40px);}
  100% { opacity:1; transform: translateX(0);}
}
@keyframes slideInFromRight {
  0% { opacity: 0; transform: translateX(40px);}
  100% { opacity:1; transform: translateX(0);}
}
@keyframes jsMenuUpShow {
  0% { opacity: 0; transform: translateY(40px); visibility: hidden;}
  60% { opacity: 0.8; }
  100% { opacity:1; transform: translateY(0); visibility: visible;}
}
@keyframes jsMenuDownHide {
  0% { opacity: 1; transform: translateY(0); visibility: visible;}
  60% { opacity: 0.8; }
  100% { opacity:0; transform: translateY(40px); visibility: hidden;}
}
.navOpen{
  .sp-drawer-menu{
    animation: jsMenuUpShow 0.6s forwards;
  }
}
.navClose{
  .sp-drawer-menu{
    animation: jsMenuDownHide 0.6s forwards;
  }
}

@keyframes jsModalUpShow {
  0% { opacity: 0; margin-top: 40px; visibility: hidden;}
  60% { opacity: 0.8; }
  100% { opacity:1; margin-top: 0; visibility: visible;}
}
@keyframes jsModalDownHide {
  0% { opacity: 1; margin-top: 0; visibility: visible;}
  60% { opacity: 0.8; }
  100% { opacity:0; margin-top: 40px; visibility: hidden;}
}
.serchModalOpen{
  .modal-narrow{animation: fadeIn 0.6s forwards;}
  .modal-narrow .modal-narrow-inner{
    animation: jsModalUpShow 0.6s forwards;
  }
}
.serchModalClose{
  .modal-narrow{animation: fadeOut 0.6s forwards;}
  .modal-narrow .modal-narrow-inner{
    animation: jsModalDownHide 0.6s forwards;
  }
}





/* vue transition
**************************/
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fadeIn-enter-active {
  transition: opacity .5s;
}
.fadeIn-enter {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}

.slide-fade-enter{
  transform: translateX(100px);
  opacity: 0;
}
.slide-fade-leave-to{
  transform: translateX(-100px);
  opacity: 0;
}
.reverse{
  .slide-fade-enter{
    transform: translateX(-100px);
  }
  .slide-fade-leave-to{
    transform: translateX(100px);
  }
}


//history
@keyframes jsShowRight {
  0% { opacity: 0; transform: translateX(-100%);}
  100% { opacity:1; transform: translateX(0); visibility: visible;}
}
.history-number-holder{
  visibility: hidden;
  &.showCounter{
    animation: jsShowRight 0.6s $easeOutQuart forwards;
  }
}
.section-history-read{
  .history-read{visibility: hidden;}
  &.showSection{
    .history-read{
      animation: jsUpShow 0.6s forwards;
    }
  }
}
.hiastory-count-holder{
  .section{
    .text-right-holder,.pc-right-text-holder{
      //visibility: hidden;
    }
    &.showSection{
      .text-right-holder,.pc-right-text-holder{
        //animation: jsUpShow 0.6s $easeOutQuart forwards;
      }
    }
  }
}



