@import "./_mixin";
/* utils  PC SP 共通
***********************************/
body{
  z-index: 0;
  position: relative;
  overflow-y: scroll;
  &.body-disabled{
    pointer-events:none;
  }
}
button{
  cursor: pointer;
}
pre:empty{display: none;}
ul{padding: 0;}
li{list-style: none;}
.clearfix:after {
  content:"";
  display:block;
  clear:both;
}
.swap-img{ opacity: 0; &[src]{opacity: 1;}}
.force3d {backface-visibility: hidden;}
.fullsize{
  width: 100vw;
  min-height: 100vh;
}
.hide{display: none;}

/* １行まるめ
******************/
.text-line-1{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 改行あり
******************/
.ws-pl{
  white-space: pre-line;
  word-wrap: break-word;
}

.ai-center{
  display: flex;
  align-items: center;
}
.vertical-middle{vertical-align: middle;}
.line-height-1{line-height: 1;}
.line-height-1-6{line-height: 1.6;}
.line-height-2{line-height: 2;}
.line-height-sm{line-height: 1.25;}
.line-height-md{line-height: 1.5;}
.line-height-lg{line-height: 1.89;}
//flex系
.flex-item-middle{align-self: center;}
.flex-item-top{align-self: flex-start ;}
.flex-item-bottom{align-self: flex-end;}

.font-weight-100{font-weight: 100;}
.font-weight-200{font-weight: 200;}
.font-weight-300{font-weight: 300;}
.font-weight-400{font-weight: 400;}
.font-weight-500{font-weight: 500;}
.font-weight-600{font-weight: 600;}





.dib{display: inline-block;}
.bg-color-white{background-color: #fff;}
.bg-color-black{background-color: #000;}
.bg-color-transparent{background-color:transparent;}
.text-decoration-none{
  text-decoration: none;
  &:hover{text-decoration: none;}
}

.text-underline{
  text-decoration: underline;
}

.button-decoration-none{
  outline: none;
  border: none;
  &:before,
  &:after{
    content: none;
  }
}

.text-white{color: #fff;}
.text-black{color: #000;}
.tategaki{
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}
.bold{font-weight: bold;}
.text_en{
  opacity: 0;
  .wf-active & {opacity: 1;}
}

.border-transparent-1{border: solid 1px transparent;}
.box-shadow-none{box-shadow: none;}
.width100{width: 100%;}
.font-size0{font-size: 0;}
.disabled-click{
  cursor: default;
  pointer-events:none;
}

.uk-legend{min-height: 0;}
a.nolink{
  cursor: default;
  text-decoration: none !important;
  border-bottom: none !important;
  &:hover{text-decoration: none;}
}