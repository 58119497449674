@import "./_mixin";
/* features PC
***********************************/
.wrap[data-pageid="pickup"]{
  background-color: #fff;
  h1.list_title{
    margin: 0 0 20px;
    padding-top: 5px;
    text-align: center;
    border-bottom: none;
    font-size: 224%;
    font-weight: normal;
    letter-spacing: 0.008em;
  }
  h3{
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }
  h1,h2,h3{color: #545454;}
}
.win body #cat-wrapper > * {
  color: #545454;
  font-family: "Helvetica Neue", Helvetica, Arial,'Noto Sans','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
  word-wrap: break-word;
  -webkit-text-size-adjust : none;
}
.mac body #cat-wrapper > * {
  color: #545454;
  font-family: "Helvetica Neue", Helvetica, Arial,'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
  word-wrap: break-word;
  -webkit-text-size-adjust : none;
}


/************ 旧レイアウト ************/
#cat-wrapper{
  width: 984px;
  margin: 0 auto;
  background-color: #fff;
  p{
    color: #545454;
    font-size: 14px;
    line-height: 2.0;
  }
  hr{
    height: 0; margin: 0;
    padding: 0; border: 0;
  }
  hr.para_line_40{
    border-top: 1px solid #dcdcdc;
    margin: 40px 0;
  }
  hr.para_line_80{
    border-top: 1px solid #dcdcdc;
    margin: 80px 0;
  }
  hr.para_line_120{
    border-top: 1px solid #dcdcdc;
    margin: 120px 0;
  }
  hr.para_margin_80{height: 80px;}
  hr.para_margin_160{height: 160px;}
  hr.para_margin_240{height: 240px;}
  .cresit{font-size: 12px;}
  .txt-center{text-align: center !important;}
  a{
    color:#545454;
    font-size: 14px;
    text-decoration: none;
    &.link_blue{color: #0090d4;}
    &.nolink{color: #545454;}
  }
  a:hover{
    text-decoration: underline;
  }
  img{
    width: 100%;
    vertical-align: middle;
  }
  > p{
    margin-bottom: 60px;
    text-align: center;
  }
  #category_img{
    text-align: center;
    padding: 0 0 30px;
  }
  a.itemlink{
    font-size: 36px;
    display: inline-block;
  }
  a.itemlink + a.itemlink:before{
    content: ",";
    display: inline-block;
    padding: 0 10px;
    text-decoration: none !important;
  }
  a.itemlink + a.itemlink:before:hover{
    text-decoration: none !important;
  }
  .cat-Txt-Max{
    &.right{text-align: right;}
  }
  .cat-Half-Wrap{
    display: -webkit-flex;
    display: flex;
  }
  .cat-Half-Box{
    flex: 1;
    flex-grow: 1;/* IE11 */
    -webkit-flex: 1;
  }
  .cat-Half-Box:first-child{
    margin-right: 10px;
  }
  .cat-Half-Box:last-child{
    margin-left: 10px;
  }
  .cat-Half-Wrap-Reverse{
    display: -webkit-flex;
    display: flex;
  }
  .cat-row-Wrap{
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .cat-row-Box{
    flex: 1;
    flex-grow: 1;/* IE11 */
    -webkit-flex: 1;
  }
  .cat-row-Box .cat-inner h3{
    margin-bottom: 10px !important;
  }
  .cat-row-Box .cat-inner a.itemlink{
    font-size: 26px !important;
    line-height: 32px !important;
    margin-bottom: 10px !important;
  }
  .cat-row-Box:not(:last-of-type){
    margin-right: 20px;
  }
}
// トラベルギア特集系
.cat-typeA{
  text-align: left;
  padding-bottom: 80px;
  h3{margin-bottom: 15px;}
  > div:last-of-type{margin-bottom: 0;}
  .cat-Img-Max{ margin-bottom: 40px;}
  .cat-Txt-Max{ margin-bottom: 80px;}
  .cat-Half-Wrap,
  .cat-Half-Wrap-Reverse,
  .cat-row-Wrap{margin-bottom: 80px;}
  .cat-inner{margin-top: 40px;}
  .cat-row-Box > .cat-inner{margin-top: 20px;}
}
// 画像・テキスト左右配置系
.cat-typeB{
  padding-bottom: 80px;
  .list_title {
    font-size: 48px !important;
    font-weight: 300 !important;
    text-align: left !important;
    margin: 0 0 10px 0 !important;
  }
  h2{
    font-weight: 300;
    font-size: 48px !important;
    line-height: 56px !important;
    margin-bottom: 40px !important;
  }
  h3 {
    font-weight: 300;
    font-size: 42px;
    line-height: 62px !important;
    text-align: left;
    margin-bottom: 20px !important;
  }
  >p {
    margin-bottom: 30px !important;
    text-align: left !important;
  }
  .cat-Half-Wrap {
    align-items: center;
  }
}

/******** 各ページレイアウト調整 ********/
// sa_73
.id-73{
  padding-bottom: 80px;
  > p{
    font-size: 14px;
    text-align: center;
  }
  > p.sa_p2{text-align: left !important;}
  a{
    display: block;
    &:hover{
      text-decoration: none;
      img{opacity: 0.7;}
    }
  }
  .list_title{
    font-size: 180%;
    text-align: center;
    padding-top: 30px;
    margin-bottom: 20px !important;
  }
  .sa_h21{
    font-size: 200%;
    text-align: center;
  }
  #category_img{
    visibility: hidden !important;
    height: 554px;
  }
  .account_top{
    position:relative;
    margin:0 0 80px 0;
    p{
      font-size: 24px;
      line-height: 34px;
      margin: 40px 0 0 44px;
      color: #3c3c3c;
    }
  }
  .account_badge{
    position:absolute;
    top:388px;
    left:704px;
  }
  .account_sp{
    h2 {
      font-size:32px;
      color:#0090d4;
      text-align:center;
      margin:0 0 28px 0;
      border:none;
    }
    h3 {
      position:absolute;
      top:206px;
      width:240px;
      color:#0090d4;
      font-size:18px;
      font-weight:bold;
      text-align:center;
    }
    p {
      margin:252px 0 0 0;
      text-align:center;
      font-size:14px;
      line-height:20px;
    }
    ul {
      width: 992px;
      margin-right: -8px;
      padding-left: 0;
      overflow: hidden;
      height: 100%;
    }
    li {
      position:relative;
      width:240px;
      height:300px;
      float: left;
      margin-right: 8px;
      z-index: 1;
      img {
        width:240px;
        position:absolute;
        left:0;
        z-index:-1;
      }
    }
    .badge{
      opacity: 0.8;
      position:absolute;
      top: 10px; left: 10px;
      width: 46px; height: 46px;
      font-size: 22px;
      color: #fff;
      font-weight:bold;
      padding: 7px 0;
      text-align:center;
    }
  }
  .account_btn {
    display:block;
    width: 600px;
    margin: 80px auto 24px;
    padding: 20px 0 20px;
    font-size: 24px;
    font-weight: bold;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background:#000000;
    text-align:center;
    color:#FFFFFF;
    line-height:24px;
    &:hover {
      background:#3E3E3E;
      cursor:pointer;
    }
  }
}
// karrimor_sf_113
.id-113{
  min-width: 1064px;
  a.nolink{display: none !important;}
}
#sf-container{
  position: relative;
  /*top: -60px;*/
  top: 0;
  left: 0;
  width: 100%;
  .sf-tm:after{
    content: "TM";
    font-size: 50%;
    vertical-align: super;
    padding: 0px 1.5em 0px 0.5em;
  }
  #sf-wrapper{
    position: relative;
    top: 0; left: 0;
  }
  .list_title{display: none;}
  #sf-content{
    min-width: 1064px;
    max-width: 1500px;
    margin: 0 auto;
    padding-bottom: 120px;
    text-align: center;
    background-color: #1f1f1f;
    font-family: 'Helvetica','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
    img{width: auto;}
    p{
      color: #ccc;
      font-size: 18px;
      line-height: 28px;
    }
    p.intro {
      margin-top: 50px;
      margin-bottom: 65px;
    }
  }
  #sf-content-inner{
    background-size: cover;
    background-position: center center;
    max-width: 1500px;
    min-width: 1064px;
    height: 4534px;
    h2{
      font-size: 80px;
      line-height: 1em;
      color: #e6d200;
      border: none;
      margin-bottom: 0;
      font-weight: normal;
    }
    p{margin: 0;}
    a{
      opacity: 1;
      &:hover{opacity: 0.7;}
    }
  }
  .sf-box{
    padding-top: 70px;
    width: 488px;
    padding-right: 40px;
    padding-bottom:20px;
    margin: 0 auto;
    box-sizing: content-box;
    a{display: inline-block;}
    a img{ vertical-align: middle;}
    p {
      padding: 40px;
      text-align: left;
      text-align: justify;
      text-justify: distribute-all-lines;
      letter-spacing: 0.008em;
      margin: 0;
    }
    .color-price{
      margin: 0;
      padding: 0px 0px 20px 0px;
      color: #ccc;
      font-size: 24px;
      text-align: center;
    }
  }
  #sf-box01{padding-top: 70px; padding-left: 536px;}
  #sf-box02{padding-top: 260px; padding-right: 536px;}
  #sf-box03{padding-top: 220px; padding-left: 536px;}
  #sf-box04{padding-top: 320px; padding-right: 536px;}
  #sf-box05{padding-top: 200px; padding-left: 536px;}
  #sf-box06{padding-top: 340px; padding-right: 536px;}
  #sf-box07{padding-top: 170px; padding-left: 536px;}
  #new-color{
    width: 1064px;
    margin: 0 auto;
    h2{
      font-size: 80px;
      color: #e6d200;
      margin-top: 47px;
      margin-bottom: 0;
    }
    h3{
      display: inline-block;
      color: #ccc;
      margin: 0;
      padding-top: 10px;
      height: 60px;
      font-size: 24px;
      font-weight: bold;
    }
    p{
      margin: 0;
      padding: 40px 0;
    }
    #color{font-size: 0;}
    .color-inner{
      vertical-align: top;
      display: inline-block;
      width: 488px;
      img{vertical-align: top; width: 100%;}
      :nth-child(odd){padding-right: 8px;}
    }
  }
}
// black_black_black_186
.id-186{
  .list_title{font-size: 200%;}
}
// karrimor_16_winter_202
.id-202,.id-215,.id-238{
  text-align: left;
  h3{margin-bottom: 35px;}
  .cat-Img-Max, .cat-Txt-Max{margin-bottom: 40px;}
  .cat-Half-Wrap, .cat-Half-Wrap-Reverse{margin-bottom: 40px;}
  #cat-wrapper > div:last-of-type{margin-bottom: 0;}
}
.id-289,.id-220,.id-287{
  padding-bottom: 80px !important;
  h3{margin-bottom: 15px;}
  > p{text-align: left !important;}
  > div:last-of-type{margin-bottom: 0;}
  .cat-Img-Max{margin-bottom: 40px;}
  .cat-Txt-Max{margin-bottom: 80px;}
  .cat-Half-Wrap,
  .cat-Half-Wrap-Reverse,
  .cat-row-Wrap{margin-bottom: 80px;}
  .cat-inner{margin-top: 40px;}
  .cat-row-Box > .cat-inner{margin-top: 20px;}
}
.id-208{
  .cat-Txt-Max{
    h3{margin-top: -20px;}
  }
}
// karrimor_17_summer_215
.id-215{
  .list_title{font-size: 180%;}
  .txt-center{margin-bottom: 30px;}
}
// trek_carry_series_debut_220
.id-220{
  .list_title{font-size: 180%;}
  h3 a{font-size: 20px !important;}
}
// karrimor_17_winter_238
.id-238{
  .list_title{font-size: 180%;}
}
// karrimor_2018_summer_260
#k18sb_main{
  width:100%;
  font-family:"Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  position:relative;
  color:#000 !important;
  &:before{
    content:"";
    display:block;
    width:100%;
    height:12442px;
  }
  .list_title{
    display:none !important;
  }
  #k18sb_main_contents{
    width:1280px;
    margin:0 auto;
    position:absolute;
    top:0; left:0;
    margin-left:-148px;
  }
  #k18sb_main_contents_headerImg{
    width:100%;
    max-width:1194px;
    margin:0 auto;
  }
  #k18sb_main_contents_headerImg>img{
    width:100%;
    height:auto;
    vertical-align:middle;
  }
  #k18sb_main_contents_statement{
    width:100%;
    margin-top:60px;
    > h2 + p{
      margin-top:60px;
    }
    > p + p{
      margin-top:60px;
    }
    >h2{
      color: #3c3c3c !important;
      font-size: 155%;
      line-height: 1em;
      text-align:center;
    }
    >p{
      color: #3c3c3c !important;
      text-align:center;
      font-size:16px;
      line-height:1.8em;
    }
  }
  .k18sb_main_contents_imageGroup{
    width:1280px;
    margin:60px auto 0;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-flow:row-reverse;
    flex-flow:row-reverse;
    -webkit-justify-content:center;
    justify-content:center;
    .figure{
      width:100%;
      position:relative;
      transition:opacity .4s, visibility 0s ease;
      opacity:0;
      visibility:hidden;
      img{
        width:100%;
        height:auto;
        vertical-align:middle;
      }
      &.inviewActive{
        transition-delay:0s;
        opacity:1;
        visibility:visible;
      }
    }
    .figcaption{
      position:absolute;
      bottom:20px;
      z-index:2;
      a{
        display:block;
        color:#fff;
        text-decoration:none;
        font-size:16px;
        line-height:1.5em;
        text-align:left;
        &:before{
          content:"> ";
          display:inline;
        }
      }
      span{
        font-style:italic;
        position:relative;
        &:after{
          content: "";
          display: block;
          width:0%; height:1px;
          background-color:#fff;
          position:absolute;
          left:0; bottom:0;
          transition:width .4s, visibility 4s ease;
        }
      }
      &.inviewActive{
        span:after{
          transition-delay:.4s;
          width:100%;
        }
      }
    }
    .figure.size1 .figcaption{
      left:20px;
      bottom:20px;
    }
    .figure.size2 img{
      box-sizing:border-box;
      padding:0 48px;
    }
    .figure.size2 .figcaption{left:68px}
    .figure.size3 img{
      box-sizing:border-box;
      padding:0 98px;
    }
    .figure.size4 .figcaption{
      left: 68px;
      bottom: 82px;
    }
    &:not(.irregular){
      -webkit-align-items:center;
      align-items:center;
    }
  }
  #k18sb_main_aside{
    width:100%;
    margin:180px auto 120px;
    >p{
      text-align:center;
      font-size:16px;
      line-height:1.8em;
    }
  }
}
@media all and (-ms-high-contrast: none){
  .k18sb_main_contents_imageGroup{
    >.figure{
    opacity:1 !important;
    visibility:visible !important;
    }
  }
}
.id-262{
  .list_title{
    font-size: 180%;
  }
  .cat-Txt-Max{
    h3{margin-top: -20px;}
  }
}
// newridge_2019ss_268
.id-268{
  text-align: left;
  .list_title{font-size: 180%;}
  h3{margin-bottom: 35px !important;}
  > div:last-of-type{margin-bottom: 0;}
  .cat-Img-Max, .cat-Txt-Max{margin-bottom: 40px;}
  .cat-Half-Wrap, .cat-Half-Wrap-Reverse{margin-bottom: 40px;}
}
// karrimor_for_steve_alan_276
.id-276{
  text-align: left;
  .gif{
    width: 452px;
    float: left;
  }
  .gif:last-child{
    float: right;
    width: 452px;
  }
  >p{
    margin-bottom: 30px !important;
    text-align: left !important;
  }
  .cresit{margin: 0 !important;}
  .cat-Half-Box{
    display: block;
    position: absolute;
    flex-grow: 1;
    -webkit-flex: 1;
    .cat-inner h3{
      color: #545454;
      text-align: right;
    }
  }
  .cat-Half-Box:last-child{
    margin: 400px 0 0 360px !important;
    width: 640px;
  }
  .cat-Half-Box:last-child p{
    width: 330px;
    float: right;
  }
  .cat-Img-Max{
    margin: 160px 0;
    overflow: hidden;
  }
  .cat-Txt-Max{
    margin: 40px 0;
  }
  .cat-Txt-Max2{
    margin: 120px 0;
  }
  .cat-Half-Wrap{
    display: inline-block !important;
    height: 800px;
  }
  .cat-Half-Wrap,
  .cat-Half-Wrap-Reverse,
  .cat-row-Wrap{
    margin: 120px 0 40px 0;
  }
  #cat-wrapper > div:last-of-type{
    margin-bottom: 0;
  }
  .cat-row-Box > .cat-inner{
    margin-top: 20px;
  }
}
// mistergentleman_19ss_collection_279
.id-279{
  .list_title{
    line-height: 1.4;
  }
  .lead{
    margin: 80px 0 30px 0 !important;
  }
  .cat-Img-Max{
    margin: 160px 0;
    overflow: hidden;
  }
  .cat-Txt-Max{
    margin: 40px 0;
  }
  .item_link{
    margin: 0 0 20px 0;
  }
  .cat-Half-Box:last-child{
    margin-right: 0 !important;
  }
  .cat-Half-Wrap_left{
    display: -webkit-flex;
    display: flex;
    height: 640px;
    margin: 120px 0 40px 0;
  }
  .cat-Half-Wrap_left .cat-Half-Box{
    flex: 1;
    position: absolute;
    flex-grow: 1;/* IE11 */
    -webkit-flex: 1;
  }
  .cat-Half-Wrap_left .cat-Half-Box:last-child{
    margin: 180px 0 0 344px !important;
    width: 640px;
  }
  .cat-Half-Wrap_left .cat-Half-Box:last-child p{
    width: 330px;
    float: right;
  }
  .cat-Half-Wrap_left h3{
    font-weight: 300;
    font-size: 40px !important;
    line-height: 60px !important;
    margin-bottom: 60px !important;
    text-align: right;
  }
  .cat-Half-Wrap_right{
    display: -webkit-flex;
    display: flex;
    height: 640px;
    margin: 120px 0 40px 0;
  }
  .cat-Half-Wrap_right{
   .cat-Half-Box:first-child{
      margin: 180px 0 0 0;
      p{width: 330px; }
    }
    .cat-Half-Box:last-child{
      float: right;
      width: 600px;
      margin-left: 384px !important;
      position: absolute;
    }
    h3{
      font-weight: 300;
      font-size: 40px !important;
      line-height: 60px !important;
      margin-bottom: 60px !important;
      text-align: left
    }
  }
}
// winter_method_2019_280
.id-280{
  padding-bottom: 80px !important;
  text-align: left !important;
  img {width: 984px !important;}
  h3 {
    font-size: 50px !important;
    line-height: 60px !important;
    margin-bottom: 0 !important;
  }
  >div:last-of-type{
    margin-bottom: 100px;
  }
  .list_title {
    color: #3c3c3c !important;
    font-size: 48px !important;
    font-weight: 300 !important;
    text-align: left !important;
    margin: 0 0 10px 0 !important;
  }
  .lead {
    display: block;
    width: 500px;
    >p {
      margin-bottom: 30px;
      text-align: left;
    }
  }
  .cat-Half-Wrap {height: 2160px;}
  .cat-Half-Box {position: absolute;}
  .cat-Half-Box.typeA {margin: 150px 0 0 640px !important;}
  .cat-Half-Box.typeB {margin: 150px 0 0 40px !important;}
  .cat-Half-Box:last-child {
    margin: 80px 0 0 550px;
    width: 300px;
  }
  .cat-Half-Box:last-child p {
    width: 300px;
    float: left;
  }
  .cat-inner{
    h3 {color: #545454; margin-bottom: 40px !important;}
    p{margin: 0;}
    p a {line-height: 40px !important;}
  }
  .cat-Img-Max {
    margin: 160px 0 300px 0;
    overflow: hidden;
  }
  .cat-Txt-Max {margin: 40px 0 40px 0;}
  .cat-Half-Wrap {
    margin-top: 120px;
    display: -webkit-flex;
    display: flex;
    height: 2160px;
  }
  .cat-row-Box>.cat-inner {margin-top: 20px;}
}
// vt-r_cotton_series_287
.id-287{
  .list_title{
    color: #3c3c3c !important;
    font-size: 180%;
  }
  > p{
    font-size: 14px !important;
    text-align: center !important;
  }
  h3 a{font-size: 20px !important;}
  .cat-Half-Box,.cat-Img-Max{
    p{font-size: 14px;}
  }
}
.id-287{
  .list_title{text-align: center;}
}
// spring_daypack_289
.id-289{}
// carry_more_traveller_290
.id-290{
  >.lead>p {
    margin-bottom: 30px;
    text-align: left;
  }
  img{width: 984px;}
  .list_title{
    color: #3c3c3c !important;
    font-size: 200%;
  }
  .lead {
    margin-top: 50px !important;
  }
  .lead,.textbox {
    display: block;
    width: 500px;
  }
  .textbox {
    margin: 140px 0 180px 40px;
    width: 600px !important;
  }
  .textbox p {
    margin-top: 60px;
    line-height: 2rem;
  }
  .textbox:not(:last-child) p, .lead p {
    font-size: 16px;
    line-height: 2.2rem;
  }
  .cat-Img-Max {
    margin: 120px 0;
    overflow: hidden;
    .cat-Txt-Max {
      margin: 40px 0;
    }
  }
  .cap {
    margin-top: 10px;
    line-height: 1.8;
    a{color: #000 !important;}
  }
  .cap_right {
    text-align: right !important;
    margin-top: 10px;
  }
  .max-big {
    margin-top: 140px !important;
  }
  #A,#C{
    .cat-Half-Box{width: 580px !important;}
    img{
      max-width: 580px;
      width: 580px !important;
    }
  }
  #B{
    .cat-Half-Box{width: 640px !important;}
    img{
      max-width: 640px;
      width: 640px !important;
    }
  }
  #A #item{padding-left: 60px;}
  #C #item{padding-right: 45px;}
  #item{width: 300px !important;}
  .cat-Half-Box:last-child p {
    font-size: 14px;
  }
  .cat-inner{
    h3{margin-bottom: 40px !important;}
    p a{line-height: 60px !important;}
    .textbox h3{
      margin-bottom: 30px !important;
      font-size: 40px !important;
    }
  }
  >div:last-of-type {
    margin: 140px auto;
    text-align: center;
  }
  .cat-row-Box>.cat-inner {
    margin-top: 20px;
  }
}
.my_first_karrimor{
  .hero img{width: 984px;}
  a.nolink{border-bottom: solid 2px #1791d2 !important;}
}

/* FOOTER SHOPPING GUIDE
----------------------------------------------- */
#shoppingguide_area {
  /*font-size: 110%;*/
  font-size: 85%;
  line-height: 200%;
}
#shoppingguide_box {
  width: 984px;
  margin: 0 auto;
  clear: both;
  padding: 62px 0 30px;
  border-top: 1px solid #dcdcdc;
}
#shoppingguide_area h2 {
  font-size: 140%;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px;
}
#shoppingguide_area h3 {
  font-size: 110%;
  margin-top: 0;
  margin-bottom: 2px;
  line-height: normal;
}
#shoppingguide_area p {
  /*font-size: 80%;*/
  margin: 0;
  padding: 0 0 20px;
  line-height: 1.74;
}
#shoppingguide_area a {
  color: #0090d4;
}
#shoppingguide_area .block_body_left {
  width: 344px;
  padding: 0 48px 0 0;
  float: left;
}
#shoppingguide_area .block_body_right {
  width: 296px;
  float: right;
}
#shoppingguide_area .gray {
  padding: 10px 10px 0;
  background: #EAEAEA;
}
#shoppingguide_area table {
  margin: 0 0 30px;
  border-top: 1px solid #ccc;
}
#shoppingguide_area table tr {
  margin: 0 0 30px;
  border-bottom: 1px solid #ccc;
}
#shoppingguide_area th,
#shoppingguide_area td{
  padding: 8px 3px;
  font-weight: normal;
}
#shoppingguide_area th{
  width: 116px;
  display: inline-block;
  background: none;
  /*border-right: 1px solid #ccc;*/
  text-align: left;
}
#shoppingguide_area td{
  width: 180px;
  /*border-right: 1px solid #ccc;*/
  text-align: right;
}



/****** 新レイアウト ******/
.feature-content{
  margin: auto;
  max-width: 1640px;
  .section + .section{
    margin-top: 70px;
  }
  .btn-link{
    text-decoration: underline;
    &:hover{text-decoration: none;}
  }
  .inner{
    .text{line-height: 2;}
  }
  .text-center{
    text-align: center;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right;
  }
  .margin-large{
    margin: 120px auto;
  }
  .margin-medium{
    margin: 80px auto;
  }
  .margin-small{
    margin: 40px auto;
  }
  .text-block{
    margin: auto;
    width: 640/960*100%;
    @media screen and (max-width: 1280px) {
      width: 880/1280*100%;
    }
    @media screen and (max-width: 2040px) {
      width: 880/2040*100%;
    }
    .ttl-topics,.ttl-section{
      margin: 0;
      & + .text{margin-top: 60px;}
    }
    .ttl-topics{
      margin: auto;
      font-size: 40px;
      line-height: 1.25;
    }
    .ttl-section{
      font-size: 30px;
      line-height: 1.33;
    }
    .text{font-size: 20px;}
  }
  .section-main,
  .single-column{
    .pic{margin-bottom: 70px;}
  }
  .section-main{
    .text-block{
      width: 540/960*100%;
      @media screen and (max-width: 1280px) {
        width: 880/1280*100%;
      }
      @media screen and (max-width: 2040px) {
        width: 1280/2040*100%;
      }
    }
  }
  .section-column{
    margin: auto;
    width: 640/960*100%;
    @media screen and (max-width: 1280px) {
      width: 880/1280*100%;
    }
    @media screen and (max-width: 2040px) {
      width: 1280/2040*100%;
    }
    &.wide{
      padding: 0;
      width: 100%;
      .pic{margin-bottom: 70px;}
      .text-block{
        padding: 0;
        width: 720/1280*100%;
        .text{font-size: 20px;}
      }
    }
    &.single-column,&.two-column{
      .text-block{padding: 0;}
    }
    &.single-column{
      text-align: center;
    }
    &.two-column{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .inner{width: 50%;}
      .inner + .inner{
        margin-left: 80px;
      }
    }
    .text-block{
      width: 100%;
      .text{font-size: 16px;}
    }
    .pic{margin-bottom: 30px;}
  }
  .section,.section.wide{
    &.margin-large{margin: 120px auto;}
    &.margin-medium{margin: 80px auto;}
    &.margin-small{margin: 40px auto;}
    .margin-b-xs{margin-bottom: 20px;}
    .margin-b-s{margin-bottom: 40px;}
    .margin-b-sm{margin-bottom: 60px;}
    .margin-b-m{margin-bottom: 80px;}
    .margin-b-xl{margin-bottom: 100px;}
    .margin-b-l{margin-bottom: 120px;}
  }
}













