@import "./_mixin";
/* parts SP
***********************************/
.container-sp-size-m{
  margin: auto;
  padding: 0 50/2+px;
}

.btn-wishlist .icon-heart{
  width: 30/2+px;
  height: 30/2+px;
}
//画面いっぱいのスライダー
.wide-slider{
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 15/2+px;
}
.list-layout-row-slider{
  .list-item{
    width: 470/2+px;
  }
  &.list-layout-item-sizeS{
    .list-item{
      width: 370/2+px;
    }
  }
  &.list-layout-item-sizeSS{
    .list-item{
      width: 220/2+px;
    }
  }
  &.list-layout-item-sizeXS{
    .list-item{
      width: 270/2+px;
    }
  }
  &.list-layout-item-sizeAuto{
    .list-item{
      width: auto;
    }
  }
}

//top style レイアウト スライダー
.list-layout-styles-slider{
  margin-left: 0;
  @media all and (min-width: 1961px) {
    margin-left: auto;
    padding-left: 0;
  }
  .list-item{
    width: 650/2+px;
  }

  .list-alternate .list-alternate-item{
    width: calc(50% - 10px);
  }
  .list-alternate .list-alternate-item:first-child{
    width: 100%;
  }
  .list-item:nth-of-type(2n){
    .list-alternate .list-alternate-item:first-child{
      width: calc(50% - 10px);
    }
    .list-alternate .list-alternate-item:nth-child(5n){
      width: 100%;
    }
  }
}

//レーベルレイアウト
.list-layout-pc-half{
  margin-top: -100/2+px;
  .list-item{
    margin-top: 100/2+px;
    width: 100%;
  }
}


//MV
.main-slideshow,
.mainVisual-slider{
  .uk-slideshow-items{
    padding-top: 0;
    height: 1000/750*100vw;
    .video-holder{
      height: 1000/750*100vw;
      width: 100vw;
    }
  }
  .image-holder{
    width: 100%;
    height: 1000/2+px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// 表示順選択,こだわり検索
.filter-block{
  padding: 0 25px;
  .rearrange-block select.select-el,
  .rearrange-block .select-el-parent{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.filter-block-feature{
  padding: 0;
}

//スタイルズ詳細
.list-layout-styles-items{
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 15/2+px;
  .list-item-module{
    width: 450/2+px;
  }
}

// カテゴリ一覧 アクティビティから探す
.section-activity{
  .list-layout-select-items{
    margin: 0 -5px;
  }
  .list-item{
    margin: 5px;
    width: calc(50% - 10px);
  }
}
//wp カテゴリTOP
.list-layout-pc-4col{
  margin-top: -100/2+px;
  .list-item-module,.list-item{
    margin-top: 100/2+px;
    padding-right: 0;
  }
}
.list-layout-first-large{
  .list-item-module,.list-item{
    &:first-child{
      .img-holder{
        padding-top: 867/2+px;
      }
    }
  }
}
// カテゴリ一覧 容量から探す
.section-capacity{
  .list-item-module{
    padding-right: 0;
  }
  .list-item-module ul{
    margin-right: -10/2+px;
  }
  .list-item{
    margin-right: 2px;
  }
}

// お気に入り一覧
.section-wishlist{
  overflow: hidden;
  .uk-position-top-right{
    right: 15px;
  }
}

// レーベル詳細 パララックスエリア
.item-parallax-holder{
  .bg-parallax{
    height: 1000/2+px;
  }
  &.ultimate{
    .n1{background-image: url("../img/label/ultimate/bg-ultimate-01-sp.jpg");}
    .n2{background-image: url("../img/label/ultimate/bg-ultimate-02-sp.jpg");}
  }
  &.explorer{
    .n1{background-image: url("../img/label/explorer/bg-explorer-01-sp.jpg");}
    .n2{background-image: url("../img/label/explorer/bg-explorer-02-sp.jpg");}
    .n3{background-image: url("../img/label/explorer/bg-explorer-03-sp.jpg");}
  }
  &.life{
    .n1{background-image: url("../img/label/life/bg-life-01-sp.jpg");}
    .n2{background-image: url("../img/label/life/bg-life-02-sp.jpg");}
    .n3{background-image: url("../img/label/life/bg-life-03-sp.jpg");}
  }
  &.aspire{
    .n1{background-image: url("../img/label/aspire/bg-aspire-01-sp.jpg");}
    .n2{background-image: url("../img/label/aspire/bg-aspire-02-sp.jpg");}
  }
  .btn-parallax{
    .btn-default{
      margin: auto;
      width: 250/2+px;
    }
  }
}


// ギフトラッピング アコーディオン
/**
.list-accordion.uk-accordion{
  .uk-accordion-title{
    font-size: 40/2*1px;
    position: relative;
    &:before{
      position: absolute;
      top: 50%;
      right: 0;
      width: 13px;
      height: 13px;
      margin-top: -13px*0.5;
    }
  }
  >li{
    margin-top: 0;
    padding-top: 27px;
    padding-bottom: 27px;
  }
  .uk-accordion-content{
    margin-left: 50px*0.5;
    .img-holder-s{
      width: 250px;
      margin: auto;
    }
    .uk-list li{
      &:before{
        background-size: 3px;
      }
    }
  }
}
**/


// お問い合わせする項目

.uk-form-controls{
  .radio-online{
    margin-top: -20px;
  }

  // 　オンラインストア位置調整
  .online-service{
    display: inline-block;
    margin-left: -4px;
  }

  // セキュリティコード幅
  .security-code-width{
    width: 310/750*100vw;
  }

  // クレジットカード有効期限：月幅
  .cregit-month-width{
    width: 310/750*100vw;
    margin-right: 30/750*100vw;
  }

  // クレジットカード有効期限：年幅
  .cregit-year-width{
     width:  310/750*100vw;
  }
}

// お問い合わせ　住所検索ボタン　幅
.uk-grid-small{
  .address-search-width{
    text-align: center;
    width: 100%;
  }
}

.fieldset-mailmagazine{
  margin-left: -25px;
  margin-right: -25px;
  .uk-form-controls{
    > span{
      margin-bottom: 10px;
      display: inline-block;
      margin-right: 1em;
    }
  }
}

// AmaozonPayment
.AmazonWidgetsArea{
  display: block !important;
  #addressBookWidgetDiv,
  #walletWidgetDiv{
    display: block !important;
    width: 100% !important;
    padding: 0 !important;
  }
  #addressBookWidgetDiv{
    margin-bottom: 90/2+px;
  }
}




/* modal ex) ultimate project
********************************/
.btn-modal{
  .icon-play{
    pointer-events: none;
  }
}
.modal{
  .btn-modal-close{
    color: #FFF;
    position: absolute;
    top:  3vw;
    right: 3vw;
    cursor: pointer;
  }
  .modal-inner{
    width: 94%;
  }
}

