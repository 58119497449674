@import "./_mixin";
/* utils SP
***********************************/
.btn{
  //display: inline-block;
  .icon{display: block;}
}

.pcOnly{display: none !important;}
.sp-flex{display: flex;}
.sp-flex-wrap{flex-wrap:wrap;}
.sp-flex-middle{align-items: center;}
.sp-flex-end{align-items: flex-end;}
.sp-flex-between{justify-content: space-between;}
.sp-flex-item-end{align-self: flex-end;}


.margin0{margin: 0;}.margin-b0{margin-bottom: 0;}
.padding0{padding: 0;}
