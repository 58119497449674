@import "./_mixin";
/* features SP
***********************************/
.android body #cat-wrapper > * {
  color: #3c3c3c;
  font-family: 'Noto Sans','ヒラギノ角ゴ Pro W3', "Helvetica Neue", Helvetica, Arial, 'Hiragino Kaku Gothic ProN', sans-serif;
  line-height: 150%;
  word-wrap: break-word;
  -webkit-text-size-adjust : none;
}
.ios body #cat-wrapper > * {
  color: #3c3c3c;
  font-family:  Helvetica, "Helvetica Neue", Arial, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
  line-height: 150%;
  word-wrap: break-word;
  -webkit-text-size-adjust : none;
}

h1.list_title{
  margin: 10px 0 30px;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}

/****** 旧レイアウト ******/
#cat-wrapper{
  text-align: left;
  margin: 30px 0 0;
  padding-bottom: 80px;
  background-color: #fff;
  > *{margin: auto 15px;}
  p{
    line-height: 1.8;
    font-size: 16px;
    &.cap{font-size: 12px;}
  }
  > p{
    margin-bottom: 60px;
    text-align: center;
  }
  hr {height: 0; margin: 0; padding: 0; border: 0; }
  hr.para_line_40{border-top: 1px solid #dcdcdc; margin: 40px 0; }
  hr.para_line_80{border-top: 1px solid #dcdcdc; margin: 80px 0; }
  hr.para_line_120{border-top: 1px solid #dcdcdc; margin: 120px 0; }
  hr.para_margin_80{height: 80px;}
  hr.para_margin_160{height: 160px;}
  hr.para_margin_240{height: 240px;}
  a,a.nolink{
    color:#545454;
    font-size: 16px;
    &:link,&:visited,
    &:active,&:hover{color:#545454;}
  }
  a.link_blue{
    &:link,&:visited,
    &:active,&:hover{color:#0090d4;}
  }
  a.link_gray{
    &:link,&:visited,
    &:active,&:hover{color:#b8b8b8;}
  }
  .cresit{font-size: 12px !important;}
  .txt-center{ text-align: center;}
  .txt-left{ text-align: left;}
  #category_img{
    margin: 0 auto 30px;
  }
  .list_title{
    font-size: 20px;
    margin: 0 auto 30px !important;
  }
  h3{
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  img{
    width: 100%;
    vertical-align: middle;
  }
  a.itemlink{
    color: #0090d4;
    font-size: 36px;
    display: inline-block;
  }
  a.itemlink + a.itemlink:before{
    content: ",";
    display: inline-block;
    padding: 0 10px;
    text-decoration: none!important;
  }
  a.itemlink + a.itemlink:before:hover{
    text-decoration: none!important;
  }
  .cat-Txt-Max{
    &.right{text-align: right;}
  }
  .cat-Half-Wrap{
    display: -webkit-flex;
    display: flex;
  }
  .cat-Half-Box{
    flex: 1;
    flex-grow: 1;/* IE11 */
    -webkit-flex: 1;
  }
  .cat-Half-Box:first-child{
    margin-right: 10px;
  }
  .cat-Half-Box:last-child{
    margin-left: 10px;
  }
  .cat-Half-Wrap-Reverse{
    display: -webkit-flex;
    display: flex;
  }
  .cat-row-Wrap{
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .cat-row-Box{
    flex: 1;
    flex-grow: 1;/* IE11 */
    -webkit-flex: 1;
  }
  .cat-row-Box .cat-inner h3{
    margin-bottom: 10px !important;
  }
  .cat-row-Box .cat-inner a.itemlink{
    font-size: 26px !important;
    line-height: 32px !important;
    margin-bottom: 10px !important;
  }
  .cat-row-Box:not(:last-of-type){
    margin-right: 20px;
  }
  /*-- margin ------------*/
  .cat-Img-Max{ margin-bottom: 40px;}
  .cat-Txt-Max{ margin-bottom: 80px;}
  .cat-Half-Wrap,
  .cat-Half-Wrap-Reverse{ margin-bottom: 80px;}
  #cat-wrapper > div:last-of-type{ margin-bottom: 0;}
  .cat-inner{margin-top: 40px;}
  .sa_p1{
    margin-top: 8px;
    margin-bottom: 64px;
  }
  .sa_h21{
    font-size: 22px;
    font-weight: normal;
    color: #404040;
    text-decoration: none;
    border-bottom: none;
    line-height: 1.4;
    text-align: center;
  }
  .account_sp ul li{
    position: relative;
  }
  .account_sp li p{
    font-size: 80%;
    text-align: center;
  }
}
.cat-typeB{
  padding-bottom: 80px;
  .list_title {
    font-size: 28px;
    margin: 0 30px;
    font-weight: 300 !important;
  }
  .lead {
    display: block;
    width: 315px;
    margin: 30px 30px 0;
  }
  h2 {
      font-weight: 300;
      font-size: 48px !important;
      line-height: 56px !important;
      margin-bottom: 40px !important;
  }
  >p{
    margin-bottom: 30px;
    text-align: left;
  }
  .textbox h3 {
    margin-bottom: 40px;
    font-weight: normal !important;
    font-size: 28px !important;
    font-feature-settings: "pkna";
    letter-spacing: 2px;
  }
  .cat-Half-Box h3 {
    font-weight: 300;
    font-size: 46px !important;
    line-height: 52px !important;
    text-align: right;
  }
  .cat-inner{
    h3{
      font-size: 34px !important;
      margin-bottom: -5px;
    }
  }
}
/****** 各ページレイアウト調整 ******/
// sa_73
.id-73{
  padding-bottom: 0 !important;
  #sa_category_img{
    position:absolute;
    /*top:60px;*/
    top: 0;
    width: 100%;
    margin: 0;
    img{width: 100%; height: auto;}
  }
  #account_badge{
    position:absolute;
    width: 37.5%;
    top: 70%; right: 3%;
    img {width:100%;}
  }
  .list_title{padding-top: 105vw;}
  .sa_h21{
    margin-bottom: 20px !important;
  }
  .sa_p1,.sa_p2{
    margin: 0 15px;
    text-align: left !important;
  }
  .sa_p1{
    margin-top: 8px !important;
    margin-bottom: 64px !important;
  }
  .sa_p2{
    padding: 48px 0 64px;
    &.last{
      padding-bottom: 0;
      margin-bottom: 0 !important;
    }
  }
  .account_sp{
    margin: 0 !important;
    h2{
      font-size:20px;
      color:#0090d4;
      text-align:center;
      border:none;
    }
    h3{
      position: absolute;
      top: 85.2112%;
      width: 100%;
      color: #0090d4;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }
    p {
      margin: 6% 0 10% 0;
      font-size: 1em;
    }
    ul{
      width: 100%;
      overflow: hidden;
      padding-left: 0;
      margin: 0 0 0 1.875%;
    }
    li{
      float: left;
      width: 44.375%;
      margin :0 1.875%;
      img{width: 100%;}
    }
    .sp{position: relative;}
    .badge{
      position: absolute;
      top: 3.52112676%;
      left: 3.52112676%;
      width: 40px;
      height: 40px;
      background-size: cover;
      padding-top: 10px;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: table-cell;
      p{
        position: absolute;
        top: 50%;
        margin: -10px 0 0 0;
        width: 100%;
      }
    }
  }
}
// karrimor_sf_113
.id-113{
  margin: 0 !important;
  .list_title{padding-top: 30px;}
  a.nolink{display: none !important;}
}
#sf-wrapper{
  position: relative;
  top: 0;
  left: 0;
  /*top: -25px;*/
  /*top: -27px !important;*/
  margin-left: 0;
  text-align: center;
  width: 100%;
  background-color: #1f1f1f;
  font-family: 'Helvetica','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
  .sf-tm:after{
    content: "TM";
    font-size: 50%;
    vertical-align: super;
    padding: 0px 1.5em 0px 0.5em;
  }
  .pagenumberarea{
    padding: 15px;
    margin: 0 4%;
  }
  .list_wrapper{
    width: 84.64%;
    margin: 0 auto;
  }
  #sf-content{
    background-color: #1f1f1f;
    p,.sf-link,.sf-color{
      margin: 0 12px !important;
    }
    p{
      text-align: left;
      color: #ccc;
      font-size: 12px;
      padding: 0px 0px 20px 0px;
      line-height: 17px;
    }
    h2{
      margin: 0;
      color: #e6d200;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
  .sf-item h2,.sf-color h2{
    border: none;
    color: #e6d200;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    background: none;
    font-weight: normal;
  }
  .sf-item{
    img{padding-top: 35px;}
    .sf-price{
      display: block;
      font-size: 14px;
      color: #999999;
      padding: 0px 0px 20px 0px;
      text-align: center;
    }
    .sf-link{
      display: block;
      color: #e6d200;
      font-size: 15px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 3px;
      border: 2px #e6d200 solid;
      text-align: center;
      padding: 15px 0px;
      text-decoration: none;
    }
  }
  .sf-color{
    padding-bottom: 10px;
    h2{
      padding-top: 60px;
      padding-bottom: 20px;
    }
    p{padding-bottom: 40px;}
    img{width: 100%;}
    .sf-color-inner p{
      text-align: center !important;
      padding-top: 9px !important;
      padding-bottom: 20px !important;
      font-size: 16px !important;
    }
  }
}
// black_black_black_186
.id-186{
  > p{
    margin: 0 15px;
    text-align: left !important;
  }
  .txt-left{
    margin-top: 30px !important;
  }
  .cat-inner{margin: 0 15px;}
  .cat-row-Wrap{
    flex-direction: column;
  }
}
// karrimor_16_winter_202
.id-202{
  h3{margin-bottom: 15px;}
  center{margin-top: 30px;}
  .cat-Img-Max,.cat-Txt-Max{
    margin: 0 15px;
  }
}
// karrimor_travelgear_vol1_208
.id-208{
  > *{margin: 0 15px;}
  .list_title{
    font-size: 20px !important;
  }
  .txt-left{
    margin-top: 30px !important;
  }
  .cat-row-Wrap{
    flex-direction: column;
  }
}
// karrimor_17_summer_215
.id-215{
  .txt-center{margin-bottom: 30px;}
}
// trek_carry_series_debut_220
.id-220{
  padding-bottom: 0 !important;
  .list_title{
    margin-bottom: 30px !important;
  }
  .link_blue{color: #3C3C3C !important;}
  .cat-Half-Wrap.last{
    margin-bottom: -20px !important;
  }
}
// karrimor_17_winter_238
.id-238{
  .list_title{
    font-size: 18px;
  }
}
// karrimor_2018_summer_260
#cat-wrapper.id-260{
  > *{
    margin:0 0 100px !important;
  }
  .list_title{display:none !important}
  .k18sb_main_spacer{
    // width: 100%; height: 100%;
    height: 0 !important;
  }
  #k18sb_main{
    width: 100vw;
    font-family: "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    position: relative;
    color: #000 !important;
  }
  #k18sb_main_contents{
    width: 100vw;
    overflow-x: hidden;
    /*position: absolute;*/
    /*top: 0; left: 0;*/
    // margin-left: -15px;
  }
  #k18sb_main_contents_headerImg{
    width:100%;
    margin: 0 !important;
    img{height: auto;}
  }
  #k18sb_main_contents_statement{
    width: 100%;
    margin-top: 8vw;
    padding: 0;
    box-sizing: border-box;
    >h2{
      text-align: center;
      font-size: 16px;
      line-height: 1em;
      padding: 0 !important;
      font-weight: normal !important;
    }
    >h2+p{margin: 16vw 0 0;}
    >p{
      text-align: center;
      font-size: 12px;
      line-height: 1.8em;
      padding:0 !important;
    }
    >p+p{margin: 8vw 0 0;}
  }
  .k18sb_main_contents_image{
    width: 100%;
    transition: opacity .4s ease;
    opacity: 0;
    &.inviewActive{
      transition-delay: 0s;
      opacity: 1;
    }
    &:not(.k18sb_main_contents_image2){
      margin-top: 16vw;
    }
    .figure{
      width: inherit;
      position: relative;
      box-sizing: border-box;
      img{height: auto;}
    }
    .figcaption{
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 2;
      a{
        display: block;
        color: #fff;
        text-decoration: none;
        font-size: 8px;
        line-height: 1.5em;
        &:before{
          content: "> ";
          display: inline;
        }
      }
      span{
        font-style: italic;
        position: relative;
      }
      span:after{
        content: "";
        display: block;
        width: 0%; height: 1px;
        background-color: #fff;
        position: absolute;
        left: 0;bottom: 0;
        transition: width .4s, visibility 4s ease;
      }
      &.inviewActive{
        span:after{
          transition-delay: .4s;
          width: 100%;
        }
      }
    }
    .figure.size2{
      width: calc(100% - 14.666666666666666vw);
      margin-left: auto;
      margin-right:auto;
    }
    .figure.size3{
      width: calc(100% - 30.666666666666664vw);
      margin-left: auto;
      margin-right:auto;
    }
    .figure.size4>.figcaption{
      left: calc(7.333333333333333vw + 10px);
      bottom: calc(9.2vw + 10px);
    }
  }
  #k18sb_main_aside{
    width: 100%;
    margin: auto 15px;
    margin-top: 16vw;
    box-sizing: border-box;
    >p{
      font-size: 8px;
      line-height: 1.6em;
    }
    >p+p{margin-top: 5.333333333333334vw;}
  }
}
// karrimor_travelgear_vol3_262
.id-262{
  .txt-left{
    margin-top: 30px !important;
  }
  .cat-row-Wrap{
    flex-direction: column;
  }
}
// newridge_2019ss_268
.id-268{
  .txt-left{margin-top: 30px !important;}
}
// karrimor_for_steve_alan_276
.id-276{
  padding: 0 0 80px 0;
  >*{padding: 0 15px;}
  #category_img{
    padding: 0 !important;
  }
  .gif{
    width: 46%;
    float: left;
  }
  .gif:last-child{
    float: right;
    width: 46%;
  }
  .cresit{margin: 0;}
  .list_title{
    font-size: 28px !important;
    padding: 0 30px;
    text-align: left;
  }
  .txt-left{
    margin-top: 30px !important;
  }
  h2{
    text-align: left;
    padding: 0 !important;
    font-size: 32px !important;
  }
  h3{
    font-weight: 200;
    font-size: 26px !important;
    line-height: 40px !important;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 0;
    padding: 0 !important;
  }
  .cat-Img-Max{
    margin: 80px 0 !important;
    overflow: hidden;
  }
  .cat-Img-Max2{margin: 60px 0 0 !important;}
  .cat-Ttl-Max{ margin: 40px 0 !important;}
  .cat-Txt-Max{ margin: 40px 0 !important;}
  .cat-Half-Wrap .cat-inner{ margin-top: 0;}
  .cat-Half-Wrap .cat-inner p{ margin: 0;}
  .cat-Half-Wrap, .cat-Half-Wrap-Reverse, .cat-row-Wrap{  margin: 40px 0 40px 0;}
  #cat-wrapper > div:last-of-type{ margin-bottom: 0; font-size: 12px;}
  .cat-row-Box > .cat-inner{margin-top: 20px;}
  .cat-Txt-Max2{ margin: 80px 0 80px 0; font-size: 12px;}
  .cat-Txt-Max2 p a:link{
    color:#0090d4 !important;
    text-decoration: underline;
  }
}
// mistergentleman_19ss_collection_279
.id-279{
  padding: 0 0 80px 0;
  >*{
    margin: 0 !important;
    padding: 0 30px;
  }
  >p{
    margin-bottom: 30px;
    text-align: left;
  }
  #category_img{
    padding: 0 !important;
  }
  .list_title{
    font-size: 28px !important;
    padding: 30px 30px 0 !important;
  }
  .lead{
    padding: 0 !important;
    text-align: left !important;
    margin: 0 auto 30px !important;
  }
  .cresit{
    // margin: 0 15px !important;
    &.link_blue{margin: 0 !important;}
  }
  .item_link{
    font-size: 12px;
    margin: 0 0 20px 0;
  }
  .item_cap{
    font-size: 12px;
    margin: 0;
  }
  .nyuka a{
    text-decoration: underline !important;
  }
  .cat-Img-Max{
    margin: 80px 0 !important;
    overflow: hidden;
  }
  .cat-Img-Max2{margin: 60px 0 0 0 !important;}
  .cat-Ttl-Max{ margin: 40px 0 !important;}
  .cat-Txt-Max{margin: 40px 0 !important;}
  #cat-wrapper > div:last-of-type{  margin-bottom: 0;}
  .cat-inner{margin-top: 0;}
  .cat-row-Box > .cat-inner{margin-top: 20px;}
}
// winter_method_2019_280
.id-280{
  margin: 0 !important;
  #category_img{margin: 0 0 30px 0 !important;}
  >div:last-of-type {margin-bottom: 0;}
  h2{
    font-weight: 300;
    font-size: 48px !important;
    line-height: 56px !important;
    margin-bottom: 40px !important;
  }
  h3{
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 40px !important;
    line-height: 52px !important;
    text-align: right;
  }
  .list_title {
    font-size: 28px !important;
    font-weight: 300 !important;
  }
  .lead{
    display: block;
    width: 315px;
    margin: 30px auto 0 !important;
  }
  .lead > p{
    margin-bottom: 30px;
    text-align: left;
  }
  .item_caption{
    margin: 35px 0 10px 0;
  }
  .plusA{
    padding: 40px 0 0 0;
  }
  .cat-Half-Wrap {
    display: inline-block;
    height: 465px;
    margin-top: 120px;
    display: -webkit-flex;
    display: flex;
    margin: 0 !important;
    .cat-inne h3{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .cat-Half-Box{
    position: absolute;
    width: 100%;
    .cat-inner{
      margin-top: 0 !important;
      .item_url{margin: 0 !important;}
      h3{margin: 0 0 10px 0 !important;}
      p a{line-height: 20px !important;}
    }
    &.typeA{
      margin: 172px 0 0 100px !important;
      width: 260px !important;
      h3{letter-spacing: -0.05em;}
    }
    &.typeB{
      margin: 172px 0 0 15px !important;
      width: 260px !important;
      h3{text-align: left !important;}
    }
    &.typeC{
      margin: 220px 0 0 100px !important;
      width: 260px !important;
    }
    &:last-child{
      margin: 35px 0 0 190px;
      width: 345px;
    }
    &:last-child p{
      width: 260px !important;
      float: left !important;
    }
  }
  .cat-Img-Max {
    margin: 100px 0 60px 0 !important;
    overflow: hidden;
    &.Divider {margin-bottom: 160px;}
  }
  .cat-Txt-Max {
    margin: 50px 0 50px 0;
  }
}
// vt-r_cotton_series_287
.id-287{
  .cat-Half-Wrap{
    flex-direction: column;
  }
  .cat-Half-Box{
    &:first-child{margin-right: 0 !important;}
    &:last-child{margin-left: 0 !important;}
  }
}
// spring_daypack_289
.id-289{}
// carry_more_traveller_290
.id-290{
  margin: 0 auto !important;
  .list_title{
    font-size: 28px !important;
  }
  .item_caption {
    margin: 20px 0 10px 0;
    width: 250px !important;
  }
  .lead{
    margin: 30px auto 0 !important;
  }
  >.lead>p{
    margin-bottom: 30px;
    text-align: left;
  }
  .plusA{
    padding: 40px 0 0 0;
  }
  .textbox {
    width: 285px;
    margin: 100px auto !important;
    h3 {
      margin-bottom: 40px;
      line-height: 1.8 !important;
      font-weight: normal !important;
      font-size: 28px !important;
      font-feature-settings: "pkna";
      letter-spacing: 2px;
    }
  }
  .typeA p.cap{
    font-size: 12px !important;
  }
  p.cap{
    margin: 10px 15px !important;
    a {
      font-size: 12px !important;
      text-decoration: underline !important;
    }
  }
  p.item_url{margin: 0;}
  .cap_right {text-align: right;}
  .cat-Half-Box {
    position: absolute;
    margin-top: 300px;
  }
  .cat-Half-Box:last-child{
    margin: 0;
    p{width: 260px; float: left;}
  }
  .typeA p{
    font-size: 14px !important;
    line-height: 1.6rem !important;
  }
  .cat-Half-Box.typeA {
    margin: -50px 0 0 120px !important;
    width: 240px;
  }
  .cat-Half-Box.typeA .cat-inner{
    margin-top: 0 !important;
  }
  .cat-Half-Box.typeA .cat-inner h3{
    margin: 0 !important;
  }
  .cat-inner p a{
    line-height: 20px !important;
  }
  #outro p{
    text-align: center !important;
  }
  .cat-Img-Max {
    margin: 80px 0 !important;
    overflow: hidden;
  }
  .cat-Img-Max.Divider {
    margin-bottom: 160px;
  }
  .cat-Half-Wrap {
    display: inline-block;
    height: 630px;
    margin: 120px 0 0 !important;
    display: -webkit-flex;
    display: flex;
  }
  #cat-wrapper>div:last-of-type {
    margin-bottom: 0;
  }
  .cat-row-Box>.cat-inner {
    margin-top: 20px;
  }
}
.my_first_karrimor{
  a.nolink{border-bottom: solid 2px #1791d2 !important;}
}



/****** 新レイアウト ******/
.feature-content{
  margin: auto;
  .section + .section{
    margin-top: 80/750*100vw;
  }
  .btn-link{
    text-decoration: underline;
  }
  .text-center{
    text-align: center;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right;
  }
  .margin-large{
    margin: 150/750*100vw auto;
  }
  .margin-medium{
    margin: 100/750*100vw auto;
  }
  .margin-small{
    margin: 50/750*100vw auto;
  }
  .inner{
    margin: auto;
    .text{line-height: 2;}
  }
  .text-block{
    margin: auto;
    padding: 0 100/750*100%;
    .text{
      margin: 0;
      line-height: 2;
      font-size: 30/2+px;
    }
  }
  .ttl-topics,
  .ttl-section{
    & + .text{
      margin-top: 70/750*100vw;
    }
  }
  .ttl-topics{
    font-size: 60/2+px;
    line-height: 1.23;
  }
  .ttl-section{
    font-size: 50/2+px;
    line-height: 1.4;
  }
  .section-main,.section-column{
    .pic{margin-bottom: 85/750*100vw;}
  }
  .section-column{
    margin: auto;
    &.single-column,&.two-column{
      padding: 0 100/750*100%;
      .pic{margin-bottom: 35/750*100vw;}
      .text-block{
        padding: 0;
        .text{font-size: 24/2+px;}
      }
    }
    &.single-column{
      .icon-play{
        width: 110/750*100vw;
        height: 110/750*100vw;
      }
    }
    &.two-column{
      .inner + .inner{
        margin-top: 90/750*100vw;
      }
    }
  }
  .section{
    &.margin-large{margin: 150/750*100vw auto;}
    &.margin-medium{margin: 100/750*100vw auto;}
    &.margin-small{margin: 50/750*100vw auto;}
    .margin-b-xs{margin-bottom: 20/750*100vw;}
    .margin-b-s{margin-bottom: 40/750*100vw;}
    .margin-b-sm{margin-bottom: 60/750*100vw;}
    .margin-b-m{margin-bottom: 80/750*100vw;}
    .margin-b-xl{margin-bottom: 100/750*100vw;}
    .margin-b-l{margin-bottom: 120/750*100vw;}
  }
}
body[data-categoryid="item_detail"]{
  .section,
  .modal-vimeo .setVideoSize.video-holder{
    .icon-play{
      width: 150/750*100vw;
      height: 150/750*100vw;
    }
  }
}




