@import "./_mixin";
/* layout PC
***********************************/
.btn-b2b{
  height: 80px;
  width: 100%;
  background-color: #0090D4;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  &:hover{
    color: #FFF;
  }
  .icon-arrow{
    display: block;
    transform: translateY(-2px);
    margin-left: 25px;
  }
}
.btn-b2b + header.header{
  top: 80px;
}

.wrap{
  padding-top: 120px;
}
.sp-navigation{
  display: none;
}
.wrap-top-0{
  .wrap{padding-top: 0;}
  .header:not(.js-headerTopFix){
    .brand-logo{fill:#FAFAFA;}
    .g-menu-item a{color: #FAFAFA;}
    .g-sub-menu i{color: #FAFAFA;}
  }
}
header.header{
  position: absolute;
	left: 0; top: 0;
	width: 100%;
	z-index: 5;
  //margin-bottom: 40px;
  transition: transform 0.4s $easeOutQuart , background-color 0.4s $easeOutQuart;
  &.js-hideUpHeader{
    transform: translateY(-100%);
    //.brand-logo{transform: scale(1, 1);}
  }
  &.js-headerTopFix{
    position: fixed;
    top: 0;
    background-color: #fff;
    //.brand-logo{transform: scale(1, 1);}
  }
  .js-onHoverMenu & {
    //.brand-logo{transform: scale(1, 1);}
    .header-container{
      .brand-logo{fill:#000;}
      .g-menu-item a{color: #000;}
      .g-sub-menu i{color: #000;}
    }
  }
  .brand-logo{
    display: block;
    margin: auto;
    width: 53px;
    transform-origin: center center;
    //transform: scale(1.4, 1.4);
    //transition: transform 0.4s $easeOutQuart;
  }
  nav{
    height: 60px;
  }
  .header-container{
    height: 120px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .g-nav{
    display: flex;
    align-items: center;
  }
  .g-menu{
    display: flex;
    align-items: center;
    padding-left: 18px;
    min-width: 670px;
    .g-menu-item{
      a{
        padding: 10px 19px;
      }
    }
  }
  .g-sub-menu{
    .btn-favorite{
      .uk-icon{
        width: 19px;
        svg{width: 100%;}
      }
    }
    .btn-cart{
      .uk-icon{
        width: 20px; height: 20px;
        svg{width: 100%;}
      }
    }
  }
}
.header-hover-menu{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  .header-hover-menu-inner{
    width: 100%;
    visibility: hidden;
    opacity: 0;
    padding-top: 120px;
  }
}
.sp-drawer-menu{display: none;}
footer.footer{
  background-color: #fff;
  padding-top: 120px;
	position: relative;
	z-index: 0;
	.footer-inner{
	}
  .brand-logo{
    display: block;
    margin: auto;
    width: 80px;
    svg{width: 100%;}
  }
  .nav-footer{
    font-size: 12px;
    .uk-text-bold{
    }
  }
  .nav-footer{
    max-width: 1360px;
    margin: auto;
  }
  .nav-footer-sns-list{
    .icon{width: 24px;}
  }
  .nav-footer-list{
    a{line-height: 1.6;}
  }
}
/** ↓↓ 共通パンくず ↓↓ **/
.breadcrumb-block{
  padding: 0 0 24px;

}

/** ↓↓ マガジン・アンバサダー 記事 ↓↓ **/
.article-holder{
	.cat-holder{
		.cat-thumb{
      width: 70px;
      min-height: 70px;
    }
	}
}
//既存コンテンツ揃える
.article-content{
	h1,h2,h3{
		font-size: 30px;
		padding-left: 80px;
		padding-right: 80px;
		line-height: 50/30*1;
		margin: 110px 0 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
		&:first-child{
			margin-top: 0;
		}
	}
	>ul li{
		list-style-type: disc;
	}
	p,dd,address,>ul{
		margin: 110px 0;
		position: relative;
		padding-left: 80px;
		padding-right: 80px;
		font-size: 20px;
		line-height: 2;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
		a[href*=".jpg"],
    a[href*=".gif"],
    a[href*=".png"]{
			display: block;
			margin: 100px 0;
      width: calc(99vw - 10px);
      padding-left: 200px;
      padding-right: 200px;
      margin-left: calc(-50vw + 325px);
      @media all and (max-width: 1280px) {
        margin-left: -280px;
      }
      @media all and (min-width: 1960px) {
        width: 1560px;
        padding-left: 0;
        padding-right: 0;
        margin-left: -460px;
      }
		}
		&:last-child{
			margin-bottom: 0;
		}
		&:first-child{
			margin-top: 0;
		}
		&:empty{display: none;}
	}
  a{
    text-decoration: underline;
    &:hover{text-decoration: none;}
  }
	.wp-block-image{
		margin-top: 110px;
	}
	.img_c + p {margin-top: 110px;}
	.row{display: flex;}
	p + p{margin-top: 110px;}
	p ~ p{margin-bottom: 110px;}
	p + h3{margin-top: 102px;}
	h3 + p,h2 + p{margin-top: 20px;}
	h3 + dd,h2 + dd{margin-top: 20px;}
	p + dd{margin-top: 20px;}
	hr{margin: 80px 0}
	.img_l{
		margin-top: 80px;
		margin-bottom: 80px;
		overflow: hidden;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
		.img_r{margin: 0;}
		.gia_ttl{
			margin-top: 0;
		}
		dt{
			width: 360/640*100%;
			float: left;
			padding-right: 40px;
		}
		h3,h2{
			padding: 0;
			clear: initial;
			font-size: 20px;
			margin-top: 0;
		}
		dd{
			width: 280/640*100%;
			float: left;
			font-size: 12px;
			>p,>h6{
				font-size: 12px;
				clear: initial;
			}
		}
		p,dd{padding: 0; margin: 0;}
		p + h3{margin-top: 0;}
		h3 + p{margin-top: 0;}
		h1{margin: 0;}
	}
	.img_c{
		margin: 110px 0;
	}
	.img_r{
		margin: 110px 0;
	}
	.img_l.img_l_wide{
		padding-left: 0;
		padding-right: 0;
		dt{
			width: 480px;
		}
	}
	p:empty + .row{
		margin: 110px 0;
	}
	h3 + .row{
		margin: 20px 0;
	}
	.row + .row{
		margin: 110px 0;
	}
	.row{
		justify-content: space-between;
    //padding-left: 80px;
    //padding-right: 80px;
		.grid4{
			width: calc(33.333333% - 4px);
			p{margin-top: 20px;}
		}

    img{padding: 0;}
		.grid6{
			width: calc(50% - 4px);
      .img_c{margin-bottom: 0;}
			.mt10{
				margin-top: 10px;
				padding: 0;
			}
			.mb10{
				margin-bottom: 10px;
				padding: 0;
			}
		}
	}
  * + .wp-block-lazyblock-article-2col-image{margin-top: 80px;}
  .article-2col-image-text{
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    .article-2col-image-holder{
      width: 360/640*100%;
      padding-right: 40px;
      align-self: flex-start;
    }
    .article-2col-text-holder{
      align-self: center;
      width: 280/640*100%;
      *{
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
      h2,h3,h4{
        font-size: 20px;
        line-height: 1.5;
      }
      p{
        font-size: 12px;
        line-height: 2;
        & ~ p{margin-bottom: 0;}
      }
      a{
        text-decoration: underline;
        &:hover{text-decoration: none;}
      }
    }
  }
}
.wide-slider-def{
	padding-right: 40px;
	.swiper-button-next,
	.swiper-button-prev{
		width: 50px;
		height: 50px;
	}
	.swiper-button-next{
		right: 20px;
	}
	.swiper-button-prev{
		left: 20px;
	}
	.swiper-wrapper{
		padding-left: 40px;
		padding-right: 40px;
	}
}
//バックナンバー
.post-slide-holder{
	.swiper-button-next,
	.swiper-button-prev{top: 75px;}
	.list-item{
		@include mqPcSizeM("width",200);
		margin-right: 20px;
		.item-title{
			line-height: 24/16*1;
		}
	}
}
//関連アイテム
.relation-items-slide-holder{
	.swiper-button-next,
	.swiper-button-prev{top: 175px;}
	.list-item{
		@include mqPcSizeM("width",400);
		margin-right: 20px;
	}
}

.ambassador-info-holder{
	display: flex;
	.thumb-holder{
		width: 160px;
    height: 160px;

	}
	.info-holder{
		width: 440px;
		margin-left: 40px;
		.ambassador-desc{line-height: 20/12*1;}
	}
}
.article-bottom-holder{
	.post-url-holder{
		.btn-url-copy{
		}
	}
  .post-url-holder{
    width: 440px;
    margin: auto;
    .balloon-top{
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
	.tag-item{
		margin-right: 10px;
		margin-bottom: 10px;
		.post-tag{
			line-height: 1;
			padding: 14px 22px;
		}
	}
}

/** ↑↑ マガジン・アンバサダー 記事 ↑↑ **/

/** ↓↓ WP パンくず ↓↓ **/
.section-wp-breadcrumb,
.section-breadcrumb{
	&.cat-parent,
  &.breadcrumb-abs{
		top: 100/1280*100vw;
	}
}
/** ↑↑ WP パンくず ↑↑ **/

/** ↓↓ マガジンTOP ↓↓ **/
.section-ambassador-top{
  .mainVisual-info{
    top: 175/1280*100vw;
  }
}
/** ↑↑ マガジンTOP ↑↑ **/

/** ↓↓ マガジン子カテゴリ ↓↓ **/
.section-wp-category-top{
	.title-holder,.info-holder{
		width: 50%;
	}
}
.more-btn-holder{
	width: 240px;
	margin: 0 auto;
  .section-feature &{width: 400px;}
}
/** ↑↑ マガジン子カテゴリ ↑↑ **/

/** ↑↑ アンバサダーTOP ↑↑ **/
.ambassador-intro-list-holder{
	.ambassador-intro-list {
		margin-right: -40px;
	}
	.ambassador-intro-item{
		width: calc(50% - 40px);
		margin-right:40px;
	}
	@media all and (min-width: 1961px) {
		.ambassador-intro-item{
			width: calc(33.33333333% - 40px);
		}
	}
}
/** ↑↑ アンバサダーTOP ↑↑ **/
/** ↓↓ アンバサダー子カテゴリ ↓↓ **/
/** ↑↑ アンバサダー子カテゴリ ↑↑ **/


/** ↓↓ 商品一覧 ↓↓ **/
.section-product-list{
  .rearrange-layout{
    margin: 0;
    justify-content: space-between;
  }
}
/** ↑↑ 商品一覧 ↑↑ **/

/** ↓↓ TOP NEWS ↓↓ **/
.section-top-news{
	.btn-holder{
		width: 400px;
		margin: auto;
	}
}
.top-news-list{
	margin-right: -40px;
	.list-item{
		&:last-child,
		&:nth-last-child(2){
			.btn{border-bottom: none;}
		}
	}
}
/** ↑↑ TOP NEWS ↑↑ **/

/** ↓↓ NEWS一覧 ↓↓ **/
.news-list-layout{
    .list-item{
    &:last-child{
      .btn{border-bottom: none;}
    }
  }
}
/** ↑↑ NEWS一覧 ↑↑ **/


/** ↓↓ マイページ ↓↓ **/
.member-info{
  display: flex;
  align-items: center;
  font-size: 16px;
  p + p{
    margin-left: 60px;
  }
}
.mypage-list-head{
  display: flex;
  margin: 34px 0 56px;
  .item {
    flex-grow: 1;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background-color: #C8C8C8;
      width: 1px;
      height: 20px;
      margin-top: -10px;
    }
    &:first-child:before{
      content: none;
    }
  }
  a{
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
  }
}
.mypage-list{
  display: flex;
  margin: auto 0;
  .item{
    position: relative;
    + .item:before{
      content: "";
      position: absolute;
      top: 50px;
      bottom: 30px;
      left: 0;
      width: 1px;
      background-color: #DCDCDC;

    }
    margin: 0;
    width: 25%;
    height: 240px;
    a{
      height: 100%;
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .icon{
      height: 76px;
      width: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      svg{width: 100%;}
    }
    .label{
      margin-top: 53px;
    }
  }
}

.order-history-item{
  +.order-history-item{
    margin-top: 40px;
  }
  padding: 40px;
}
.card_address-list{
  .uk-fieldset{
    border-radius: 4px;
    border: #DCDCDC solid 1px;
    padding: 40px;
    margin: 0;
    + .uk-fieldset{
      margin-top: 20px;
    }
  }
  .uk-list{
    li{ display: flex; align-items: center;}
  }
  .uk-legend{
    width: 110px;
    margin-bottom: 0;
  }
  .btn-default{
    width: 160px;
    &.delete{
      margin-left: 20px;
    }
  }
}
/** ↑↑ マイページ ↑↑ **/



/** ↓↓ ログイン ↓↓ **/
.member-merit{
  display: flex;
  justify-content: space-between;
  .item{
    width: 190px;
    line-height: 1.8;
    .icon{
      padding: 15px;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-bottom: 45px;
      svg{width: 100%;}
    }
    .ttl{
      margin: 0 0 15px;
    }
  }
}
/** ↑↑ ログイン ↑↑ **/

/** ↓↓ こだわり検索 ↓↓ **/
.modal-narrow-body{
  height: 100%;
}
.searchModule{
  height: 100%;
}
.search-container{
  height: 100%;
  width: 100%;
  background-color: #fff;
  .search-inner{
    height: 100%;
  }
  label{
    cursor: pointer;
  }
  .icon-absolute-right{
    position: absolute;
    top: 50%;
    right: 20px;
    &.uk-radio{
      right: 12px;
      margin-right: 0;
    }
    transform: translateY(-50%);
  }
  .icon-absolute-left{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
  .uk-button-default{
    min-width: 80px;
    padding: 0 5px;
    font-size: 12px;
    width: 100%;
  }
  .list-holder{
    position: relative;
    background-color: #DCDCDC;
    height: 100%;
    width: 100%;
    .head{
      display: none;
    }

    .ITEM_003-head{
      position: absolute;
      left: 0;
      top: 0;
      width: 240/1120*100%;
      height: 100%;
      background-color: #E6E6E6;
    }
    [data-hierarchy="1"]{
      border-left: #DCDCDC solid 1px;
      border-right: #DCDCDC solid 1px;
      position: absolute;
      background-color: #fff;
      left: 240/1120*100%;
      top: 0;
      width: 525/1120*100%;
      height: 100%;
      overflow-y: auto;
    }
    [data-hierarchy="2"]{
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      width: 355/1120*100%;
    }

    .item{
      position: relative;
      display: flex;
      align-items: center;
      padding: 25px 20px;
      &.active{
        background-color: #969696;
        color: #FFF;
        .item-title{
          color: #FFF;
        }
        .sCategory{
          color: #FFF;
        }
      }
    }
    .item-title{
      width: 130px;
      font-size: 14px;
    }
    .item-body{
      flex-grow: 1;
    }
    .sCategory{
      padding-right: 30px;
    }

  }
  .search-head{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item.ITEM_004{
    align-items: flex-start;
    .item-title{
      margin-top: 10px;
    }
  }

}
/** ↑↑ こだわり検索 ↑↑ **/


// アマゾンペイ
[data-pageID="cart_seisan$amazon_payments"]{
  #memberEditArea {
    width: 900px;
  }
}


//ご利用ガイド
.guide-list-rank-item{
  .guide-rank-table{
    margin-left: -80px;
  }
}


//aboutページ
.section-about-mainvisual{
  min-height: 100vh;
  .about-page-title{
    .ka-about-title{
      width: 522px;
    }
    .title-sub{
      margin-top: 30px;
      font-size: 20px;
    }
  }
  .about-section-inner{
  }
}
.section-about-read{
  .about-section-inner{
    display: block;
    .section-about-read-holder{
      padding: 120px 0 650px;
      //padding-bottom: 50vh;
    }
  }
  .about-read{
    width: 480px;
    & + .about-read{
      padding-top: 60px;
    }
  }
}
.section-about-label-intro{
  .title{
    width: 504px;
  }
  .title-sub{
    margin-top: 30px;
  }
}
.about-label-contents-holder{
  .about-label-contents{
    width: 640px;
    padding-top: 80px;
    padding-left: 80px;
    @media all and (min-width: 1280px) {
      padding-top: 80/1280*100vw;
    }
    .uk-accordion-title{display: none;}
  }
}
.section-about-history{
  background-image: url("../img/history/history-fit-bg.jpg");
}


body[data-pageid="history"]{
  .header{
    position: absolute;
    &.js-headerTopFix{
      background-color: transparent;
    }
  }
}

.section-history-read{
  padding: 160/1280*100vw 0;
  .history-read{
    width: 480/1280*100vw;
    font-size: 20/1280*100vw;
  }
}
.history-number{
  font-size: 60/1280*100vw;
  padding: 335/1280*100vw 0 335/1280*100vw 80/1280*100vw;
}


.history-number-holder{
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 1;
  align-items: center;
  .number-text-white{color: $white2;}
  .number-text-black{color: $black2;}
  .history-number{
    transition: color 0.3s;
  }
  &.bottom-abs{
    position: absolute;
    height: auto;
    bottom: 0; top: auto;
  }
  &.top-abs{
    position: absolute;
    height: auto;
    top: auto;
  }
  // &.bottom-abs{
  //   position: absolute;
  //   height: auto;
  //   bottom: 360/1280*100vw; top: auto;
  // }
  // &.top-abs{
  //   position: absolute;
  //   height: auto;
  //   top: 360/1280*100vw;
  // }
}

.history-number-holder{
}

.hiastory-count-holder{
  .section-bg-height-md{
    height: 720/1280*100vw;
  }
  .section-bg-height-lg{
    height: 720/1280*100vw;
  }
  .pc-right-text-holder{
    width: 240/1280*100vw;
    font-size: 20/1280*100vw;
  }
  .section-1{
    padding: 250/1280*100vw 0;
  }
  .section.bg-color-white{
    padding-left: 220/1280*100vw;
    padding-top: 140/1280*100vw;
    padding-bottom: 140/1280*100vw;
  }
  .text-right-holder{
    width: 816/1280*100vw;
    margin-left: auto;
    margin-right:0;
    font-size: 20/1280*100vw;
  }
  .pc-right-text-holder{
    width: 240/1280*100vw;
    margin-left: auto;
    margin-right:0;
    .animation-text{
      font-size: 20/1280*100vw;
    }
  }
  .text-right-holder{

  }
}

