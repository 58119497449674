@import "./_mixin";
/* item_detail
***********************************/
.valiation{
  //.item-color-pulldown-holder{display: none;}
}
.ITEM_INFO_03_holder{
  a{
    text-decoration: underline;
  }
}
.item-shopping-btn-group{
  button{
    // border:none;
  }
  .valiation-buttons {
    .valiation-function-button{
      display: none;
    }
  }
}
.detail-tab-group{
  li{padding-left: 0;}
}
.item-detail-info-wide{
  .item-spec{
    table{
      th,td{
        padding: 8px 0;
      }
      th{
        width: 100px;
        padding-right: 10px;
        font-weight: normal;
        text-align: left;
      }
    }
  }
  .size-guide-spec-holder{
    th,td{
      @include mqPcPxSpVw("padding-top",8,8);
      @include mqPcPxSpVw("padding-bottom",8,8);
      @include mqPcPxSpVw("font-size",12,20);
    }
    th{font-weight: normal;}
    td{@include mqPcPxSpVw("padding-left",20,45);}
  }
}

.valiation-select-slide{
  .item{
    display: block;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      background-color: #000;
      top: 0;left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      transition: opacity 0.3s;
      z-index: 1;
    }
    &.active:before{
      opacity: 0.7;
    }
    .info{
      position: absolute;
      bottom: 6px;
      left: 10px;
      z-index: 1;
    }
    .allout{
      position: absolute;
      top: 0; left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2;
      &:before{
        background-color: rgba(#FFF,0.8);
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 3px;
        margin-top: -1.5px;
        border-radius: 3px;
        width: 120%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.shop-modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 11;
  background-color: #fff;
  .btn-close{
    width: 70px;
    height: 70px;
    padding: 25px;
    top: 0;right: 0;
    position: fixed;
    z-index: 100;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      @include mqPcPxSpVw("width",20,68);
      @include mqPcPxSpVw("height",20,68);
    }
  }
  .pswp__ui{
    .pswp__button{
      display: flex;
      justify-content: center;
      align-items: center;
      i{
        @include mqPcPxSpVw("width",40,40);
        @include mqPcPxSpVw("height",40,40);
        svg{width: 100%;}
      }
    }
  }
}
.imageViewer{
  .pswp__counter{
    height: 70px;
    font-size: 22px;
    line-height: 70px;
    padding: 0 25px;
  }
  .cropper-view-box{display: none;}
  .clopContainer{
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    img {max-width: 100%;}
  }
  .foot{
    position: fixed;
    background-color: #fff;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cart{margin-right: 24px;}
    .btn-cart{
      .icon{
        @include mqPcPxSpVw("width",25,39);
        @include mqPcPxSpVw("height",25,39);
        svg{width: 100%;}
      }
    }
    .wishlistSubmitButton{
      .icon{
        @include mqPcPxSpVw("width",19,38);
        @include mqPcPxSpVw("height",19,38);
        svg{width: 100%;}
      }
    }
  }
  // モーダル内お気に入りボタン
  .wishlistdeletetag{
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .wishlistinserttag,
  .wishlistdeletetag{
    background: none !important;
  }
  .btn-item-detail-icon{
    .icon{color: #000;}
    &:hover{
      background-color: transparent;
    }
  }
}
.cartViewer{
  overflow-y: auto;
  overflow-scrolling: touch;
  .img-thumb{
    .item-title{
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
    }
  }
  dl{
    width: 100%;
    display: flex;
    .item-label{
    }
    .item-status{
      font-size: 14px;
    }
    .item-button{
      .btn-default{
        width: 140px;
        font-size: 14px !important;
        .btn-inner{
          min-height: 40px;
        }
      }
    }

  }
}
.cartViewerItemHolder{
  .item-button{
    .btn-default{
      @include mqPcPxSpVw("padding-top",5,5);
      @include mqPcPxSpVw("padding-bottom",5,5);
    }
    .txt-henpin{
      display: block;
      @include mqPcPxSpVw("font-size",12,22);
      line-height: 1.2;
    }
  }
}

.item-details-tab-content{
	&.uk-active{
    animation: fadeIn 0.6s;
  }
}

.balloon-top {
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 64px;
  padding: 7px 10px;
  color: #000;
  font-size: 12px;
  background: #CCC;
  height: 42px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:before{
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #CCC transparent;
  }
}
@keyframes popDownShow {
  0% {
    //margin-top: -20px;
    visibility: visible;
    pointer-events:none;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes popDownHide {
  0% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.copy-done{
  display: none;
  &.popDownShow{
    display: flex;
    animation: popDownShow 0.3s $easeOutQuart both;
  }
  &.popDownHide{
    animation: popDownHide 0.6s both;
  }
}
