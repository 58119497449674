@import "./_mixin";
/* item_detail PC
***********************************/
.balloon-top{
  top: 40px;
  left: -40px;
}

.item-shopping-holder{
  .item-image-holder.grid{
    margin-top: -10px;
    overflow: hidden;
    .swiper-wrapper{
      flex-wrap: wrap;
      width: calc(100% + 10px);
      margin-right: -10px;
    }
    .image-item{
      margin-top: 10px;
      height: auto;
      width: calc(50% - 10px);
      margin-right:10px;
      position: relative;
      &:nth-child(odd){
        &:after{
          content: "";
          position: absolute;
          left: 11px; top: 0;
          width: calc(100% - 1px); height: calc(100% - 1px);
          background-color: $lightGray;
          transform: translateX(100%);
        }
      }
    }
  }
}

.teika-for-sale{
  font-size: 10px;
  color: #969696;
  text-decoration: line-through;
}
.price-sale,.price-sale .sub,.price-sale span{
  font-size: 15px;
  color: #DA3636;
  .price-off{display: inline-block;}
}
.item-shopping-info{
  .teika-for-sale{
    font-size: 10px;
  }
  .price-sale,.price-sale .sub{
    font-size: 15px;
  }
  .valiation-select-slide{
    padding: 2.5px;
    .list-item{
      padding: 2.5px !important;
    }
  }
}

.shop-modal{
  .btn-close{
    top: 0;
    right: 0;
  }
}

.cartViewer{
  .cartViewerItem{
    padding-bottom: 5px;
    border-bottom: #DCDCDC solid 1px;
    margin-bottom: 25px;
    .img-thumb{
      width: 75px;
      .item-title{
        font-size: 12px;
      }
    }
    .info{
      margin-left: 25px;
      width: calc(100% - 100px);
      font-size: 13px !important;
      .item-button{
        .btn-default{
          width: 250px * 0.5;
          .btn-inner{
            padding: 5px;
            line-height: 1.3;
            min-height: 70px * 0.5;
          }
        }
      }
    }
  }
}

.section-item-info{
  .sticky{
    z-index: 2;
    position: absolute;
    left: 15px;
    bottom: 15px;
    .btn-mode{
      text-align: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 0;
      background-color: #fff;
      box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      line-height: 0;
      i{
        width: 20px;
      }
    }
  }
}
