@import "./_mixin";
/* parts PC
***********************************/
.container-pc-size-m{
	margin: auto;
	//max-width: 1960px;
	padding: 0 40px;
}

.container-pc-size-sm{
  margin: auto;
  max-width: 1960px;
  padding: 0 200px;
}
.container-pc-size-s{
	margin: auto;
	max-width: 1280px;
	padding: 0 40px;
}

.btn-wishlist .icon-heart{
  width: 20px;
  height: 20px;
}

.btn-hover-bg-white{
  transition: background 0.3s;
  &:hover{
    background-color: rgba(255,255,255,0.3);
  }
}

//セクションタイトル
.section-title-holder{
	.title-info{width: 50%;}
}


//商品タイプ選択　レイアウト
.list-layout-select-items{
	margin-right: -40px;
	.list-item{
		margin-right: 40px;
		width: calc(33.3333333333% - 40px);
	}
	&.list-layout-select-2column{
		.list-item{
			width: calc(50% - 40px);
		}
	}
}
//画面いっぱいのスライダー
.list-layout-row-slider{
	margin-left: 0;
	@media all and (min-width: 1961px) {
		//margin-left: auto;
		//padding-left: 0;
	}
	.swiper-button-next,
	.swiper-button-prev{
		width: 50px; height: 50px;
		top: 117px;
	}
	.swiper-button-next{right: 20px;}
	.swiper-button-prev{left: 20px;}
	.list-item{
		@include mqPcSizeM("width",280);
	}
	&.list-layout-item-sizeS{
		.swiper-button-next,
		.swiper-button-prev{
			top: 85px;
		}
		.list-item{
			@include mqPcSizeM("width",220);
		}
	}
	&.list-layout-item-sizeSS{
		.swiper-button-next,
		.swiper-button-prev{
			top: 60px;
		}
		.list-item{
			@include mqPcSizeM("width",170);
		}
	}
	&.list-layout-item-sizeXS{
		.swiper-button-next,
		.swiper-button-prev{
			width: 30px; height: 30px;
			top: 25px;
			.icon-arrow{
				width: 5px;
				height: 9px;
			}
		}
		.swiper-button-next{right: 2px;}
		.swiper-button-prev{left: 2px;}
		.list-item{
			width: 80px;
		}
	}
	&.list-layout-item-sizeAuto{
		.swiper-button-next,
		.swiper-button-prev{
			width: 30px; height: 30px;
			top: 5px;
			.icon-arrow{
				width: 5px;
				height: 9px;
			}
		}
		.swiper-button-next{right: 2px;}
		.swiper-button-prev{left: 2px;}
		.list-item{
			width: auto;
		}
	}
}


//top style レイアウト スライダー
.list-layout-styles-slider{
	margin-left: 0;
  .slide-nav-holder{
    width: 100%;
  }
  .slide-nav-prev-holder,
  .slide-nav-next-holder{
    width: 50px;
    position: absolute;
    height: 100vh;
    top: 0;
    z-index: 2;
  }
  .slide-nav-prev-holder{left: 20px;}
  .slide-nav-next-holder{right: 20px;}
  &.fixPos{
    .slide-nav-prev-holder,
    .slide-nav-next-holder{
      position: fixed;
    }
  }
  &.absBottom{
    .slide-nav-prev-holder,
    .slide-nav-next-holder{
      top: auto;
      position: absolute;
      bottom: 0;
    }

  }
  .swiper-button-next,
  .swiper-button-prev{
    width: 50px; height: 50px;
    top: 50%;
    margin-top: -25px;
    z-index: 1;
    &.swiper-button-disabled{
      opacity: 0;
    }
  }
  .swiper-button-next{right: 0;}
  .swiper-button-prev{left: 0;}
	.list-item{
		@include mqPcSizeM("width",600);
	}

  .list-item{
  	.list-alternate-item{
  		width: calc(50% - 40px);
  		&:first-child{width: 100%;}
  	}
  	&:nth-child(2n){
  		.list-alternate-item:first-child{
  			width: calc(50% - 40px);
  		}
  		.list-alternate-item:nth-child(5n){
  			width: 100%;
  		}
  	}
  }
}

//レーベルレイアウト
.list-layout-pc-half{
	margin-top: -80px;
	margin-right: -40px;
	.list-item{
		margin-top: 80px;
		margin-right: 40px;
		width: calc(50% - 40px);
	}
	&.list-layout-pc-half-marginS{
		margin-top: -30px;
		.list-item{margin-top: 30px;}
	}
	&.list-layout-pc-half-mq1280{
		@media all and (min-width: 1280px) {
			.list-item{
				width: calc(33.333333333333% - 40px);
			}
		}
	}
}

//商品詳細
.item-shopping-holder{
	.item-image-holder{
		margin-top: -10px;
		.image-item{
			width: 100%;
			margin-top: 10px;
			height: auto;
		}
    &.grid{
      margin-right: -10px;
      .image-item{
        width: calc(50% - 10px);
        margin-right:10px;
        position: relative;
        &:nth-child(odd){
          &:after{
            content: "";
            position: absolute;
            left: 11px; top: 0;
            width: calc(100% - 1px); height: calc(100% - 1px);
            background-color: $lightGray;
            transform: translateX(100%);
          }
        }
      }
    }
	}
	@media all and (min-width: 1281px) {
    &:not(.single){
      .item-image-holder{
        margin-right: -10px;
        .image-item{
          width: calc(50% - 10px);
          margin-right:10px;
          position: relative;
          &:nth-child(odd){
            &:after{
              content: "";
              position: absolute;
              left: 11px; top: 0;
              width: calc(100% - 1px); height: calc(100% - 1px);
              background-color: $lightGray;
              transform: translateX(100%);
            }
          }
        }
      }
    }
    .btn-mode{
      display: none;
    }
	}
	.item-shopping-info{
		@include mqPcSizeM("width",440);
		padding-left: 40px;
	}
}
.item-details-holder{
	.item-details-contact-holder{
		width: 320px;
		margin: auto;
	}
}

// 表示順選択,こだわり検索
.filter-block{
  width: 380px;
  .rearrange-block select.select-el,
  .rearrange-block .select-el-parent{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
//スタイルズ詳細
.list-layout-styles-items{
  .list-item-module{
    border-bottom: solid 1px $lightGray2;
    .img-link-holder{
      width: 120/400*100%;
    }

    .img-holder{

    }
  }
}

// カテゴリ一覧 アクティビティから探す
.section-activity{
  .list-layout-select-items{
    margin: 0 -2px;
  }
  .list-item{
    margin: 2px;
    width: calc(16.666666% - 4px);
  }
}

// カテゴリ一覧 容量から探す
.section-capacity{
  .list-item-module ul{
    margin-right: -4px;
  }
  .list-item{
    margin-right: 4px;
    @include mqPcSizeM("width",170);
  }
}

// お気に入り一覧
.section-wishlist{
}


//wp カテゴリTOP
.list-layout-pc-4col{
  margin-top: -40px;
  margin-right: -40px;
  .list-item-module,.list-item{
    margin-top: 40px;
    width: calc(25% - 40px);
  }
}
.list-layout-first-large{
  .list-item-module,.list-item{
    &:first-child{
      .img-holder{
        padding-top: 600/1200*100%;
      }
    }
  }
}

// レーベル詳細 パララックスエリア
.item-parallax-holder{
  .bg-parallax{
    height: 640px;
  }
  &.ultimate{
    .n1{background-image: url("../img/label/ultimate/bg-ultimate-01.jpg");}
    .n2{background-image: url("../img/label/ultimate/bg-ultimate-02.jpg");}
  }
  &.explorer{
    .n1{background-image: url("../img/label/explorer/bg-explorer-01.jpg");}
    .n2{background-image: url("../img/label/explorer/bg-explorer-02.jpg");}
    .n3{background-image: url("../img/label/explorer/bg-explorer-03.jpg");}
  }
  &.life{
    .n1{background-image: url("../img/label/life/bg-life-01.jpg");}
    .n2{background-image: url("../img/label/life/bg-life-02.jpg");}
    .n3{background-image: url("../img/label/life/bg-life-03.jpg");}
  }
  &.aspire{
    .n1{background-image: url("../img/label/aspire/bg-aspire-01.jpg");}
    .n2{background-image: url("../img/label/aspire/bg-aspire-02.jpg");}
  }
  .btn-parallax{
    .btn-default{
      width: 240px;
      margin: auto;
    }
  }
}

// ギフトラッピング アコーディオン
.list-accordion.uk-accordion{
  >li{
    margin-top: 0;
  }
}

.uk-accordion-title{
  padding-top: 30px;
  padding-bottom: 30px;
}


.uk-form-controls{

  // 住所検索 ボタン幅 & お問い合わせする項目
  .address-search-width{
    width: 37%;
  }

  // 　オンラインストア位置調整
  .online-service{
    display: inline-block;
    margin-left: -4px;
  }

  // クレジットカード番号（ハイフン）
  .credit-hyphen{
    margin: auto 3px;
    color: #DCDCDC;
  }

  .security-code-width{
    width: 80px;
  }

  // クレジットカード有効期限：月幅
  .cregit-month-width{
    width: 80px;
    margin-right: 22px;
  }

  // クレジットカード有効期限：年幅
  .cregit-year-width{
     width: 184px;
  }
}
