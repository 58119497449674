@import "./_mixin";
*,*:after,*:before{box-sizing: border-box;}
body{
  font-family: $fontFamilySet2;
}

html,body{
  height: 100%;
  width: 100%;
  color: #323232;
}
img.width100{width: 100%;}




/* size
xxxs:5
xxs :10
xs  :15
sm  :20
md  :30
lg  :40
ll  :50
xl  :60
xxl :80
xxxl:100
2xl :200
3xl :300

***********************************/
$spacer:"xxxs","xxs","xs","sm","md","lg","ll","xl","xxl","xxxl","2xl","3xl";
$spaceSize:5,10,15,20,30,40,50,60,80,100,200,300;
@for $i from 1 through length($spacer) {
  .spacer-#{nth($spacer,$i)}{
    width: 100%;
    height: nth($spaceSize,$i)+px;
  }
  .spacer-pcsp-#{nth($spacer,$i)}{
    width: 100%;
    @media all and (min-width: 960px) {
      height: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      height:nth($spaceSize,$i)/ 2 + px;
    }
  }
  @media all and (min-width: 960px) {
    .spacer-pc-#{nth($spacer,$i)}{
      width: 100%;
      height:nth($spaceSize,$i)+px;
    }
  }
  @media screen and (max-width: 959px) {
    .spacer-sp-#{nth($spacer,$i)}{
      width: 100%;
      height:nth($spaceSize,$i)/ 2 + px;
    }
  }


  //パディング
  .padding-#{nth($spacer,$i)}{
    padding: nth($spaceSize,$i)+px nth($spaceSize,$i)+px nth($spaceSize,$i)+px nth($spaceSize,$i)+px;
  }
  .padding-pcsp-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding: nth($spaceSize,$i)+px nth($spaceSize,$i)+px nth($spaceSize,$i)+px nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      padding: nth($spaceSize,$i)/ 2 + px nth($spaceSize,$i)/ 2 + px nth($spaceSize,$i)/ 2 + px nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-pcsp-left-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-left: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      padding-left: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-pcsp-top-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-top: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      padding-top: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-pcsp-right-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-right: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      padding-right: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-pcsp-bottom-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-bottom: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      padding-bottom: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-pc-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {padding: nth($spaceSize,$i)+px;}
  }
  .padding-pc-left-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {padding-left: nth($spaceSize,$i)+px;}
  }
  .padding-pc-top-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {padding-top: nth($spaceSize,$i)+px;}
  }
  .padding-pc-right-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {padding-right: nth($spaceSize,$i)+px;}
  }
  .padding-pc-bottom-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {padding-bottom: nth($spaceSize,$i)+px;}
  }
  .padding-pc-highLow-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-top: nth($spaceSize,$i)+px;
      padding-bottom: nth($spaceSize,$i)+px;
    }
  }
  .padding-pc-side-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      padding-left: nth($spaceSize,$i)+px;
      padding-right: nth($spaceSize,$i)+px;
    }
  }
  .padding-sp-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {padding: nth($spaceSize,$i)/ 2 + px;}
  }
  .padding-sp-left-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {padding-left: nth($spaceSize,$i)/ 2 + px;}
  }
  .padding-sp-top-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {padding-top: nth($spaceSize,$i)/ 2 + px;}
  }
  .padding-sp-right-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {padding-right: nth($spaceSize,$i)/ 2 + px;}
  }
  .padding-sp-bottom-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {padding-bottom: nth($spaceSize,$i)/ 2 + px;}
  }
  .padding-sp-highLow-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {
      padding-top: nth($spaceSize,$i)/ 2 + px;
      padding-bottom: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .padding-sp-side-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {
      padding-left: nth($spaceSize,$i)/ 2 + px;
      padding-right: nth($spaceSize,$i)/ 2 + px;
    }
  }
  //マージンTOP
  .margin-pcsp-top-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      margin-top: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      margin-top: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .margin-pcsp-right-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      margin-right: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      margin-right: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .margin-pcsp-left-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      margin-left: nth($spaceSize,$i)+px;
    }
    @media screen and (max-width: 959px) {
      margin-left: nth($spaceSize,$i)/ 2 + px;
    }
  }
  .margin-pc-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {margin: nth($spaceSize,$i)+px;}
  }
  .margin-pc-right-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {margin-right: nth($spaceSize,$i)+px;}
  }
  .margin-pc-left-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {margin-left: nth($spaceSize,$i)+px;}
  }
  .margin-pc-top-#{nth($spacer,$i)}{
    @media all and (min-width: 960px) {
      margin-top: nth($spaceSize,$i)+px;
    }
  }
  .margin-sp-right-#{nth($spacer,$i)}{
    @media all and (max-width: 959px) {margin-right: nth($spaceSize,$i)/ 2 + px;}
  }
  .margin-sp-left-#{nth($spacer,$i)}{
    @media all and (max-width: 959px) {margin-left: nth($spaceSize,$i)/ 2 + px;}
  }
  .margin-sp-#{nth($spacer,$i)}{
    @media screen and (max-width: 959px) {margin: nth($spaceSize,$i)/ 2 + px;}
  }
  .margin-sp-top-#{nth($spacer,$i)}{
    @media all and (max-width: 959px) {
      margin-top: nth($spaceSize,$i)/ 2 + px;
    }
  }
}

@for $i from 0 through 20 {
  .line-height-#{$i}{line-height: 1 + 0.1*$i;}
  @media all and (min-width: 960px) {.pc-line-height-#{$i}{line-height: 1 + 0.1*$i;}}
  @media screen and (max-width: 959px) {.sp-line-height-#{$i}{line-height: 1 + 0.1*$i;}}
}

@for $i from 10 through 100 {
  @media all and (min-width: 960px) {
    .pc-text-size-#{$i}{font-size:#{$i}px;}
    .text-size-#{$i} {
      font-size:#{$i}px;
    }
  }
  @media screen and (max-width: 959px) {
    .sp-text-size-#{$i}{ font-size: #{$i/2}px; }
    .text-size-#{$i} {
      font-size:#{$i/2}px;
    }
  }
}

@for $i from 0 through 20 {
  .text-indent-#{$i}{
    padding-left: 0.1*$i+em;
    text-indent: -0.1*$i+em;
  }
}
