@import "./_mixin";
/* item_detail PC
***********************************/

.item-shopping-holder{
  .detail-image{
    position: relative;
    display: block;
    padding-top: 1014 / 760 * 100%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}


.valiation-select-slide.wide-slider {
  .swiper-button-prev,
  .swiper-button-next{
    top: 35px;
  }
  .list-item{
    width: 100px;
  }
}


.teika-for-sale{
  font-size: 12px;
  color: #969696;
  text-decoration: line-through;
}
.price-sale,.price-sale .sub,.price-sale span{
  font-size: 16px;
  color: #DA3636;
  .price-off{display: inline-block;}
}
.item-shopping-info{
  z-index: 2;
  position: sticky;
  top: 120px;
  .teika-for-sale{
    font-size: 12px;
  }
  .price-sale,.price-sale .sub{
    font-size: 20px;
  }
}


.cartViewer{
  h2{
    text-align: center;
  }
  .img-thumb{
    width: 120px;
      flex-shrink: 0;
      flex-grow: 0;
    .item-title{
      font-size: 14px;
    }
  }
  .info{
    margin-left: 25px;
    width: calc(100% - 145px);
  }
  .cartViewerItemHolder{
    margin: 0 -40px;
    .cartViewerItem{
      padding: 40px;
    }
  }
}
.section-item-info{
  .sticky{
    z-index: 2;
    position: sticky;
    height: 100vh;
    float: left;
    top: 0;
    .btn-mode{
      position: absolute;
      top: 50%;
      left: 40px;
      margin-top: -30px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      padding: 0;
      background-color: #fff;
      box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      i{
        width: 20px;
      }
    }
  }
}
