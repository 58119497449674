@import "./_mixin";
/* parts PC SP 共通
***********************************/
.contents section{
}
.relative{
  position: relative;
}
//サイト共通lazyload
.img-holder{background-color: $lightGray;}
.img-lazy-mode,.vue-img-lazy{
  transition: opacity 1.2s $easeOutQuart;
  //transition-delay: 0.35s;
  opacity: 0;
  &.lazyloaded{opacity: 1;}
  &[lazy="loaded"]{opacity: 1;}
}

//container size
.container-pcsp-size-m{
  @media all and (min-width: 960px) {
    margin: auto;
    //max-width: 1960px;
    padding: 0 40px;
  }
  @media screen and (max-width: 959px) {
    padding: 0 50/2+px;
  }
}
.container-pcsp-size-s{
  @media all and (min-width: 960px) {
    margin: auto;
    max-width: 1960px;
    padding: 0 200px;
  }
  @media screen and (max-width: 959px) {
    padding: 0 50/2+px;
  }
}

//セクションタイトル
.section-title-holder{}
picture.assetsPath{
  // display: inline-block;
  // background-color: #F0F0F0;
}
.AmazonWidgetsArea{
  iframe{z-index: 1 !important;}
}
.sub{
  color: #969696;
}




//背景色・テキスト色
.bg-color-transparent{background-color: transparent;}
.bg-color-white{background-color: $white;}
.text-color-white{color: $white;}
.bg-color-white2{background-color: $white2;}
.text-color-white2{color: $white2;}
.bg-color-black{background-color: $black;}
.text-color-black{color: $black;}
.bg-color-black2{background-color: $black2;}
.text-color-black2{color: $black2;}
.bg-color-gray{background-color: $gray;}
.text-color-gray{color: $gray;}
.bg-color-lightGray{background-color: $lightGray;}
.text-color-lightGray{color: $lightGray;}
.bg-color-lightGray2{background-color: $lightGray2;}
.text-color-lightGray2{color: $lightGray2;}
.bg-color-lightGray3{background-color: $lightGray3;}
.text-color-lightGray3{color: $lightGray3;}
.bg-color-red{background-color: $red;}
.text-color-red{
  color: $red;
  &:hover{color: $red;}
}
.bg-color-yellow{background-color: $yellow;}
.text-color-yellow{color: $yellow;}
.text-center{text-align: center;}
//ボーダー
//ボーダー白
.bd-0{border: none;}
.bd-1-color-white{border: solid 1px $white;}
.bdt-1-color-white{border-top: solid 1px $white;}
.bdr-1-color-white{border-right: solid 1px $white;}
.bdb-1-color-white{border-bottom: solid 1px $white;}
.bdl-1-color-white{border-left: solid 1px $white;}
//ボーダー黒
.bd-1-color-black{border: solid 1px $black;}
.bdt-1-color-black{border-top: solid 1px $black;}
.bdr-1-color-black{border-right: solid 1px $black;}
.bdb-1-color-black{border-bottom: solid 1px $black;}
.bdl-1-color-black{border-left: solid 1px $black;}
//ボーダー灰色
.bd-1-color-gray{border: solid 1px $gray;}
.bdt-1-color-gray{border-top: solid 1px $gray;}
.bdr-1-color-gray{border-right: solid 1px $gray;}
.bdb-1-color-gray{border-bottom: solid 1px $gray;}
.bdl-1-color-gray{border-left: solid 1px $gray;}
//ボーダー薄灰色
.bd-1-color-lightGray{border: solid 1px $lightGray;}
.bdt-1-color-lightGray{border-top: solid 1px $lightGray;}
.bdr-1-color-lightGray{border-right: solid 1px $lightGray;}
.bdb-1-color-lightGray{border-bottom: solid 1px $lightGray;}
.bdl1-color-lightGray{border-left: solid 1px $lightGray;}
//ボーダー薄灰色2
.bd-1-color-lightGray2{border: solid 1px $lightGray2;}
.bdt-1-color-lightGray2{border-top: solid 1px $lightGray2;}
.bdr-1-color-lightGray2{border-right: solid 1px $lightGray2;}
.bdb-1-color-lightGray2{border-bottom: solid 1px $lightGray2;}
.bdl1-color-lightGray2{border-left: solid 1px $lightGray2;}
//ボーダー薄灰色3
.bd-1-color-lightGray3{border: solid 1px $lightGray3;}
.bdt-1-color-lightGray3{border-top: solid 1px $lightGray3;}
.bdr-1-color-lightGray3{border-right: solid 1px $lightGray3;}
.bdb-1-color-lightGray3{border-bottom: solid 1px $lightGray3;}
.bdl1-color-lightGray3{border-left: solid 1px $lightGray3;}
//ボーダー赤
.bd-1-color-red{border: solid 1px $red;}
.bdt-1-color-red{border-top: solid 1px $red;}
.bdr-1-color-red{border-right: solid 1px $red;}
.bdb-1-color-red{border-bottom: solid 1px $red;}
.bdl1-color-red{border-left: solid 1px $red;}
//ボーダー黄色
.bd-1-color-yellow{border: solid 1px $yellow;}
.bdt-1-color-yellow{border-top: solid 1px $yellow;}
.bdr-1-color-yellow{border-right: solid 1px $yellow;}
.bdb-1-color-yellow{border-bottom: solid 1px $yellow;}
.bdl1-color-yellow{border-left: solid 1px $yellow;}
//ボーダー角丸
.bd-rd-1{border-radius: 1px; overflow-y: hidden;}
.bd-rd-2{border-radius: 2px; overflow-y: hidden;}
.bd-rd-3{border-radius: 3px; overflow-y: hidden;}
.bd-rd-4{border-radius: 4px; overflow-y: hidden;}
.bd-rd-5{border-radius: 5px; overflow-y: hidden;}
.bd-rd-6{border-radius: 6px; overflow-y: hidden;}

a.link-text-color-white:link,
a.link-text-color-white:visited{color: $white;}
a.link-text-color-white2:link,
a.link-text-color-white2:visited{color: $white2;}
a.link-text-color-black:link,
a.link-text-color-black:visited{color: $black;}
a.link-text-color-black2:link,
a.link-text-color-black2:visited{color: $black2;}
a.link-text-color-gray:link,
a.link-text-color-gray:visited{color: $gray;}
a.link-text-color-lightGray:link,
a.link-text-color-lightGray:visited{color: $lightGray;}
a.link-text-color-lightGray:link,
a.link-text-color-lightGray:visited{color: $lightGray;}


input[style="background-color: rgb(255, 192, 203);"],
select[style="background-color: rgb(255, 192, 203);"],
textarea[style="background-color: rgb(255, 192, 203);"],
input[type="tel"][style=" background-color:#FFC0CB;"],
input[type="radio"][style=" background-color:#FFC0CB;"],
input[type="checkbox"][style=" background-color:#FFC0CB;"],
input[type="email"][style=" background-color:#FFC0CB;"],
input[type="text"][style=" background-color:#FFC0CB;"],
input[type="text"][style=" background-color:#FFC0CB; ime-mode:active;"],
input[type="text"][style=" background-color:#FFC0CB; ime-mode:inactive;"],
input[type="text"][style=" background-color:#FFC0CB; ime-mode:disabled;"],
input[type="text"].error,
select[style=" background-color:#FFC0CB;"],
textarea[style=" background-color:#FFC0CB;"],
textarea[style=" background-color:#FFC0CB; ime-mode:active;"]
{
  background-color: #DFC5CC !important;
  border: 1px solid #963E56 !important;
  color: #963E56 !important;
}


[data-pageID="wishlist"]{
  .messages--error{
    display: none;
  }
}
[data-pageID="password_reminder_result"]{
  .messages--default{
    display: none;

  }
}







//アイコン
.icon{
	display: inline-flex;
  font-size: 0; line-height: 1;
	align-items: center;
  justify-content: center;
}
.icon-white{
  svg{fill: $white;}
}
.icon-arrow-left,
.icon-arrow-right{
	@include mqPxVw("width",15);
	@include mqPxVw("height",15);
}
.icon-bag{
  @include mqPcPxSpVw("width",70,140);
}
//影
.box-shadow-def{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
//ボタン
.btn-default{
	width: 100%;
	display: flex;
	padding: 0;
	text-align: center;
	outline: none;
	.btn-inner{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:hover{
		text-decoration: none;
	}
  &.bg-color-black2{
    border: none;
  }
}
.btn-hover-cover{
	.btn-inner{
		position: relative;
		&:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $white;
			visibility: hidden;
			opacity: 0;
			transition: 0.3s $easeOutSine;
		}
	}
	&:hover .btn-inner,
	&:active .btn-inner{
		&:after{
			visibility: visible;
			opacity: 0.6;
		}
	}
}
.btn-hover-black{
  display: flex !important;
  justify-content: center;
  align-items: center;
  &:after{
    content: "";
    display: block;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0; left: 0;
    transition: background-color 0.3s;
  }
  &:hover{
    &:after{background-color: rgba(0,0,0,0.8);}
  }
}
.btn-more-read{
  background: none;
  border: 0;
  padding: 0;
  text-decoration: underline !important;
  &:hover{text-decoration: none !important;}
}
.btn-more-label{
  margin: auto;
  @media all and (min-width: 960px) {
    min-width: 280px;
    max-width: 400px;
  }
  @media screen and (max-width: 959px) {
    width: 650/750*100%;
    min-height: 120/2+px;
  }
}
.btn-text-underLine{
  text-decoration: underline;
  @media all and (min-width: 960px) {
    &:hover{
      text-decoration: none;
    }
  }
}

.btn-hover-child-transparent{
  cursor: pointer;
  .icon-play{transition: opacity 0.4s;}
  &:hover{
    .icon-play{opacity: 0.6;}
  }
}

//ボタン高さ
.btn-height-xs .btn-inner{
	@media all and (min-width: 960px) {min-height: 30px;}
	@media screen and (max-width: 959px) {min-height: 50/2+px;}
}
.btn-height-sm .btn-inner{
	@media all and (min-width: 960px) {min-height: 40px;}
	@media screen and (max-width: 959px) {min-height: 80/2+px;}
}
.btn-height-sm2 .btn-inner{
	@media all and (min-width: 960px) {min-height: 50px;}
	@media screen and (max-width: 959px) {min-height: 80/2+px;}
}
.btn-height-md .btn-inner{
  @media all and (min-width: 960px) {min-height: 60px;}
  @media screen and (max-width: 959px) {min-height: 100/2+px;}
}
.btn-height-md2 .btn-inner{
  @media all and (min-width: 960px) {min-height: 50px;}
  @media screen and (max-width: 959px) {min-height: 100/2+px;}
}
.btn-height-md3 .btn-inner{
  @media all and (min-width: 960px) {min-height: 60px;}
  @media screen and (max-width: 959px) {min-height: 120/2+px;}
}
.btn-height-lg .btn-inner{
	@media all and (min-width: 960px) {min-height: 80px;}
	@media screen and (max-width: 959px) {min-height: 160/2+px;}
}



//タグ
.tag-list-layout{
	.tag-default{
		@include mqPcPxSpVw("margin-right",4,10);
		@include mqPcPxSpVw("margin-bottom",4,10);
	}
  .section-label-list &,
  .section-search &,.list-item-info &{font-size: 0;}
  .section-search.life &{
    .btn:last-of-type{
      display: none;
    }
  }
  .section-search.aspire &{
    .btn{
      display: none;
      &:last-of-type{display: inline-block;}
    }
  }
}
.tag-default{
	display: inline-flex;
	padding: 0;
	text-align: center;
	outline: none;
	.tag-inner{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
  &.btn{
    text-decoration: none;
    &:hover{color: #333;}
  }
}
.tag-height-xs{
  @media all and (min-width: 960px) {border-radius: 2px;}
  @media screen and (max-width: 959px) {border-radius: 3px;}
}
.tag-height-xs .tag-inner{
  @media all and (min-width: 960px) {min-height: 30px;}
  @media screen and (max-width: 959px) {min-height: 50/2+px;}
}
.tag-height-sm{
  @media all and (min-width: 960px) {border-radius: 2px;}
  @media screen and (max-width: 959px) {border-radius: 3px;}
}
.tag-height-sm .tag-inner{
  @media all and (min-width: 960px) {min-height: 30px;}
  @media screen and (max-width: 959px) {min-height: 70/2+px;}
}
.tag-height-sm2 .tag-inner{
  @media all and (min-width: 960px) {min-height: 40px;}
  @media screen and (max-width: 959px) {min-height: 70/2+px;}
}
.tag-height-sm3 .tag-inner{
  @media all and (min-width: 960px) {min-height: 40px;}
  @media screen and (max-width: 959px) {min-height: 60/2+px;}
}
.tag-height-md .tag-inner{
	@media all and (min-width: 960px) {min-height: 40px;}
	@media screen and (max-width: 959px) {min-height: 90/2+px;}
}
.tag-height-lg .tag-inner{
	@media all and (min-width: 960px) {min-height: 50px;}
	@media screen and (max-width: 959px) {min-height: 100/2+px;}
}

.modal.modal-vimeo{
  .setVideoSize.video-holder{
    &.pause{
      .vmPlayer{
        opacity: 1;
      }
    }
    &.playing{
      .icon-play{
        opacity: 0;

      }
    }
    .vmPlayer{
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
    }
    .icon-play{
      pointer-events: none;
      transition: opacity 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      z-index: 100;
    }
  }
  .modal-inner{
    background-color: #000000;
  }
}

/* VIDEO
***************************/
.video-holder{
  position: relative;
  overflow: hidden;
  .poster{
    img{width: 100%;}
    // .icon-play{
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 100px;
    //   height: 100px;
    // }
  }
  .uk-spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    color: #FFF;
  }
  .uk-background-black{
    background-color: #000 !important;
  }
  .vmPlayer{
    transition: opacity 0.3s;
    position: absolute;
    top: -1px;left: -1px;
    width: calc(100% + 2px);height: calc(100% + 2px);
    iframe{
      width: 100%;height: 100%;
    }
    .main-slideshow & {
      overflow: hidden;
      pointer-events: none;
    }
  }
}
.holder-btn-play{
  position: relative;
  .btn-modal{
    color:white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }
}
.poster{
  .icon-play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }
}




/** ↓↓ モジュール ↓↓ **/
//MV
.main-slideshow,
.mainVisual-slider{
  position: relative;
  .uk-slideshow-items{
    overflow: hidden;
    padding-top: 50%;
  }
	.item-info{
		width: 100%;
		@include mqPcPxSpVw("bottom",80,100);
	}
	.item-title{
		line-height: 1;
	}
	.item-read{
		line-height: 40/24*1;
	}
	.btn-holder{
		@include mqPcPxSpVw("width",160,250);
	}
  .main-item{
    position: relative;
    overflow: hidden;
  }

  .uk-slideshow-nav,
	.swiper-pagination{
    position: absolute;
    margin: 0;
		width: 100%;
		font-size: 0;

		@include mqPcPxSpVw("padding-left",40,20);
		@include mqPcPxSpVw("bottom",20,20);

    >li,
		.swiper-pagination-bullet{
			background: none;
			border-radius: 0;
			flex: 1;
			outline: none;
			margin-left: 0;
			opacity: 1;
			position: relative;
			@include mqPcPxSpVw("margin-right",40,20);
			@include mqPcPxSpVw("height",40,40);
      >a{
        width: 100%;
        border-radius: 0;
        border:none;
        height: 100%;
        background-color: transparent !important;
        position: relative;
        &:before,.line{
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          top: 50%; left: 0;
          background-color: rgba(255,255,255,0.5);
          @include mqPcPxSpVw("height",1 ,4);
        }
        .line{
          background-color: rgba(255,255,255,1);
          width: 0;
          &.end{
            left:auto;
            right: 0;
            animation: percentWidthDown 0.5s ease-out both;
          }
        }
      }

      &.uk-active:not(.loading){
        .line{
          animation: percentWidthUp 6s 0.5s linear;
        }
      }

			.bullet-line{
				content: "";
				display: block;
				position: absolute;
				left: 0; top: 50%;
				@include mqPcPxSpVw("height",1 ,4);
			}
			.bullet-line-def{
				width: 100%;
				background-color: rgba(255,255,255,0.5);
			}
			.bullet-line-cover{
				background-color: rgba(255,255,255,1);
			}
		}
	}
}
//検索ボックス
.item-search-holder{
  .item-search-form{
    @include mqPcPxSpVw("padding-left",55,48);
    @include mqPcPxSpVw("padding-top",12,18);
    @include mqPcPxSpVw("padding-bottom",12,18);
  }
  input.item-search-text-input.uk-form-large{
    padding-left: 0;
  }
	input.item-search-text-input{
		box-shadow: none;
		border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0 ;
    @include mqPcPxSpVw("min-height",55,75);
		height: auto;
		font-family: $fontFamilySet2 !important;;
		&::placeholder{
			color:$lightGray2;
		}
	}
	.search-button-holder{
		font-size: 0;
	}
	.item-search-submit{
		opacity: 0;
		width: 100%; height: 100%;
		border: none; padding: 0;
		font-size: 0;
		z-index: 1;
	}
	.search-button-holder .icon-search{
		@include mqPcPxSpVw("width",40,45);
		color: $lightGray2;
		svg{width: 100%;}
	}
}
//商品タイプ選択　レイアウト
.list-layout-select-items{
	.list-item{
		.btn-item-select{
			width: 100%;
			height: 100%;
			@include mqPcPxSpVw("min-height",80,120);
		}
	}
	.item-thumb{
		@include mqPcPxSpVw("width",80,120);
	}
}


//リストアイテム共通
.list-item-module{
  list-style: none;
  @include mqPcPxSpVw("margin-right",40,0);
  @include mqPcPxSpVw("padding-right",0,20);
  a:not(.uk-flex){
    display: block;
  }
  a{
    position: relative;
    &.active{
        .img-holder{
          &:before{
            opacity: 1;
          }
        }
      }
  }
  .img-holder.img-cover-black{
    &:before{
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;left: 0;
      width: 100%;height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      background-color: rgba(0,0,0,0.7);
    }
  }
  .img-holder{
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    img{width: 100%;}
    >img,>a,.img-element{
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }
  }
  .img-holder-square{
    width: 100%;
    padding-top: 100%;
  }
  .img-element{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .list-item-info.layout-abs{
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-item-icon-holder{
    @include mqPcPxSpVw("width",70,140);
    @include mqPcPxSpVw("height",70,140);
    @include mqPcPxSpVw("left",10,20);
    @include mqPcPxSpVw("bottom",10,20);
    @include mqPcPxSpVw("border-width",5,10);
    .list-item-icon{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0; top: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
.status-holder{
  .items-status-list{font-size: 0;}
  .status-item + .status-item{
    &:before{
      content: "/";
      padding-right:0.5em;
      padding-left:0.5em;
    }
    &:empty{
      display: none;
    }
    &:empty:before{
      content: none;
    }
  }
}
// 価格表記削除
.list-item{
  &.features,&.styles{
    .status-holder,.item-price{display: none;}
    .status-holder + .item-title{margin-top: 0;}
  }
  &:empty{display: none;}
}
.status-holder{
  .status-item{
    display: inline-block;
    @include mqPcPxSpVw("margin-bottom",5,3);
  }
  &:empty{display: none;}
}
.wrap[data-pageid="item_list$detail"]{
  .list-item{
    .item-title{margin-top: -3px;}
  }
  .status-holder{
    .status-item{@include mqPcPxSpVw("margin-bottom",8,3);}
  }
}

.items-status-list:empty{
  display: none;
}

//サイト共通　横並びスライダー
.wide-slider{
  .swiper-button-next,
  .swiper-button-prev{
    transition: opacity 0.4s $easeOutQuart;
    outline: none;
    &.swiper-button-disabled{opacity: 0;}
  }
  .list-item{
    @extend .list-item-module;
  }
  &.list-layout-item-margin0{
    .list-item{
      margin-right: 0;
    }
  }
  &.list-layout-item-marginS{
    .list-item{
      @include mqPcPxSpVw("margin-right",10,0);
      @include mqPcPxSpVw("padding-right",0,10);
    }
  }
  &.list-layout-item-marginXS{
    .list-item{
      @include mqPcPxSpVw("margin-right",5,0);
      @include mqPcPxSpVw("padding-right",0,5);
    }
  }
}
.list-layout-row-slider{
  width: 100%;
  margin: auto;
  //height: 100%;
  z-index: 1;
  //max-width: 1880px;
}
//top 新着特集レイアウト
.list-layout-costum-col2{
  @include mqPcPxSpVw("margin-top",-30,-40);
  >*{
    @include mqPcPxSpVw("margin-top",30,40);
  }
  &.rearrange-layout{
    @include mqPcPxSpVw("margin-top",0,0);
    >*{
      @include mqPcPxSpVw("margin-top",0,0);
    }
  }
  .list-layout-width-m{
    width: 100%;
    @media all and (min-width: 1281px) {
      width: calc(66.1% - 20px);
      &:nth-child(1),&:nth-child(4){
        width: calc(66.1% - 20px);
      }
      &:nth-child(2),&:nth-child(3){
        width: calc(33.8% - 20px);
      }
    }
    @media all and (min-width: 960px) {
      &:nth-child(2),&:nth-child(3){
        width: calc(50% - 20px);
      }
    }
	}
	.list-layout-width-s{
		width: 100%;
		@media all and (min-width: 960px) {
			width: calc(50% - 20px);
		}
		@media all and (min-width: 1281px) {
			width: calc(33.8% - 20px);
		}
	}
}
//top style レイアウト スライダー
.list-layout-styles-slider{
	width: 100%;
	margin: auto;
	height: 100%;
	z-index: 1;
	//max-width: 1880px;
	.list-item:nth-of-type(2n){
		//display: none;
		.list-alternate{
			//flex-direction: row-reverse;
		}
	}
	.list-alternate{
		@include mqPcPxSpVw("margin-right",-40,-20);
		@include mqPcPxSpVw("margin-top",-40,-20);
	}
	.list-alternate-item{
		@include mqPcPxSpVw("margin-right",40,20);
		@include mqPcPxSpVw("margin-top",40,20);
	}
}
.list-layout-costum-season{
  .list-layout-width-m{
    width: 100%;
    & + .list-layout-width-m{
      @include mqPcPxSpVw("margin-top",30,40);
    }
  }
}

//商品詳細
.item-shopping-holder{
  &.single{
    max-width: 1200px;
    margin: auto;
    .btn-mode{
      display: none;
    }
  }
  .item-image-holder{
    .image-item{background-color: #EEE;}
    &.grid{
      .swiper-pagination{display: none;}
    }
    .swiper-pagination{
      .swiper-pagination-bullet{
        background-color: rgba(#000,0.8);
        margin: 0 4px;
      }
      .swiper-pagination-bullet-active{background-color: #000;}
    }
  }
	.size-select-radio-holder{
		@include mqPcPxSpVw("padding-top",12,30);
		@include mqPcPxSpVw("padding-bottom",12,30);
		@include mqPcPxSpVw("padding-left",15,30);
		@include mqPcPxSpVw("padding-right",15,30);
    &.soldout,&.soldout.active,&.active .soldout{
      border-color: $white;
      background-color: $white;
      color: $gray;
    }
	}
	input:checked + .size-select-radio-holder,.size-select-radio-holder.active{
		background-color: $black2;
		color: $white;
	}
}
//商品一覧レイアウト
.list-layout-costum-col4{
  overflow: hidden;
  &.list-layout .list-holder{
    @media all and (min-width: 960px) {
      margin: -20px;
    }
    @media screen and (max-width: 959px) {
      margin: -2.5px  -2.5px;
      .list-item-info{
        padding-top: 0;
      }
    }
  }
  .list-item{
    img{width: 100%;}
    a{display: block;}
    .status-item + .status-item:before{
      content: "/";
      padding-right: 0.5em;
      padding-left: 0.5em;
    }
    @media all and (min-width: 960px) {
      width: calc(25% - 40px);
      margin: 20px;
    }
    @media screen and (max-width: 959px) {
      width: 50%;
      padding:5/2+px;
      .btn-wishlist{
        right: 15px;
      }
    }
  }
  &.product-list,&.product-list-holder{
    @media all and (min-width: 960px) {
      margin: -20px;
    }
    @media screen and (max-width: 959px) {
      .list-item-info{
        padding-top: 0;
      }
    }
  }
  // 商品一覧
  &.product-list-holder{
    .list-holder{
      @media screen and (max-width: 959px) {
        margin: -2.5px;
      }
    }
    .list-item{
      @media all and (min-width: 960px) {
        &:nth-child(6n),&:nth-child(6n - 1){
          width: calc(50% - 40px);
          margin: 20px;
        }
      }
      @media screen and (max-width: 959px) {
        &:nth-child(6n),&:nth-child(6n - 1){
          width: 100%;
          .uk-position-top-right{
            right: 30/750*100%;
          }
        }
      }
    }
  }
  // スタイルズ一覧
  &.style-list-holder{
    .list-item{
      overflow: hidden;
      >*,a{
        height: 100%;
        overflow: hidden;
      }
      @media all and (min-width: 1281px) {
        width: calc(33.3333% - 40px);
        margin: 20px;
      }
      @media all and (min-width: 960px) {
        width: calc(50% - 40px);
        margin: 20px;
      }
      @media screen and (max-width: 959px) {
        &:nth-child(5n){
          width: 100%;
          .uk-position-top-right{
            right: 30/750*100%;
          }
        }
      }
    }
  }
  // 特集一覧
  &.feature-list-holder{
    margin-bottom: 120/2+px;
    margin: -20px;
    @media screen and (max-width: 959px){
      margin-left: 0;
      margin-right: 0;
      margin-top: -100/2+px;
      margin-bottom: 100/2+px;
      .list-item{width: 100%;}
    }
    .tag-default{
      display: inline-block;
    }
  }
}
// お気に入り一覧レイアウト
.list-layout-costum-col3{
  &.wish-list-holder{
    @media all and (min-width: 960px) {
      margin-right: 0;
      margin-left: -40px;
    }
    @media screen and (max-width: 959px) {
      overflow: hidden;
      margin: -2.5px;
      padding-right: 0;
      justify-content: space-between;
      .list-item-info{
        padding-top: 0;
      }
    }
    .list-item{
      @media all and (min-width: 960px) {
        padding-left: 40px;
        width: 33.3333%;
      }
      @media screen and (max-width: 959px) {
        padding: 2.5px;
        width: 50%;
      }
    }
  }
}
// お気に入り一覧
.section-wishlist{
  .list-item{
    position: relative;
    @include mqPcPxSpVw("margin-bottom",40,0);
  }
  .wishlist-body .styleHolder{
    .status-holder,.item-title,
    .item-price{display: none;}
    .list-item-info{
      padding: 0 !important;
      position: absolute !important;
      @include mqPcPxSpVw("top",0,5);
      @include mqPcPxSpVw("right",0,5);
      @include mqPcPxSpVw("width",50,90);
      @include mqPcPxSpVw("height",50,90);
    }
    .wishlistSubmitButton{
      display: block;
      @include mqPcPxSpVw("padding",15,30);
      @include mqPcPxSpVw("margin",0,0);
      @include mqPcPxSpVw("right",0,0);
    }
  }
}
.wishlist-head-holder{
  .wishlist-head{
    .item a{
      color: #323232;
      cursor: pointer;
      pointer-events: painted;
    }
    .item.uk-active a{
      color: #C8C8C8;
      cursor: default;
      pointer-events: none;
    }
  }
  @media screen and (min-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 959px) {
    margin: auto;
    .item{width: 50%;}
    .wishlist-head{
      margin-left: -25px;
    }
  }
}

// アクティビティから探す
.section-activity{
  .cate-name{
    color: $white;
    position: absolute;
    z-index: 1;
  }
}
// カテゴリ一覧 アクティビティから探す
.section-activity{
  .list-layout-select-items{
    .item-thumb{
      width: 100%;
    }
  }
  .list-item-info{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;
  }
  .list-item{
    img{width: 100%;}
    a{display: block;position: relative;}
    &.list-item-wide{width: 100%;}
  }
}
// カテゴリ一覧 容量から探す
.section-capacity{
  .list-item{
    a{display: block;width: 100%;}
    @media screen and (max-width: 959px){
      a{border: none;}
    }
  }
}
// レーベルトップ
.section-label-list{
  @media all and (min-width: 960px){
    .list-item{
      width: 100%;
    }
    .list-item-pic-holder{
      width: 580/1280*100%;
      padding-top: 290/1280*100%;
      position: relative;
      .img-holder{
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 100%;
      }
    }
    .list-item-info{
      flex:1;
      padding-left: 40/1280*100%;
    }
    .btn-read-more{
      text-decoration: underline;
      &:hover{text-decoration: none;}
    }
  }
  @media screen and (max-width: 959px){
    .btn-read-more{
      text-decoration: underline;
    }
  }
}
// レーベル詳細 おすすめ・ランキング
.section-recommend,.section-ranking{
  .btn-wishlist{display: none !important;}
}



// 閲覧履歴
.section-history{
  .list-item-info{
    position: relative;
  }
  .list-item{
    &.features,&.styles{
      .item-price{display: none;}
    }
  }
}
// カテゴリHTML表示
.category-html{
  .category-text{line-height: 2;}
  @media all and (min-width: 960px) {
    .category-text,
    .category-title{
      display: block;
    }
    .category-title{width: 48%;}
    .category-text{width: 50%;}
    .category-text-holder{
      display: flex;
      align-items: flex-start;
      &.no-info{
        .category-title{width: 100%;}
      }
    }
  }
  @media all and (max-width: 959px) {
  }
}
// こだわり検索、表示順選択
.filter-block{
  .rearrange-block .select-el-parent{
    width: 100%;
  }
  .rearrange-block select.select-el,
  .rearrange-block .select-el-parent select{
    width: 100%;
    line-height: 1 !important;
    background: none;
    padding: 0 !important;
    border: none;
    height: auto !important;
    position: relative;
    z-index:1;
  }
  .rearrange-block .select-el-parent select{
    height: 100% !important;
  }
  .rearrange-block,.search-block{
    display: flex;
    @include mqPcPxSpVw("width",180,300);
    @include mqPcPxSpVw("min-height",50,90);
  }
  //sortボタン
  .rearrange-block{
    position: relative;
    .icon{
      position: absolute;
      @include mqPcPxSpVw("width",12,18);
      @include mqPcPxSpVw("right",18,30);
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }
  .search-block{
    .icon{
      @include mqPcPxSpVw("width",20,40);
    }
  }
}
// こだわり検索

.modal-narrow{
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0; left: 0;
  z-index: 11;
  //overflow: hidden;
  &.opened{
    position: relative;
    height: auto;
  }
  @media all and (max-width: 959px) {
    display: block;
  }
  .modal-narrow-inner{
    position: relative;
    z-index: 999;
    background-color: #fafafa;
    width: 100%;
    @media all and (min-width: 960px) {
      height: 680px;
      max-width: 1120px;
      border-radius: 6px;
    }
    @media all and (max-width: 959px) {
      height: 100%;
      min-height: 100vh;
    }
  }
  .overlay{
    display: block;
    width: 100%;
    height: 150%;
    position: fixed;
    top: -25%; left: 0;
    z-index: 5;
    background-color: rgba(0,0,0,0.6);
  }
  .btn-close{
    padding: 0;
    position: absolute;
    @include mqPxVw("width",50);
    @include mqPxVw("height",50);
    .icon-close,svg{width: 100%;}
    @media all and (min-width: 960px) {
      top: 20px; right: 20px;
    }
    @media all and (max-width: 959px) {
      top: 50/2+px;
      right: 50/750*100%;
    }
  }
}
// スタイルズ検索、表示順選択
.style-filter-block{
}
// パンくず
.breadcrumb-block{
  .icon-arrow{
    @media all and (min-width: 960px) {
      width: 10px; height: 10px;
    }
    @media all and (max-width: 959px) {
      width: 20/750*100%; height: 20/2+px;
    }
  }
}

//wp カテゴリトップ
.list-layout-first-large{
  .list-item-module,.list-item{
    &:first-child{
      width: 100%;
    }
  }
}

// レーベル詳細 メルマガエリア
.wrap[data-categoryid="label_top"]{
  .header{
    //position: absolute;
    top: 0; left: 0;
    //z-index: 3;
    .nav-item >*,
    .uk-grid-small >*{color: $white;}
  }
  .breadcrumb-block{
    width: 100%;
    position: absolute;
    z-index: 2;
    @media screen and (min-width: 960px){
      top: 150px;
    }
    @media screen and (max-width: 959px){
      top: 50/2+px;
    }
  }
}
.section-mailmag{
  a{position: relative;}
  .pic{width: 100%;}
  .item-mailmag-holder{
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
  .icon-mail{
    margin: 0 auto;
    display: block;
    @include mqPxVw("width",80);
    @include mqPxVw("height",80);
  }
}

// レーベル詳細
.section-label-link{
  .item-label-link{
    position: relative;
    .parallax-inner{
      text-align: center;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      z-index: 3;
    }
    h3{line-height: 1.5;}
  }
}
// レーベル詳細 パララックスエリア
.item-parallax-holder{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .image-holder{width: 100%;}
  .bg-parallax{
    width: 100%; height: 100%;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}
// ランキング
.section-ranking{
  .item-rank-num{
    position: absolute;
    width: 40px; height: 40px;
    top: 10px; left: 10px;
    z-index: 1;
    >p{margin: auto;}
  }
}


// 特集一覧
.section-feature-list{
  .tag-list-layout{
    > span{
      display: inline-block;
    }
  }
}

//ご利用ガイド
.toggle-layout-default{
  .uk-accordion-title{
    cursor: pointer;
  }
  .uk-open .uk-accordion-title{
    .icon svg{
      transform: rotate(45deg);
    }
  }
  .uk-accordion-title{
    &:before{display: none;}
  }
}

/** ↑↑ モジュール ↑↑ **/





/* modal ex) ultimate project
********************************/
.btn-modal{
  .icon-play{
    pointer-events: none;
  }
}
.modal{
  z-index: 100;
  visibility: hidden;
  position: fixed;
  bottom: 0;left: 0;
  width: 100%;
  height: 100%;
  &.modalShow{
    top: 0;
    animation: fadeIn 0.6s both;
    .modal-inner{
      animation: modalInnerShow 0.6s both;
    }
  }
  &.modalHide{
    animation: fadeOut 0.6s both;
    .modal-inner{
      animation: modalInnerHide 0.6s both;
    }
  }

  .modal-container-center{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; top: 0;
    background-color: rgba(#000,0.9);
  }
  .btn-modal-close{
    color: #FFF;
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
  }
  .modal-inner{
    background-color: #fff;
    width: 80%;
    .video-holder{
      .icon-play{
        color: #fff;
      }
      &.pause{
        .vmPlayer{
          opacity: 0.0000001;
        }
      }
    }
  }
}



.couponHide{
  //display: none;
}
