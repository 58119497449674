@import "./_mixin";
/* utils PC
***********************************/
.btn{
  opacity: 1;
  transition: opacity .2s linear;
  &:hover{opacity: 0.6;}
}
.btn-hover-opacity{
  opacity: 1;
  transition: opacity .2s linear;
  text-decoration: none;
  &:hover{opacity: 0.6;}
}
.spOnly{display: none;}
.pc-flex{display: flex;}
.pc-flex-wrap{flex-wrap:wrap;}
.pc-flex-middle{align-items: center;}
.pc-flex-end{align-items: flex-end;}
.pc-flex-between{justify-content: space-between;}
.pc-flex-center{justify-content: center;}


.margin0{margin: 0;}.margin-b0{margin-bottom: 0;}
.padding0{padding: 0;}
