@import "./_mixin";


@import "framework/variables-theme.scss";
@import "framework/mixins-theme.scss";
@import "framework/uikit-theme.scss";

a,button{-webkit-tap-highlight-color: rgba(255,255,255,0.5);}
.uk-text-small:empty{
  display: none;
  span:empty{
    display: none;
  }
}
.uk-padding{
  padding: 40px;
}

/* uikit
*********************/
.uk-fieldset{
  margin-bottom: 20px;
}
.uk-display-panel{
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: #CCC;
  text-align: center;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  input:checked+.uk-icon-holder{
    display: block;
  }
  .uk-icon-holder{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
  }
}
.uk-button-large{
  padding: 5px 10px;
  height: 60px;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}
.uk-button-primary{
  border:none;
}
.uk-input,
.uk-select,
.uk-button,
.uk-checkbox,
.uk-textarea,
.btn-default{
  border-radius: 4px;
}
.uk-radio,
.uk-checkbox{
  width: 30px;
  height: 30px;
  border-color: $lightGray2;
  background-color: $white;
  margin-top: 0;
}
.uk-select:not([multiple]):not([size]){
  background: $white url('../img/icon/form-select.svg') no-repeat calc(100% - 10px) 50% / 20px 20px;
}
.uk-select:focus,
.uk-input:focus,
.uk-radio:focus,
.uk-checkbox:focus {
  border-color: $lightGray2;
}
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  border-color: $gray;
  background: $gray url('../img/icon/form-checkbox.svg') no-repeat center center / 15px 15px;
}

.uk-radio-inverse:checked {
  border-color: #fafafa;
  background: #fafafa url('../img/icon/form-checkbox-white.svg') no-repeat center center / 15px 15px;
  .checklist-ITEM_002 &,
  .checklist-ITEM_003_001 &,
  .checklist-ITEM_003_002 &,
  .checklist-ITEM_003_003 & {
    background: #fafafa url('../img/icon/form-radio-white.svg') no-repeat center center / 30px 30px;
  }
}

.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: $gray;
}
.uk-radio-inverse:checked:focus {
  background-color: $white;
}
.uk-form-large,.uk-form-large:not(textarea):not([multiple]):not([size]){
  height: 50px;
  line-height: 50px;
  padding-left: 18px;
  padding-right: 18px;
  text-align: inherit;
}
.uk-grid-small{
  margin-left: -20px;
  >*{
    padding-left: 20px;
  }
}
.uk-legend{
  font-size: 14px;
  min-height: 30px;
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}
.uk-label{
  font-size: 12px;
  background-color: $lightGray;
  color: $black2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;
  margin-left: 15px;
}
.uk-fieldset{
  margin-bottom: 30px;
}
.uk-form-controls{
  .uk-text-small:empty{
    display: none;
  }
}
// tel
.holder-tel-input{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .uk-input{
    width: 31%;
  }
}
// tel
.holder-card-input{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .uk-input{
    width: 23%;
  }
}
.uk-fieldset,.uk-form-controls{
  font-size: 14px;
}

// .uk-form-controls{
//   .contact-online-service{
//      padding-left: 2.44em;
//      text-indent: -2.46em;;
//    }
// }


.uk-input,
.uk-select{
  font-size: 14px;
}
.uk-select{
  min-width: 80px !important;
  height: 50px !important;
  padding-right: 40px !important;
}

.uk-radio,
.uk-checkbox{
  margin-right: 5px;
}

/* uk-accordion
*********************/
.uk-accordion-title{
  &:before{
    background-size: 20px;
  }
}
.uk-textarea{
  padding: 10px;
  min-height: 16em;
}
.uk-table-large th, .uk-table-large td{
  padding: 36px 0;
  vertical-align: middle;
}
.uk-table-large td{
  font-size: 20px;
}
.uk-table-large th{
  font-size: 14px;
  width: 280px;
}
@media screen and (max-width: 959px) {
  .uk-fieldset{
    margin-bottom: 25px;
  }
  .uk-grid-small{
    margin-left: -15px;
    >*{
      padding-left: 15px;
    }
  }
  .uk-label{
    font-size: 10px;
    padding: 4px 8px;
  }
  .uk-input,
  .uk-select{
    font-size: 12px;
  }
  .uk-select{
    min-width: 80px !important;
    height: 45px !important;
    padding-right: 30px !important;
  }
  .uk-legend{
    font-size: 12px;
  }
  .uk-accordion-title{
    &:before{
      background-size: 13px;
    }
  }
  .uk-form-large,.uk-form-large:not(textarea):not([multiple]):not([size]){
    height: 45px;
    line-height: 1.3;
    padding-left: 12px;
    padding-right: 12px;
  }
  .uk-table-large th, .uk-table-large td{
    padding: 20px 0;
    vertical-align: middle;
  }
  .uk-table-large td{
    font-size: 15px;
  }
  .uk-table-large th{
    font-size: 12px;
    width: 125px;
  }
}


.uk-tab{
  &:before{
    content: none;
  }
  > * > a{
    transition: color 0.2s;
    border: none;
    color: #DCDCDC;
  }
}

/* swiper
*********************/
.swiper-button-prev{
  background: none;
  left: 0;
  width: 60px;
  height: 60px;
}
.swiper-button-next{
  background: none;
  right: 0;
  width: 60px;
  height: 60px;
}
.swiper-container{
  .swiper-pagination{
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    .swiper-pagination-bullet{
      width: 4px;
      height: 4px;
      margin: 0 2.5px;
    }
    .swiper-pagination-bullet-active{
      background-color: #79888B;
    }
  }
}
