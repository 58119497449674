$fontFamilySet0: "游ゴシック体", "Yu Gothic", "YuGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$fontFamilySet1: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック体", "Yu Gothic", "YuGothic", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", Verdana, Roboto, "Droid Sans", sans-serif;

$fontFamilySet2:"Helvetica Neue", Helvetica, Arial,'Roboto','Noto Sans JP',"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック体", "Yu Gothic", "YuGothic", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", Verdana, Roboto, "Droid Sans", sans-serif;


$ease1: cubic-bezier(0.215, 0.61, 0.355, 1);

$minWidth:1000px;

$mqSmall: 750px;
$mqTablet: 1028px;
$mqMiddle: 1366px;
$mqLarge: 1600px;
$mqMaxLarge: 1920px;



//color
$white       : #FFFFFF;
$white2       : #FAFAFA;
$black       : #000000;
$black2      : #323232;
$gray        : #969696;
$lightGray   : #F0F0F0;
$lightGray2  : #DCDCDC;
$lightGray3  : #E6E6E6;
$red         : #DA3636;
$yellow      : #FFE500;

//rank color
$rankBlue      : #0090D4;
$rankGold      : #DAA520;
$rankPLATINUM : #A0A0A0;
$rankBlack     : #323232;

//vw px 指定
@mixin mqPcPxSpVw($prop,$numberPc,$numberSp) {
  @media all and (min-width: 960px) {
    #{$prop} : $numberPc + px;
  }
  @media all and (max-width: 959px) {
    #{$prop} : $numberSp / 2 + px;
  }
}

@mixin mqPxVw($prop,$number) {
  @media all and (min-width: 960px) {
    #{$prop} : $number + px;
  }
  @media all and (max-width: 959px) {
    #{$prop} : $number / 2 + px;
  }
}

@mixin mqPcSizeM($prop,$number) {
  #{$prop} : $number + px;
  @media all and (max-width: 1281px) {
    #{$prop} : $number / 1280 * 100%;
  }
}
@mixin mqPcSizeL($prop,$number) {
  @media all and (min-width: 1961px) {
    #{$prop} : $number + px;
  }
  @media all and (max-width: 1960px) {
    #{$prop} : $number / 1960 * 100%;
  }
}



$fontMinWidth:1366;
$fontMaxWidth:1600;
@mixin fsPC($fs){
  font-size: $fs/1366*100vw;
  @media only screen and (min-width: $fontMaxWidth+px ) {
    font-size: $fs * ($fontMaxWidth / 1366) + px;
  }
  @media only screen and (max-width: $fontMinWidth + px) {
    font-size: $fs*($fontMinWidth / 1366)+px;
  }
}


/*
 例 PSDのフォントサイズを()内に代入 @include fsPC(20);
 第二引数をつけるとvw以外の設定　@include fsPC(28,rem)
*/
@mixin fsSP($fs,$str:""){
  @if $str == "" {
    font-size: $fs/ 2 + px;
    font-size: ($fs/2)+px;
  } @else if $str == vw {
    font-size: $fs/ 2 + px;
  } @else if $str == rem {
    font-size: ($fs / 20)#{$str};
  } @else if $str == px {
    font-size: ($fs / 2)#{$str};
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    html.android &{ transform: translateY(-2px);}
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}


@mixin clearfix() {
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}

// ==========================================

// 想定する画面サイズを超えたとき
@mixin mqExceed($size) {
	@media (min-width: $size) {
		@content;
	}
}
// 想定する画面サイズ以下のとき
@mixin mqSmaller($size) {
	@media (max-width: $size) {
		@content;
	}
}



$easeInSine:cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInQuart:cubic-bezier(0.895, 0.03, 0.685, 0.22);;

$easeInExpo:cubic-bezier(.95, .05, .795, .035);
$easeOutExpo:cubic-bezier(.19, 1, .22, 1);
$easeInOutExpo:cubic-bezier(1, 0, 0, 1);

$easeInOutSine : cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutQuad : cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);

$easeOutSine :cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutQuad : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutExpo : cubic-bezier(0.19, 1, 0.22, 1);
$easeOutQuart : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint : cubic-bezier(0.23, 1, 0.32, 1);
$easeoriginal : cubic-bezier(0.18, 1.01, 0.29, 1.04);

$easeOutCirc:cubic-bezier(0.075, 0.82, 0.165, 1);



// スプライト用画像レスポンシブ仕様
@mixin sprite-responsive($sprite){
  $sheet-w    : nth($sprite, 7);
  $sheet-h    : nth($sprite, 8);
  $sprite-w   : nth($sprite, 5);
  $sprite-h   : nth($sprite, 6);
  $offset-x   : nth($sprite, 1);
  $offset-y   : nth($sprite, 2);
  $bg-path    : nth($sprite, 9);
  // テキストを隠す
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  font-size: 0;
  background-image: url($bg-path);
  background-size: ($sheet-w / $sprite-w * 100 + 0%) ($sheet-h / $sprite-h * 100+ 0%);
  background-position: ($offset-x / ($sheet-w - $sprite-w) * 100 + 0%) ($offset-y / ($sheet-h - $sprite-h) * 100 + 0%);
  &:after{
    content: '';
    display: block;
    padding-top: ($sprite-h / $sprite-w * 100 + 0%);
  }
}
